import styled from 'styled-components';
import tw from 'twin.macro';

export const Wrapper = tw.div`
  fixed z-50 bottom-0 left-0 right-0 flex flex-col items-center gap-4 max-h-[29.75rem] p-6
  [box-shadow: 0px 4px 92px 0px rgba(0, 0, 0, 0.25)] bg-white rounded-t-2xl
  md:(w-[22.625rem] left-10 bottom-1/2 translate-y-1/2 rounded-[1.2rem] right-[unset] max-h-[unset] pt-6)
`;

export const CloseBtn = styled.button`
  ${tw`-rotate-90 absolute z-50 right-1/2 translate-x-1/2 -top-[5.5rem]
  md:(-right-12 translate-x-0 top-1/2 -translate-y-1/2 rotate-0)`}
  img {
    pointer-events: none;
  }
`;

export const Video = tw.video`
  w-full max-w-[19.625rem] rounded-xl
`;

export const Description = tw.p`
  text-black text-base opacity-70 leading-7
`;
