import tw, { styled } from 'twin.macro';

export const SearchInputWrapper = tw.div`
  relative w-full
`;
export const StyledInput = styled.input(() => [
  tw`w-full outline-none rounded-full h-[3.75rem] pl-6
  `,
  `border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 16px 18px rgba(0, 0, 0, 0.11);`,
]);

export const InputButton = tw.span`
     absolute w-[3.25rem] md:w-[4.5rem] h-[3.25rem] bg-[#EB672C] rounded-[1.875rem] flex items-center justify-center 
     right-1 top-1/2 -translate-y-1/2 
     [box-shadow: 0px 0px 30px 15px rgba(0, 0, 0, 0.02)]
  `;

export const RedPoint = tw.span`
    absolute -top-1.5 right-2 w-3.5 h-3.5 bg-[#EA4235] rounded-full
  `;
