const Restaurant = () => {
  return (
    <svg
      width="39"
      height="36"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.14574 4.31699C6.33938 4.31774 6.53123 4.27563 6.71033 4.19311C6.88943 4.11056 7.05225 3.98917 7.18953 3.83591L8.40105 2.47641C8.48851 2.3702 8.52085 2.22079 8.48657 2.08136C8.45232 1.94195 8.35631 1.83227 8.23268 1.79136C8.10906 1.75044 7.97537 1.7841 7.87915 1.88034L6.66762 3.23984C6.48333 3.44205 6.21822 3.52137 5.96867 3.44899L7.6182 1.58753C7.7131 1.48104 7.75013 1.32585 7.7154 1.18042C7.6807 1.03496 7.57943 0.921328 7.44981 0.882347C7.32018 0.843369 7.18188 0.884969 7.08698 0.991456L5.43745 2.86338C5.37295 2.58335 5.44364 2.28586 5.62384 2.07906L6.83536 0.719556C6.93025 0.613071 6.96732 0.457879 6.93259 0.312412C6.89785 0.166955 6.79659 0.0533492 6.66696 0.0143769C6.53736 -0.0246015 6.39906 0.0169641 6.30417 0.123451L5.09265 1.48295C4.86224 1.74218 4.7145 2.07946 4.67237 2.44253C4.63026 2.80555 4.69609 3.17405 4.85966 3.49083L4.57077 3.815L1.9427 0.845022C1.85081 0.743818 1.71888 0.702998 1.59377 0.737038C1.46863 0.771079 1.368 0.875149 1.32762 1.01234L1.18783 1.46203C1.02689 2.01226 1.00529 2.60183 1.1254 3.16498C1.24553 3.72817 1.50266 4.24282 1.86813 4.65163L2.85598 5.76014L0.610011 8.28045C0.515116 8.38693 0.478079 8.54212 0.51281 8.68759C0.547545 8.83305 0.64878 8.94668 0.778405 8.98562C0.90803 9.0246 1.04634 8.98304 1.14123 8.87655L3.37789 6.34579L4.10481 7.16151H4.10478C4.17486 7.24134 4.27054 7.2863 4.37041 7.2863C4.47024 7.2863 4.56593 7.24133 4.636 7.16151L5.57726 6.11574L7.82324 8.62558C7.91813 8.73207 8.05643 8.77366 8.18606 8.73469C8.31569 8.69571 8.41693 8.58208 8.45166 8.43662C8.48639 8.29119 8.44935 8.136 8.35446 8.02951L5.84754 5.22685L5.12061 4.41114L5.40951 4.08696C5.63278 4.23457 5.88664 4.31391 6.14575 4.31704L6.14574 4.31699ZM4.37505 6.28304L2.39934 4.06601C2.15243 3.79088 1.97233 3.44951 1.87607 3.07427C1.77984 2.69906 1.77068 2.30244 1.84949 1.92218L5.05536 5.51962L4.37505 6.28304Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Restaurant;
