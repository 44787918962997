import tw, { styled } from 'twin.macro';

export const ClusterWrapper = styled.div`
  ${tw`relative w-[5.625rem] h-[6.063rem] flex justify-center items-center`}
  color: ${({ bgColor }) => bgColor};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

export const TextWrapper = tw.span`
   absolute inset-0 z-10 bg-transparent pt-8 px-3.5 pb-[2.375rem] flex justify-center items-center
   text-white text-xs leading-[13.22px] tracking-[-0.48px] text-center
`;
