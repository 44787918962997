import Button from 'components/Button';
import ProgramList from 'components/Program/DayList';
import Modal from 'components/Modal';
import SharePanel from 'components/SharePanel';
import Title from 'components/Typography/Title';
import { getBounds, getDistance, getRhumbLineBearing } from 'geolib';
import ArrowLeft from 'Icons/ArrowLeft';
import Share from 'Icons/Share';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import useFavorites from 'store/useFavorites';
import usePlaces from 'store/usePlaces';
import { getDistanceWithUnit } from 'utils/helpers';
import {
  Breadcrumb,
  Breadcrumbs,
  Btn,
  BtnsWrapper,
  Content,
  DayWrapper,
  Header,
  PrintBtn,
  Wrapper,
} from './Program.styles';
import useStepScroll from 'store/useStepScroll';
import useBrekpoint from 'hooks/useBrekpoint';
import PrintIcon from 'Icons/PrintIcon';
import { placesZoom } from 'utils/constants';

export default function Program() {
  const { main_map } = useMap();
  const [shareIsOpen, setShareIsOpen] = useState(false);
  const { programs, isFavorites, toggleFavorites, removeFavStep } =
    useFavorites();
  const { setPlaces, setCitySelected, withoutCommercialPlaces, cities } =
    usePlaces();
  const { t } = useTranslation();
  const naviagte = useNavigate();
  const params = useParams();
  const location = useLocation();
  const name = params.name.split('-').join(' ');
  const { id } = useStepScroll();
  const { isSmallDevice, heigth } = useBrekpoint();

  const closeModal = () => {
    setShareIsOpen(false);
  };

  const handleRemove = (id) => {
    removeFavStep(id, name);
  };

  const animateTo = useCallback(
    ({ longitude, latitude }) => {
      main_map?.flyTo({
        center: [longitude, latitude],
        curve: 2,
        zoom: 12,
        speed: 0.6,
      });
    },
    [main_map],
  );

  const headTo = useCallback(
    (start, target) => {
      if ((start, target)) {
        const { minLat, maxLat, minLng, maxLng } = getBounds([start, target]);

        const bearing = getRhumbLineBearing(start, target);
        main_map?.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          {
            padding: isSmallDevice
              ? { left: 0, top: 0, bottom: (heigth * 80) / 100 }
              : { left: 250, top: 50, bottom: 200 },
            pitch: 80,
            bearing,
            curve: 2,
            speed: 0.5,
          },
        );
      }
    },
    [main_map, heigth, isSmallDevice],
  );

  useEffect(() => {
    if (programs[name]) {
      const firstLocation = programs[name][0][0]?.location?.coordinates;
      const secondLocation = programs[name][0][1]?.location?.coordinates;
      setPlaces(programs[name].flat());
      if (secondLocation) {
        headTo(
          {
            longitude: firstLocation[0],
            latitude: firstLocation[1],
          },
          {
            longitude: secondLocation[0],
            latitude: secondLocation[1],
          },
        );
      } else {
        animateTo({
          longitude: firstLocation[0],
          latitude: firstLocation[1],
        });
      }
    }
  }, [programs, setPlaces, name, headTo, animateTo]);

  const handleHover = (start, target) => {
    headTo(start, target);
  };

  const goBack = () => {
    const zoomLevel = main_map?.getZoom();
    if (zoomLevel > placesZoom) {
      setCitySelected('all');
      setPlaces(withoutCommercialPlaces);
    } else {
      setCitySelected(false);
      setPlaces(cities);
    }
    naviagte(-1);
  };

  const programSummary = useMemo(() => {
    const myProgram = {};
    if (programs[name]) {
      programs[name]?.forEach((day, index) => {
        if (typeof day[0] !== 'string') {
          const firstPlaceCords = {
            latitude: day[0]?.location.coordinates[1],
            longitude: day[0]?.location.coordinates[0],
          };
          const lastPlaceCords = {
            latitude: day.slice(-1)[0]?.location.coordinates[1],
            longitude: day.slice(-1)[0]?.location.coordinates[0],
          };
          const distance = getDistance(firstPlaceCords, lastPlaceCords) * 1.4;

          myProgram[`day${index + 1}`] = {
            region: day[0].contact?.address?.city,
            distance: getDistanceWithUnit(distance),
            dayPlaces: day,
          };
        } else {
          myProgram[`day${index + 1}`] = {
            region: day[0],
            distance: 0 + 'm',
            dayPlaces: day,
          };
        }
      });
    }
    return myProgram;
  }, [programs, name]);

  const programUrl = useMemo(() => {
    if (programs[name]) {
      const programIds = programs[name]?.map((day) => {
        if (typeof day[0] === 'string') {
          return day[0];
        } else {
          return day.map((place) => place.id);
        }
      });

      const baseUrl = window.location.protocol + '//' + window.location.host;
      const idsUrl = encodeURI(JSON.stringify(programIds));
      return `${baseUrl}/shared/${idsUrl}`;
    } else {
      return '';
    }
  }, [programs, name]);

  useEffect(() => {
    if (id && location?.state?.prevRoute === 'add-step') {
      const step = document.getElementById(id);
      step?.scrollIntoView();
    }
  }, [id, location]);

  return Object.keys(programSummary).length === 0 ? (
    <Navigate to="/*" />
  ) : (
    <Wrapper>
      <Content>
        <Header>
          <Button variant="square" onClick={goBack}>
            <ArrowLeft />
          </Button>
          <Title level="h1" size="lg">
            {name}
          </Title>
          <BtnsWrapper>
            <PrintBtn
              to={`/print/${name}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <PrintIcon />
            </PrintBtn>
            <Btn onClick={() => setShareIsOpen(true)}>
              <Share />
            </Btn>
          </BtnsWrapper>
        </Header>
        {programSummary &&
          Object.keys(programSummary).map((day, i) => (
            <DayWrapper key={day[0].id + i.toString()}>
              <Breadcrumbs>
                <Breadcrumb>{t(day)}</Breadcrumb>
                <Breadcrumb>{programSummary[day].distance}</Breadcrumb>
                <Breadcrumb>{programSummary[day].region}</Breadcrumb>
              </Breadcrumbs>
              <ProgramList
                dayIndex={i}
                toggleFavorites={toggleFavorites}
                isFavorites={isFavorites}
                programName={name}
                items={programSummary[day]?.dayPlaces}
                handleHover={handleHover}
                handleRemove={handleRemove}
                withBorder={false}
              />
            </DayWrapper>
          ))}
      </Content>
      <Modal isOpen={shareIsOpen} closeModal={closeModal} largePanel>
        <SharePanel link={programUrl} />
      </Modal>
    </Wrapper>
  );
}
