const EditIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4156 5.68592L9.31408 4.58435L2.05808 11.8404V12.9419H3.15965L10.4156 5.68592ZM11.5172 4.58435L12.6188 3.48279L11.5172 2.38122L10.4156 3.48279L11.5172 4.58435ZM3.8047 14.5H0.5V11.1945L10.9664 0.728093C11.1125 0.582045 11.3106 0.5 11.5172 0.5C11.7238 0.5 11.9219 0.582045 12.068 0.728093L14.2719 2.932C14.418 3.0781 14.5 3.27621 14.5 3.48279C14.5 3.68936 14.418 3.88748 14.2719 4.03357L3.8047 14.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EditIcon;
