const SearchMarker = () => {
  return (
    <svg
      width="18"
      height="23"
      viewBox="0 0 18 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.93186 0.790039C6.62395 0.795351 4.41231 1.72028 2.78142 3.36224C1.15045 5.0042 0.233253 7.22924 0.230469 9.55004C0.230469 15.27 7.40041 22.25 8.93186 22.25C10.4633 22.25 17.6332 15.25 17.6332 9.55004C17.6332 7.22844 16.7168 5.00184 15.0853 3.35924C13.4537 1.71666 11.2406 0.792639 8.93186 0.790039ZM8.93186 20.72C7.5993 20.09 1.72214 14.29 1.72214 9.55004C1.72214 7.62722 2.48171 5.78324 3.83374 4.42344C5.18587 3.06384 7.01974 2.30004 8.93186 2.30004C10.844 2.30004 12.6777 3.06386 14.03 4.42344C15.382 5.78312 16.1416 7.62724 16.1416 9.55004C16.1416 14.29 10.2644 20.09 8.93186 20.72Z"
        fill="black"
      />
      <path
        d="M8.93338 5.25C7.81246 5.25 6.73745 5.69774 5.94488 6.49476C5.15226 7.2918 4.70703 8.3728 4.70703 9.49996C4.70703 10.6271 5.15228 11.7082 5.94488 12.5052C6.73749 13.3022 7.81248 13.7499 8.93338 13.7499C10.0543 13.7499 11.1293 13.3022 11.9219 12.5052C12.7145 11.7081 13.1597 10.6271 13.1597 9.49996C13.1571 8.37364 12.711 7.29416 11.9189 6.49776C11.1269 5.70128 10.0534 5.25268 8.9334 5.25002L8.93338 5.25ZM8.93338 12.25C8.20805 12.25 7.5125 11.9602 6.99964 11.4445C6.48681 10.9288 6.19866 10.2294 6.19866 9.5C6.19866 8.77062 6.48681 8.07118 6.99964 7.55546C7.51248 7.03976 8.20805 6.75 8.93338 6.75C9.65871 6.75 10.3543 7.03976 10.8671 7.55546C11.38 8.07116 11.6681 8.77062 11.6681 9.5C11.6681 10.2294 11.38 10.9288 10.8671 11.4445C10.3543 11.9602 9.65871 12.25 8.93338 12.25Z"
        fill="black"
      />
    </svg>
  );
};

export default SearchMarker;
