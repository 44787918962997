import {
  Counter,
  CounterBtn,
  Name,
  NumberOfDays,
  Wrapper,
} from './Destination.styles';
import { useTranslation } from 'react-i18next';

function Destination({ isResult, destination, days, handlePlus, handleMinus }) {
  const { t } = useTranslation();
  return (
    <Wrapper isResult={isResult}>
      <div>
        <Name>{destination}</Name>
      </div>
      <Counter>
        <CounterBtn onClick={handleMinus}>-</CounterBtn>
        <NumberOfDays>
          {days} {t('day')}
        </NumberOfDays>
        <CounterBtn onClick={handlePlus}>+</CounterBtn>
      </Counter>
    </Wrapper>
  );
}

Destination.propTypes = {};

export default Destination;
