import tw, { styled } from 'twin.macro';

export const Wrapper = styled.div(({ withBorder }) => [
  tw`relative flex flex-col gap-[1.5rem]  w-full p-4 z-10 lg:p-[1.5rem]`,
  withBorder &&
    tw`border border-[#CACACA] rounded-[1.75rem] pt-[3.875rem] md:pt-[3.875rem] last:mb-28`,
]);

export const Region = styled.div`
  ${tw`absolute left-6 top-0 flex justify-center items-center px-4 py-3
       gap-1 border-[1px] border-t-0 border-[#CACACA] rounded-b-2xl`}
  color: ${({ color }) => color}
`;

export const MapList = tw.div`
  flex flex-col gap-[0.65rem]
`;

export const EmptyDay = tw.div`
  w-full flex flex-col justify-center items-center gap-6
`;

export const AddStepButton = tw.button`
  w-full flex justify-center items-center px-4 py-6 gap-2.5
  bg-[#829F42] text-white text-base font-bold rounded-full
  shadow-[0px_4px_30px_rgba(0, 0, 0, 0.25)]
`;

export const AddYourStep = tw.p`
   text-[1.25rem] opacity-50 font-bold 
`;
