import tw, { styled } from 'twin.macro';

export const Wrapper = styled.div(({ isOpen, filterExist, isDirections }) => [
  tw`w-full flex flex-col justify-start items-start gap-4 order-1 mr-6 pl-4
  bg-white grow overflow-hidden origin-bottom-right transition-all duration-1000 
  lg:([flex-grow: unset] order-[unset] mr-0) 

  [box-shadow: 0px 0px 30px 15px rgba(0, 0, 0, 0.02)]`,
  isOpen &&
    tw`max-h-96  pt-5 pb-4 rounded-2xl 
    lg:(pl-6 pt-7 pb-6 w-[36.2rem])`,
  !isOpen &&
    tw`w-[15rem] h-[2.625rem]  justify-center items-center rounded-lg
                 lg:h-[3.75rem]`,
  !filterExist && tw` top-[6rem]`,
  isDirections && tw`hidden lg:block`,
]);

export const Header = tw.div`
 w-full flex justify-between items-center gap-4 pr-6
`;

export const Title = tw.p`
text-sm font-bold lg:(text-lg leading-[1.375rem])
`;
