import { findNearest, getDistance, orderByDistance } from 'geolib';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useProgram = create(
  persist(
    (set) => ({
      programName: null,
      isEditting: false,
      startingPoint: null,
      program: [],
      reset: () =>
        set({
          program: [],
          isEditting: false,
          startingPoint: null,
          programName: null,
        }),
      setProgramName: (name) => set({ programName: name }),
      setSuggestedPlaces: (suggestedPlaces) => set({ suggestedPlaces }),
      setStartingPoint: (location) => set({ startingPoint: location }),
      createProgram: (destinations, cities, places, startingPoint) =>
        set({
          program: handleCreateProgram(
            destinations,
            cities,
            places,
            startingPoint,
          ),
        }),
      setProgram: (program) => set({ program }),
      addStepToProgram: (dayIndex, placeIndex, place) =>
        set((state) => {
          const newDayArr = state.program[dayIndex];
          newDayArr.splice(placeIndex, 0, place);

          const newProgram = state.program;
          newProgram.splice(dayIndex, 1, newDayArr);

          return { program: newProgram };
        }),
      replaceEmptyProgramStep: (dayIndex, place) =>
        set((state) => {
          const newDayArr = state.program[dayIndex];
          newDayArr.splice(0, 1, place);
          const newProgram = state.program;
          newProgram.splice(dayIndex, 1, newDayArr);

          return { program: newProgram };
        }),
      removeProgramStep: (id) =>
        set((state) => {
          const newProgram = state.program.map((day) =>
            day.filter((place) => place.id !== id),
          );
          return { program: newProgram };
        }),

      toggleEditting: () => set((state) => ({ isEditting: !state.isEditting })),
    }),
    {
      name: 'program',
    },
  ),
);

//helper to convert an array of coords to an array of the corespondant places (it keeps order as it's IMPORTANT)
const getProgramPlacesFromCords = (programArr, places, cities) =>
  programArr.map((coordsArr) =>
    coordsArr.map((coords) => {
      if (typeof coords === 'string') {
        return coords;
      } else {
        return places.find(
          (place) =>
            coords.latitude === place?.location.coordinates[1] &&
            coords.longitude === place?.location.coordinates[0],
        );
      }
    }),
  );

//
const getDuration = (places, place) => {
  const duration = places.filter(
    (p) =>
      p?.location.coordinates[0] === place.longitude &&
      p?.location.coordinates[1] === place.latitude,
  )[0]?.misc?.minVisitDuration;

  return duration ? parseInt(duration) : 120;
};

const calcTravelTime = (firstPont, secondPoint) => {
  //speed of m/min (60km/h)
  const speed = 1000;
  const distance = getDistance(firstPont, secondPoint) * 1.4;

  return parseInt((distance / speed).toFixed(0));
};

const handleCreateProgram = (destinations, cities, places, startingPoint) => {
  const maxMinutesPerDay = 600;

  const program = {};
  //reorder the cities if there is a starting point
  let newDestinations;
  if (startingPoint) {
    const { longitude, latitude } = startingPoint;
    const citiesCoords = destinations.map((dest) => ({
      latitude: dest.coordinates?.latitude,
      longitude: dest?.coordinates?.longitude,
    }));
    const orderedDestsCoords = orderByDistance(
      { latitude, longitude },
      citiesCoords,
    );

    newDestinations = orderedDestsCoords.map((coords) =>
      destinations.find(
        (city) =>
          coords.latitude === city?.coordinates?.latitude &&
          coords.longitude === city?.coordinates?.longitude,
      ),
    );
  }

  const arr = newDestinations ? newDestinations : destinations;

  arr?.forEach((dest, destIndex) => {
    //we need to find the current city coords so we can use it's location to determine the nearest starting point

    const cityCoordinates = cities.find(
      (city) => city.slug === dest.slug,
    ).coordinates;

    //get all the places coords for the current destination
    const cityPlacesCords = places
      .filter((place) => place.contact?.address?.city === dest.slug)
      .map((place) => ({
        latitude: place?.location.coordinates[1],
        longitude: place?.location.coordinates[0],
      }))
      .filter(
        //Filtering the duplicates
        (coords, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              coords.latitude === t.latitude &&
              coords.longitude === t.longitude,
          ),
      );

    //get the starting point
    let cityStartingPoint;
    if (destIndex === 0 && startingPoint) {
      //if we have a starting point defined by the user we use it as the first starting point iinstead of the city
      cityStartingPoint = findNearest(startingPoint, cityPlacesCords);
    } else {
      //use the nearest place to the city as a starting point
      cityStartingPoint = findNearest(cityCoordinates, cityPlacesCords);
    }

    // Init the current destiniation's program array
    program[dest.slug] = [];
    for (let i = 0; i < dest.days; i++) {
      let minutesSpent = 0;
      if (i === 0) {
        //intialize the day if it's the first day and push the starting point
        program[dest.slug][i] = [];
        if (cityStartingPoint) {
          program[dest.slug][i].push(cityStartingPoint);
          minutesSpent = minutesSpent + getDuration(places, cityStartingPoint);
          removePlace(cityStartingPoint, cityPlacesCords);
        } else {
          program[dest.slug][i].push(dest.slug);
        }
      } else {
        //use the last point of the previous day to find the nearest point to start the current day with
        const lastPointOfDay = program[dest.slug][i - 1].slice(-1);
        const nextStartingPoint = findNearest(lastPointOfDay, cityPlacesCords);
        if (nextStartingPoint) {
          //intialize the day and push the starting point if it exist
          program[dest.slug][i] = [];
          program[dest.slug][i].push(nextStartingPoint);
          minutesSpent = minutesSpent + getDuration(places, nextStartingPoint);
          removePlace(nextStartingPoint, cityPlacesCords);
        } else {
          program[dest.slug][i] = [dest.slug];
        }
      }

      //For each day we have to fill it with places untill the max mins are done.

      for (
        let j = 0;
        cityPlacesCords.length && minutesSpent <= maxMinutesPerDay;
        j++
      ) {
        const point = program[dest.slug][i][j];
        const nextNearby = findNearest(point, cityPlacesCords);
        if (nextNearby) {
          program[dest.slug][i].push(nextNearby);
          minutesSpent =
            minutesSpent +
            getDuration(places, nextNearby) +
            calcTravelTime(point, nextNearby);

          removePlace(nextNearby, cityPlacesCords);
        } else {
          break;
        }
      }
    }
  });

  return getProgramPlacesFromCords(Object.values(program).flat(), places);
};
export default useProgram;

const removePlace = (point, coordsArr) => {
  const indexOfpoint = coordsArr.findIndex(
    (item) =>
      item?.latitude === point.latitude && item?.longitude === point.longitude,
  );
  coordsArr.splice(indexOfpoint, 1);
};
