import PropTypes from 'prop-types';
import {
  Coords,
  Distance,
  Divider,
  Image,
  Info,
  Tag,
  Title,
  Wrapper,
} from './printCard.styles';
import { useTranslation } from 'react-i18next';

function PrintCard({ image, title, coords, distance, prevStop }) {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Tag>{`stop ${prevStop ? prevStop + 1 : 1}`}</Tag>

      <Image src={image} alt={title} />
      <Info>
        <Distance>
          {distance
            ? `${distance} ${t('fromStop')} ${prevStop}`
            : t('startingPoint')}
        </Distance>
        <Title>{title}</Title>
        <Coords>
          <span>{`${parseInt(coords[0]).toFixed(4)}° W`}</span>
          <Divider />
          <span>{`${parseInt(coords[1]).toFixed(4)}° N`}</span>
        </Coords>
      </Info>
    </Wrapper>
  );
}

PrintCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  coords: PropTypes.arrayOf(PropTypes.string),
};

export default PrintCard;
