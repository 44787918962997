import { keyframes } from 'styled-components';
import tw, { styled } from 'twin.macro';

const pulseAnimation = keyframes`
  0% { scale: 0.9; opacity: 0.5 }
  100% {scale: 1.1; opacity: 0.4}
 
`;

export const Point = styled.div`
  ${tw`relative w-3 h-3 bg-orange rounded-full border-[3px] border-white`}
  &:after {
    content: '';
    ${tw`absolute -z-10 -left-[30px] -top-7  bg-[rgba(235, 103, 44, 0.4)] 
         w-16 h-16 border-[1px] border-orange rounded-full origin-center`}
    animation: ${pulseAnimation} 1.5s ease-in infinite alternate;
  }
`;
