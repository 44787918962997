import styled from 'styled-components';
import tw from 'twin.macro';

export const Wrapper = styled.div`
  ${tw`relative flex flex-col gap-2.5 rounded-[9px] min-h-[11rem]
  border border-[#F0F0F0] max-w-[10.625rem]`}
  @media print {
    break-inside: avoid;
  }
`;

export const TagsWrapper = tw.div`
   max-w-full flex items-center gap-0.5 flex-wrap
`;

export const Tag = tw.span`
absolute left-[9px] top-[11px] inline-flex px-2 py-[3px] bg-[#555] rounded-full 
  text-white text-[7px] font-semibold whitespace-nowrap
`;

export const Image = tw.img`
  w-full  h-[5.563rem] object-cover rounded-[9px]
`;

export const Info = tw.div`
  flex flex-col gap-2.5 capitalize
  pl-2.5 pr-5 pb-2.5
`;

export const Distance = tw.p`
  text-[#2B2B2B] text-[7px] font-bold underline
`;

export const Title = tw.h2`
  text-black text-xs font-medium
`;

export const Coords = tw.div`
 flex justify-between items-center gap-1
 text-[#525252] text-[10px]
`;
export const Divider = tw.span`
  w-[1px] h-[15px] bg-[#D9D9D9;]
 `;
