import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function useCurrentPosition() {
  const [position, setPosition] = useState();
  const [error, setError] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    let canceled = false;
    const showError = (error) => {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          setError(t('denidedLocation'));
          break;
        case error.POSITION_UNAVAILABLE:
          setError(t('LocationUnavailable'));
          break;
        case error.TIMEOUT:
          setError(t('timeout'));
          break;
        case error.UNKNOWN_ERROR:
          setError(t('unknown'));
          break;
        default:
          setError(t('unknown'));
          break;
      }
    };
    const getLocation = () => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (!canceled) {
            setError(null);
            setPosition(position);
          }
        },
        (error) => {
          if (!canceled) {
            showError(error);
          }
        },
      );
    };

    const watchStatus = async () => {
      const permissionStatus = await navigator.permissions.query({
        name: 'geolocation',
      });
      getLocation();
      permissionStatus.onchange = () => {
        getLocation();
      };
    };

    watchStatus();

    return () => {
      canceled = true;
    };
  }, [t]);

  return [position, error];
}
