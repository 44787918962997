const Search = ({ color }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.377 10.7557C20.377 16.1495 16.0335 20.5113 10.6885 20.5113C5.3436 20.5113 1 16.1495 1 10.7557C1 5.36185 5.3436 1 10.6885 1C16.0335 1 20.377 5.36185 20.377 10.7557Z"
        stroke="#FDFDFD"
        strokeWidth="2"
      />
      <path
        d="M23.2112 24.8038C22.796 24.7904 22.4012 24.6195 22.1059 24.3255L19.5747 21.3669C19.032 20.8706 18.9876 20.0272 19.4752 19.476C19.7034 19.2451 20.0137 19.1152 20.3374 19.1152C20.661 19.1152 20.9713 19.2451 21.1995 19.476L24.3829 22.0231C24.8414 22.4925 24.9829 23.1892 24.7441 23.8019C24.5053 24.4147 23.9309 24.8289 23.2775 24.8594L23.2112 24.8038Z"
        fill="#FDFDFD"
      />
    </svg>
  );
};

export default Search;
