const ChevronLeft = () => {
  return (
    <svg
      width="9"
      height="14"
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 12.4307L2.25 6.99963L7.5 1.56859"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};

export default ChevronLeft;
