const Trip = () => {
  return (
    <svg
      width="26"
      height="29"
      viewBox="0 0 26 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.26119 6.87232C2.23327 12.1873 0.676026 22.0709 10.6705 19.0859C23.1636 15.3546 19.8549 8.69855 14.8434 9.87516C9.83184 11.0518 3.12396 23.7011 13.9076 24.1624L18.4993 25.0002"
        stroke="black"
        strokeWidth="2"
        strokeDasharray="2 2"
      />
      <circle
        cx="5.8351"
        cy="3.16044"
        r="2"
        transform="rotate(15.842 5.8351 3.16044)"
        fill="#212324"
      />
      <line x1="22.1046" y1="24.042" x2="24.5407" y2="28.4084" stroke="black" />
      <line
        x1="21.1392"
        y1="27.4432"
        x2="25.5056"
        y2="25.0071"
        stroke="black"
      />
    </svg>
  );
};

export default Trip;
