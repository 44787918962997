import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import {
  CloseButton,
  ErrorMessage,
  Input,
  SavedIcon,
  SavePanel,
} from './SaveModal.styles';
import Close from 'Icons/Close';
import Title from 'components/Typography/Title';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import Heart from 'Icons/Heart';

function SaveModal({ status, handleSave, isOpen, closeModal, name, setName }) {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <SavePanel>
        {status !== 'success' ? (
          <>
            <CloseButton variant="roundedWhite" onClick={closeModal}>
              <Close />
            </CloseButton>
            <Title level="label" size="lg">
              {t('askProgramName')}
            </Title>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="name..."
            />
            {status === 'error' && (
              <ErrorMessage>{t('nameExist')}</ErrorMessage>
            )}
            <Button
              onClick={() => handleSave(name)}
              disabled={name.length === 0}
              variant="primary"
              size="small"
            >
              {t('saveToFav')}
            </Button>
          </>
        ) : (
          <>
            <SavedIcon>
              <Heart fill="white" />
            </SavedIcon>
            <Title>{t('progamSaved')}</Title>
          </>
        )}
      </SavePanel>
      {/*
        This is mobile version 
      <SavePanelMobile>
      <Title level="label" size="lg">
        {t('askProgramName')}
      </Title>
      <MobileInputWrapper>
        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="name..."
        />
        <MobileSaveButton>
          <CheckMark />
        </MobileSaveButton>
      </MobileInputWrapper>
      </SavePanelMobile>
      */}
    </Modal>
  );
}

SaveModal.propTypes = {
  status: PropTypes.oneOf([null, 'error', 'success']),
  isOpen: PropTypes.bool.isRequired,
};

export default SaveModal;
