import Title from 'components/Typography/Title';
import { Link } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

const types = {
  place: tw`bg-[#FCC433] `,
  program: tw`bg-[#B5CD3A] text-white`,
  suggestedProgram: tw`bg-[#E18C3C] `,
};
export const Wrapper = tw.div`
  w-full relative h-[15.188rem] md:h-[20rem]
`;
export const FavoriteCardWrapper = styled(Link)`
  ${tw`inline-block relative w-full h-full md:max-w-[32.313rem] `}
  &:after {
    content: '';
    ${tw`absolute bottom-0 w-full h-[8.313rem] rounded-b-[1.75rem]`};
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    opacity: 0.6;
  }
`;

export const FavoriteImage = tw.img`
  w-full h-full object-cover rounded-[1.75rem]
`;

export const FavoriteBtn = styled.button`
  ${tw`absolute  z-10 w-[3.063rem] h-[2.994rem] rounded-full flex items-center justify-center
  top-6 right-6 bg-[#EB672C] text-white`}
  ${`box-shadow: 0px 4px 7px rgba(235, 103, 44, 0.5);`}
`;

export const RemoveBtn = styled.button`
  ${tw`absolute  z-10 w-[3.063rem] h-[2.994rem] rounded-full flex items-center justify-center
  top-6 right-6 bg-orange text-white`}
  ${`box-shadow: 0px 4px 7px rgba(235, 103, 44, 0.5);`}
`;

export const Tag = styled.div(() => [
  tw`absolute top-6 left-6 px-4 py-2 bg-white
      rounded-[2.125rem] font-bold text-xs capitalize`,
  ({ type }) => types[type],
]);

export const InfoWrapper = tw.div`
w-[80%] text-white absolute bottom-6 left-6  z-20
flex flex-col justify-center items-start gap-3
`;

export const FavoriteTitle = styled(Title)`
  ${tw`w-full text-white`}
`;

export const Breadcrumbs = tw.ul`
   w-full flex gap-x-4 gap-y-1 justify-start items-center flex-wrap
`;

export const Item = styled.li`
  ${tw`flex justify-center items-center gap-1.5 whitespace-nowrap
       capitalize text-sm md:text-base text-white font-bold`}
  &:after {
    content: '';
    ${tw`bg-white w-0.5 h-4 ml-2`}
  }
  &:last-child:after {
    display: none;
  }
`;

export const ConfirmPanel = tw.div`
 w-full bg-white flex flex-col justify-center items-center gap-8
 rounded-3xl  px-6 py-12 text-center md:(px-16 py-14 )
`;

export const ConfirmMessage = tw.p`
 text-base capitalize text-black font-normal opacity-70
 tracking-[-0.03em]
`;
export const Buttons = tw.div`
  w-full flex justify-center items-center gap-4
`;
