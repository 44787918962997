import { useEffect, useState } from 'react';

export default function useScrollVisible(elementRef) {
  const [scrollIsVisible, setScrollIsVisible] = useState(false);
  const element = elementRef?.current;
  useEffect(() => {
    const handlResize = () => {
      if (element && element.scrollHeight > element.clientHeight - 10) {
        setScrollIsVisible(true);
      } else {
        setScrollIsVisible(false);
      }
    };

    handlResize();
    element?.addEventListener('resize', handlResize);
  }, [setScrollIsVisible, element]);

  return scrollIsVisible;
}
