import tw, { styled } from 'twin.macro';

export const Wrapper = styled.li`
  ${tw`flex justify-center items-center py-2 px-4  gap-8
  md:px-7 md:gap-12
  bg-[#FBFBFB] border-[1px] border-[#BBBBBB] rounded-[0.7rem]
  `}
  ${({ isResult }) => isResult && tw`pointer-events-none grayscale`}
  > * {
    ${tw`relative`};
    &:not(:last-child):after {
      content: '';
      ${tw`absolute -right-4 md:-right-6 top-1/2 -translate-y-1/2 w-[1px] h-11 bg-[rgba(0, 0, 0, 0.2)]`}
    }
  }
`;

export const Name = tw.p`
 w-[3rem] md:w-[7rem] text-base font-bold tracking-[-1px] whitespace-nowrap text-ellipsis overflow-hidden
`;

export const Icon = tw.span`
w-[3rem] md:w-[5rem] flex justify-center items-center
`;
export const Counter = tw.div`
 w-full flex justify-between items-center gap-2.5 bg-white py-1 px-0.5
 md:p-1 md:gap-3.5
 border-[1px] border-[#DFDFDF] rounded-xl
`;

export const CounterBtn = tw.span`
  w-9 h-9 rounded-full flex justify-center items-center text-center
  bg-green font-bold bg-opacity-20 cursor-pointer
`;

export const NumberOfDays = tw.p`
   flex justify-center items-center whitespace-nowrap
   text-sm md:text-lg font-normal capitalize
`;
