const BackArrow = () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9181 6.6905C16.8445 6.67811 16.7699 6.6724 16.6953 6.6734H3.9967L4.2736 6.53991C4.54425 6.40714 4.79048 6.22643 5.00125 6.00597L8.56227 2.31505C9.03127 1.85102 9.11007 1.10454 8.74902 0.546353C8.3288 -0.0484566 7.52295 -0.177605 6.94903 0.257938C6.90266 0.293145 6.85859 0.331522 6.81718 0.37282L0.377725 7.04716C-0.125519 7.56818 -0.125962 8.41336 0.376718 8.93496C0.37704 8.93529 0.377403 8.93567 0.377725 8.936L6.81718 15.6103C7.32083 16.1309 8.13626 16.1297 8.63854 15.6077C8.67806 15.5666 8.71497 15.5229 8.74902 15.4769C9.11007 14.9187 9.03127 14.1722 8.56227 13.7082L5.00769 10.0106C4.81874 9.81451 4.60148 9.65012 4.36375 9.52335L3.97738 9.34314H16.6245C17.2824 9.36846 17.8596 8.89212 17.9832 8.22185C18.0971 7.49414 17.6202 6.80856 16.9181 6.6905Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default BackArrow;
