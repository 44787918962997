import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import {
  Description,
  ExtraTitle,
  Image,
  LeftSide,
  RightSide,
  Wrapper,
} from './NotFound.styles';
import notFound from 'assets/404.svg';
import BackArrow from 'Icons/BackArrow';
import { useNavigate } from 'react-router';

export default function NotFound() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Wrapper>
      <LeftSide>
        <Image src={notFound} alt="404" />
      </LeftSide>
      <RightSide>
        <ExtraTitle level="h1" size="lg">
          {t('notFound')}
          <Description>{t('notFoundDesc')}</Description>
        </ExtraTitle>
        <Button
          onClick={() => navigate('/')}
          className="px-10"
          variant="primary"
          size="large"
        >
          <BackArrow />
          {t('goBackHome')}
        </Button>
      </RightSide>
    </Wrapper>
  );
}
