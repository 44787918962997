const Info = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.30233 4.20965C5.41945 4.47477 5.72932 4.59461 5.99438 4.47753L5.9944 4.47753C6.49105 4.25806 7.02441 4.17581 7.47119 4.21318C7.92373 4.25104 8.26154 4.40835 8.40387 4.63865C8.563 4.89622 8.59415 5.1853 8.49895 5.48661C8.40289 5.79063 8.17665 6.11057 7.81494 6.41704L7.81494 6.41703L7.81412 6.41774L7.57425 6.6255L7.57425 6.62549L7.57333 6.62631C7.17607 6.97905 6.90038 7.27418 6.72465 7.56742C6.54687 7.86408 6.47356 8.15505 6.47356 8.49436C6.47356 8.78416 6.70842 9.01914 6.99827 9.01914C7.28809 9.01914 7.52305 8.78418 7.52305 8.49436C7.52305 8.19554 7.65841 7.95 8.28204 7.40016C8.28214 7.40008 8.28223 7.4 8.28233 7.39991L8.59872 7.12553L8.59874 7.12555L8.60038 7.12406C9.57362 6.24149 9.91253 5.08346 9.29669 4.0869L9.29668 4.08689C8.97284 3.56298 8.38118 3.26927 7.70845 3.18138C7.0348 3.09337 6.26764 3.20933 5.5702 3.51754L5.5702 3.51754C5.30513 3.6347 5.18516 3.94455 5.30233 4.20965ZM5.30233 4.20965L5.3938 4.16924M5.30233 4.20965C5.30233 4.20965 5.30233 4.20966 5.30234 4.20967L5.3938 4.16924M5.3938 4.16924C5.48859 4.38381 5.7394 4.48084 5.95398 4.38606L5.61062 3.60901C5.39605 3.70385 5.29895 3.95466 5.3938 4.16924ZM13.7529 7.07851C13.7529 3.348 10.7288 0.323828 6.99825 0.323828C3.26792 0.323828 0.24375 3.348 0.24375 7.07851C0.24375 10.8088 3.26774 13.833 6.99825 13.833C10.7288 13.833 13.7529 10.809 13.7529 7.07851ZM1.29323 7.0784C1.29323 3.92761 3.84745 1.37338 6.99825 1.37338C10.149 1.37338 12.7033 3.92761 12.7033 7.0784C12.7033 10.2292 10.149 12.7834 6.99825 12.7834C3.84745 12.7834 1.29323 10.2292 1.29323 7.0784ZM7.6646 10.4765C7.6646 10.1085 7.36628 9.81013 6.99825 9.81013C6.63024 9.81013 6.3319 10.1085 6.3319 10.4765C6.3319 10.8445 6.63024 11.1428 6.99825 11.1428C7.36626 11.1428 7.6646 10.8445 7.6646 10.4765Z"
        fill="black"
        stroke="black"
        strokeWidth="0.2"
      />
    </svg>
  );
};

export default Info;
