import { useMemo } from 'react';
import { ButtonWrapper, ChildWrapper } from './TripPlaner.styles';
import Steps from 'components/CreatePlan/Steps';
import { Outlet, useLocation, useNavigate } from 'react-router';
import ChevronLeft from 'Icons/ChevronLeft';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import usePlaces from 'store/usePlaces';
import useTripForm from 'store/useTripForm';
import useProgram from 'store/useProgram';
import ChevronRight from 'Icons/ChevronRight';
import { trackGoogleAnalyticsEvent } from 'utils/google-analytics';
import { planTripCategory } from 'utils/google-analytics/events/planTrip';

export default function TripPlaner() {
  const { withoutCommercialPlaces, cities } = usePlaces();
  const { steps, destinations, preferences } = useTripForm();
  const { startingPoint, isEditting, toggleEditting, createProgram } =
    useProgram();

  const navigate = useNavigate();
  const location = useLocation();

  const step = parseInt(location.pathname.split('/')[3]);

  const handleNext = (e) => {
    e.preventDefault();
    if (step < 2) navigate((step + 1).toString());
  };

  const handlePrev = (e) => {
    e.preventDefault();
    if (step > 1) navigate((step - 1).toString());
  };

  //filter the popular places accordding to the user's chosen destinations and preferences
  const filteredPlaces = useMemo(
    () =>
      withoutCommercialPlaces?.filter(
        (place) =>
          destinations
            .map((dest) => dest.slug)
            .includes(place.contact?.address?.city) &&
          place.tags?.some((tag) => preferences.includes(tag.slug)),
      ),
    [withoutCommercialPlaces, destinations, preferences],
  );

  const getDisbaled = () => {
    if (step === 1) {
      return !(destinations?.length > 0);
    } else if (step === 2) {
      return !(preferences?.length > 0);
    }
  };

  const handleConfirm = (e) => {
    e.preventDefault();
    if (isEditting) toggleEditting();
    createProgram(destinations, cities, filteredPlaces, startingPoint);
    destinations.forEach((dest) => {
      trackGoogleAnalyticsEvent(planTripCategory, 'region_select', dest.slug);
      trackGoogleAnalyticsEvent(
        planTripCategory,
        'region_duration',
        dest.slug,
        dest.days,
      );
    });
    navigate('/program-result');
  };
  const { t } = useTranslation();
  return (
    <>
      <Steps steps={steps} activeStep={step} />
      <ChildWrapper>
        <Outlet />
      </ChildWrapper>
      <ButtonWrapper>
        <Button
          onClick={(e) => handlePrev(e)}
          disabled={step === 1}
          variant="secondary"
          size="small"
        >
          <ChevronLeft />
          {t('previous')}
        </Button>
        <Button
          onClick={(e) => (step === 2 ? handleConfirm(e) : handleNext(e))}
          variant="primary"
          size="small"
          disabled={getDisbaled()}
        >
          {step === 2 ? (isEditting ? t('update') : t('confirm')) : t('next')}
          {step !== 2 && <ChevronRight />}
        </Button>
      </ButtonWrapper>
    </>
  );
}
