import React from 'react';
import {
  Wrapper,
  MapList,
  Region,
  EmptyDay,
  AddYourStep,
  AddStepButton,
} from './DayList.styles';
import ProgramCard from '../ProgramCard';
import StepAdd from '../ProgramButtons/StepAdd';
import i18next from 'i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import getDistance from 'geolib/es/getPreciseDistance';
import {
  getcityName,
  getDistanceWithUnit,
  getRegionColor,
} from 'utils/helpers';
import placeholder from 'assets/placeholder.svg';
import Marker from 'Icons/Marker';
import { useInView } from 'react-intersection-observer';
import usePlaces from 'store/usePlaces';
import Box from 'Icons/Box';
import { useTranslation } from 'react-i18next';
import Plus from 'Icons/Plus';

const DayList = ({
  items,
  toggleFavorites,
  isFavorites,
  programName,
  handleHover,
  handleChange,
  setScrollPos,
  handleRemove,
  withBorder = true,
  isSuggestion = false,
  dayIndex,
  ...rest
}) => {
  const { cities } = usePlaces();
  const navigate = useNavigate();
  const location = useLocation();

  const getPlaceCords = (place) => ({
    latitude: place?.location.coordinates[1],
    longitude: place?.location.coordinates[0],
  });

  const handleAdd = (id, dayIndex = null, isFirst) => {
    navigate(`/add-step/${id}`, {
      state: {
        prevRoute: location.pathname,
        name: programName ? programName : null,
        dayIndex,
        isFirst,
      },
    });
  };

  const { ref } = useInView({
    threshold: 0.3,
    onChange: handleChange,
  });

  const getRegion = (item) => {
    return typeof item === 'string' ? item : item.contact?.address?.city;
  };
  const { t } = useTranslation();

  return (
    <Wrapper ref={ref} withBorder={withBorder} {...rest}>
      {withBorder && items[0] && (
        <Region color={getRegionColor(getRegion(items[0]))}>
          <Marker />
          {getcityName(cities, getRegion(items[0]))[i18next.language]}
        </Region>
      )}
      {typeof items[0] === 'string' ? (
        <EmptyDay>
          <Box />
          <AddYourStep>{t('addYourStop')}</AddYourStep>
          <AddStepButton onClick={() => handleAdd(items[0], dayIndex)}>
            <Plus />
            {t('addStep')}
          </AddStepButton>
        </EmptyDay>
      ) : (
        <MapList>
          {items?.length > 0 &&
            items?.map((item, index) => (
              <React.Fragment key={item.id}>
                {!isSuggestion && index === 0 && (
                  <StepAdd
                    isFirst={true}
                    id={item.id}
                    handleAdd={handleAdd}
                    distance={null}
                  />
                )}
                <ProgramCard
                  id={item.id}
                  number={index + 1}
                  title={item.title[i18next.language]}
                  image={item.media[0]?.url || placeholder}
                  handleFav={() => toggleFavorites(item)}
                  handleHover={() =>
                    handleHover(
                      item?.location?.coordinates,
                      items[index + 1]?.location?.coordinates,
                    )
                  }
                  handleRemove={handleRemove}
                  hasRemove={isSuggestion ? false : items?.length > 1}
                  isFavorites={isFavorites(item.id)}
                />
                {!isSuggestion && (
                  <StepAdd
                    id={item.id}
                    handleAdd={handleAdd}
                    isLast={index === items.length - 1}
                    distance={
                      index !== items.length - 1
                        ? getDistanceWithUnit(
                            getDistance(
                              getPlaceCords(item),
                              getPlaceCords(items[index + 1]),
                            ),
                          )
                        : null
                    }
                  />
                )}
              </React.Fragment>
            ))}
        </MapList>
      )}
    </Wrapper>
  );
};
export default DayList;
