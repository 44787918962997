import tw from 'twin.macro';

export const FavoriteListWrapper = tw.div`
 w-full flex flex-col gap-6
`;

export const FavoriteMapList = tw.div`
 w-full flex flex-col gap-4
 md:gap-6
`;
