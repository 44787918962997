const ChevronRight = () => {
  return (
    <svg
      width="9"
      height="14"
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 0.999998L7 7L0.999999 13"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};

export default ChevronRight;
