import DestinationDates from 'components/CreatePlan/DestinationDates';
import ProgramSave from 'components/Program/ProgramButtons/ProgramSave';
import DayList from 'components/Program/DayList';
import SaveModal from 'components/Program/SaveModal';
import PlanSwiper from 'components/Program/PlanSwiper';
import SideCloseBtn from 'components/SideCloseBtn';
import StartPoint from 'components/StartPoint';
import Title from 'components/Typography/Title';
import { getBounds, getRhumbLineBearing } from 'geolib';
import getDistance from 'geolib/es/getPreciseDistance';
import ChevronDown from 'Icons/ChevronDown';
import EditIcon from 'Icons/EditIcon';
import Step2 from 'pages/CreateProgram/Step2';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl';
import { useLocation, useNavigate } from 'react-router-dom';
import useFavorites from 'store/useFavorites';
import usePlaces from 'store/usePlaces';
import useProgram from 'store/useProgram';
import useTripForm from 'store/useTripForm';

import { getDistanceWithUnit } from 'utils/helpers';
import {
  CloseBtn,
  Content,
  EditBtn,
  Header,
  Result,
  ResultTitle,
  ResultWrapper,
  Wrapper,
} from './ProgramResult.styles';
import FixedSwiper from 'components/Program/FixedSwiper';
import useStepScroll from 'store/useStepScroll';
import { useInView } from 'react-intersection-observer';
import useBrekpoint from 'hooks/useBrekpoint';
import { format } from 'date-fns';
import { useCurrentPosition } from 'hooks/useCurrentPosition';
import ArrowLeft from 'Icons/ArrowLeft';
import Button from 'components/Button';

export default function ProgramResult() {
  const { main_map } = useMap();
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation();
  const { setScrollId, id } = useStepScroll();
  const { cities, withoutCommercialPlaces, setPlaces, allPlaces } = usePlaces();
  const { destinations, preferences } = useTripForm();
  const {
    program,
    suggestedPlaces,
    programName,
    setProgramName,
    toggleEditting,
    startingPoint,
    setStartingPoint,
    createProgram,
    removeProgramStep,
    reset,
  } = useProgram();
  const { toggleFavorites, isFavorites, programs, addProgram } = useFavorites();
  const { resetForm } = useTripForm();
  const [saveIsOpen, setSaveIsOpen] = useState(false);
  const [name, setName] = useState('');
  const [status, setStatus] = useState(null);

  const { isSmallDevice, heigth } = useBrekpoint();

  const [activeDay, setActiveDay] = useState(1);
  const [position, error] = useCurrentPosition();

  const pageRef = useRef(null);

  const animateTo = useCallback(
    ({ longitude, latitude }) => {
      main_map?.flyTo({
        center: [longitude, latitude],
        duration: 2000,
        curve: 2,
        zoom: 12,
      });
    },
    [main_map],
  );

  const headTo = useCallback(
    (start, target) => {
      if ((start, target)) {
        const { minLat, maxLat, minLng, maxLng } = getBounds([start, target]);

        const bearing = getRhumbLineBearing(start, target);
        main_map?.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          {
            padding: isSmallDevice
              ? { left: 0, top: 0, bottom: (heigth * 80) / 100 }
              : { left: 250, top: 50, bottom: 100 },
            pitch: 80,
            bearing,
            curve: 2,
            speed: 0.5,
          },
        );
      }
    },
    [main_map, heigth, isSmallDevice],
  );

  useEffect(() => {
    if (program.length > 0) {
      if (typeof program[activeDay - 1] !== 'string') {
        const firstLocation = program[activeDay - 1][0]?.location?.coordinates;
        const secondLocation = program[activeDay - 1][1]?.location?.coordinates;

        const placesWithNumbers = program[activeDay - 1]?.map((place, i) => ({
          ...place,
          number: i + 1,
        }));

        setPlaces(placesWithNumbers);
        if (secondLocation) {
          headTo(
            {
              longitude: firstLocation[0],
              latitude: firstLocation[1],
            },
            {
              longitude: secondLocation[0],
              latitude: secondLocation[1],
            },
          );
        } else if (firstLocation) {
          animateTo({
            longitude: firstLocation[0],
            latitude: firstLocation[1],
          });
        }
      }
    }
  }, [program, activeDay, headTo, animateTo, setPlaces]);

  const handleHover = (start, target) => {
    headTo(start, target);
  };

  const daysArr = useMemo(
    () =>
      program.map((day, index) => {
        if (typeof day[0] !== 'string') {
          const firstPlaceCords = {
            latitude: day[0]?.location?.coordinates[1],
            longitude: day[0]?.location?.coordinates[0],
          };
          const lastPlaceCords = {
            latitude: day.slice(-1)[0]?.location?.coordinates[1],
            longitude: day.slice(-1)[0]?.location?.coordinates[0],
          };
          const distance = getDistance(firstPlaceCords, lastPlaceCords) * 1.4;
          return {
            region: day[0].contact?.address?.city,
            number: index + 1,
            distance: getDistanceWithUnit(distance),
          };
        } else {
          return {
            distance: 0 + 'm',
            region: day[0],
            number: index + 1,
          };
        }
      }),
    [program],
  );

  const filteredPlaces = useMemo(
    () =>
      suggestedPlaces?.length > 0
        ? suggestedPlaces
        : withoutCommercialPlaces?.filter(
            (place) =>
              destinations
                .map((dest) => dest.slug)
                .includes(place.contact?.address?.city) &&
              place.tags?.some((tag) => preferences.includes(tag.slug)),
          ),
    [withoutCommercialPlaces, destinations, preferences, suggestedPlaces],
  );

  const handleEdit = () => {
    toggleEditting();
    navigate('/create-program/trip-planer/1');
  };

  const handleUseMyLocation = () => {
    if (!error && position?.coords) {
      const { latitude, longitude } = position?.coords;
      const date = format(new Date(position.timestamp), 'MMMM do. HH:mm a z');
      const newStartingPoint = {
        latitude,
        longitude,
        date,
        type: 'user',
      };
      setStartingPoint(newStartingPoint);
      createProgram(
        destinations,
        cities,
        filteredPlaces,
        startingPoint,
        newStartingPoint,
      );
    } else if (error) {
      alert(error);
    }
  };

  const handleChooseLocation = (location) => {
    setStartingPoint(location);
    createProgram(destinations, cities, filteredPlaces, location);
  };

  const closeModal = () => {
    setSaveIsOpen(false);
  };

  const handleSave = (name) => {
    const isExist = programs.hasOwnProperty(name);
    if (isExist) {
      setStatus('error');
      setTimeout(() => {
        setStatus(null);
      }, 1000);
    } else {
      addProgram(name, program);
      setStatus('success');
      setProgramName(name);
      setTimeout(() => {
        setScrollId(null);
        closeModal();
        navigate(`/program/${name}`);
      }, 1000);
      setTimeout(() => {
        resetForm();
        reset();
      }, 2000);
    }
  };

  //handle scrolling
  const [isAtTop, setIsAtTop] = useState(false);
  const [swiper, setSwiper] = useState(null);

  const planSwiperRef = useRef(null);
  const [locationRef, locationInView] = useInView({
    threshold: 0.05,
  });
  const [saveRef, saveInView] = useInView();

  //scroll into the newly added step
  useEffect(() => {
    if (id && location?.state?.prevRoute === 'add-step') {
      const step = document.getElementById(id);
      step?.scrollIntoView();
    } else {
      planSwiperRef?.current?.scrollIntoView();
    }
  }, [id, location]);

  //handle the save program styles/position
  useEffect(() => {
    if (!locationInView && !saveInView) {
      setIsAtTop(false);
    } else if (locationInView && saveInView) {
      setIsAtTop(false);
    } else if (saveInView && !locationInView) {
      setIsAtTop(true);
    }
  }, [locationInView, saveInView]);

  //switch the active day and slide the swiper
  const handleViewChange = (inView, entry) => {
    const id = parseInt(entry?.target?.id);
    if (inView) setActiveDay(id);

    if (!swiper?.destroyed) {
      if (id >= 5 && !swiper?.isEnd) {
        swiper?.slideNext();
      } else if (id <= 3 && !swiper?.isBeginning) {
        swiper?.slidePrev();
      }
    }
  };

  const handleClose = () => {
    pageRef?.current?.classList.add(
      isSmallDevice ? 'animate__fadeOutDown' : 'animate__fadeOutLeft',
    );
    setPlaces(allPlaces);
    setTimeout(() => {
      navigate('/');
    }, 600);
  };

  const goPreview = () => {
    setTimeout(() => {
      resetForm();
      reset();
    }, 2000);
    navigate(-2);
  };

  return (
    <Wrapper ref={pageRef} className="animate__animated">
      <SideCloseBtn page={pageRef} />
      <CloseBtn onClick={handleClose}>
        <ChevronDown />
      </CloseBtn>
      <Content>
        {suggestedPlaces?.length > 0 ? (
          <Header>
            <Button variant="square" onClick={() => goPreview()}>
              <ArrowLeft />
            </Button>
            <Title level="h1" size="lg">
              {programName}
            </Title>
          </Header>
        ) : (
          <ResultWrapper>
            <ResultTitle>
              <Title level="h1" size="lg">
                {t('yourPlaneTrip')}
              </Title>
              <EditBtn onClick={handleEdit}>
                <EditIcon />
                {t('edit')}
              </EditBtn>
            </ResultTitle>
            <Result>
              <DestinationDates isResult={true} destinations={destinations} />
              <Step2 isResult={true} />
            </Result>
          </ResultWrapper>
        )}
        <PlanSwiper
          ref={planSwiperRef}
          days={daysArr}
          active={activeDay}
          setActive={setActiveDay}
        />
        <StartPoint
          ref={locationRef}
          isAtTop={isAtTop}
          startingPoint={startingPoint}
          handleUseMyLocation={handleUseMyLocation}
          handleChooseLocation={handleChooseLocation}
        />
        <ProgramSave
          ref={saveRef}
          isAtTop={isAtTop}
          setSaveIsOpen={setSaveIsOpen}
        />
        {program?.length > 0 &&
          program.map((day, i) => (
            <DayList
              dayIndex={i}
              key={i}
              id={i + 1}
              items={day}
              handleHover={handleHover}
              toggleFavorites={toggleFavorites}
              isFavorites={isFavorites}
              handleChange={handleViewChange}
              handleRemove={removeProgramStep}
            />
          ))}
      </Content>
      <SaveModal
        status={status}
        isOpen={saveIsOpen}
        name={name}
        setName={setName}
        closeModal={closeModal}
        handleSave={handleSave}
      />
      {isAtTop && (
        <FixedSwiper
          setSwiper={setSwiper}
          days={daysArr}
          active={activeDay}
          setActive={setActiveDay}
        />
      )}
    </Wrapper>
  );
}
