const Label = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.789975 0H0.785822C0.351982 0 0 0.351982 0 0.785822V0.789975L0.0460478 10.2645C0.0474085 10.4803 0.134706 10.6749 0.276351 10.8158L11.2303 21.7698C11.3726 21.9121 11.5693 22.0001 11.7859 22.0001C12.0024 22.0001 12.1991 21.9121 12.3414 21.7698L21.7697 12.3415C21.912 12.1992 22 12.0026 22 11.786C22 11.5694 21.9119 11.3728 21.7697 11.2305L10.8164 0.276495C10.6748 0.134841 10.4795 0.0469069 10.2643 0.0461919L0.789975 0ZM1.57516 1.57509L9.93387 1.61562L20.1026 11.7859L11.7852 20.1033L1.61494 9.93309L1.57441 1.57511L1.57516 1.57509ZM6.28591 4.71412C5.42725 4.71412 4.71424 5.42705 4.71424 6.28579C4.71424 7.14445 5.42717 7.85745 6.28591 7.85745C7.14457 7.85745 7.85757 7.14452 7.85757 6.28579C7.85757 5.42713 7.14464 4.71412 6.28591 4.71412Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Label;
