import MapS from 'Icons/Goe';
import Minus from 'Icons/Minus';
import Plus from 'Icons/Plus';
import WhiteHeart from 'Icons/WhiteHeart';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useFavorites from 'store/useFavorites';
import {
  ControleButton,
  ControlsGrp,
  Count,
  FavButton,
  Overlay,
  ToggleImage,
  ToggleWrapper,
  Wrapper,
} from './NavigationControls.styles';

function NavigationControls({
  zoomIn,
  zoomOut,
  handleUserLocation,
  toggleStyle,
  image,
  text,
}) {
  const navigate = useNavigate();
  const { programs, favorites } = useFavorites();
  const [animate, setAnimate] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const newCount = favorites.length + Object.keys(programs).length;
    setCount(newCount);

    if (newCount > count) {
      setAnimate((prev) => !prev);
    }
    // eslint-disable-next-line
  }, [programs, favorites]);

  const goFavPage = () => {
    navigate('favorites');
  };
  return (
    <Wrapper>
      <ToggleWrapper onClick={toggleStyle}>
        <ToggleImage src={image} alt="switch style" />
        <Overlay>{text}</Overlay>
      </ToggleWrapper>
      <ControlsGrp>
        <ControleButton onClick={zoomOut}>
          <Minus />
        </ControleButton>
        <ControleButton onClick={zoomIn}>
          <Plus />
        </ControleButton>
        <ControleButton onClick={handleUserLocation}>
          <MapS />
        </ControleButton>
        <FavButton key={animate} count={count} onClick={goFavPage}>
          {count > 0 && <Count>{count}</Count>}
          <WhiteHeart />
        </FavButton>
      </ControlsGrp>
    </Wrapper>
  );
}

NavigationControls.propTypes = {
  zoomOut: PropTypes.func.isRequired,
  zoomIn: PropTypes.func.isRequired,
};

export default NavigationControls;
