import PropTypes from 'prop-types';
import Title from 'components/Typography/Title';
import ArrowLeft from 'Icons/ArrowLeft';
import placeholder from 'assets/placeholder.svg';
import {
  AddFav,
  BackButton,
  Image,
  Infos,
  ShadowDrop,
  Type,
  Wrapper,
} from './MobilePoi.styles';
import Heart from 'Icons/Heart';
import { forwardRef } from 'react';
import { A11y, Autoplay, Pagination } from 'swiper';
import { SwiperSlide, Swiper } from 'swiper/react';

const MobilePoi = forwardRef(
  (
    { media, type, title, handleClick, handleFav, isFavorites, ...rest },
    ref,
  ) => {
    return (
      <Wrapper ref={ref} {...rest}>
        <Swiper
          modules={[A11y, Autoplay, Pagination]}
          Pagination={true}
          slidesPerView={1}
          centeredSlides
          loop={media?.length > 1}
          speed={400}
          autoplay={{
            delay: 3000,
          }}
        >
          {Array.isArray(media) &&
            media.map((image) => (
              <SwiperSlide key={image.url} className="relative">
                <Image src={image?.url || placeholder} alt={title} />
                <ShadowDrop />
              </SwiperSlide>
            ))}
        </Swiper>
        <AddFav onClick={handleFav} isFav={isFavorites}>
          <Heart />
        </AddFav>
        <BackButton variant="square" onClick={handleClick}>
          <ArrowLeft />
        </BackButton>
        <Infos>
          <Type>{type}</Type>
          <Title level="h2" size="md">
            {title}
          </Title>
        </Infos>
      </Wrapper>
    );
  },
);

MobilePoi.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  handleClick: PropTypes.func,
};

export default MobilePoi;
