import tw, { styled } from 'twin.macro';

export const Wrapper = styled.div`
  ${tw`relative w-full flex justify-between items-center px-4 md:px-0`}
  &:after {
    content: '';
    ${tw`absolute right-9  left-9 -z-10 top-[32%] -translate-y-1/2 
         h-[1px] bg-[#CCCCCC]`}
  }
`;

export const Step = styled.div(({ isActive }) => [
  tw`flex flex-col justify-center items-center 
  text-[#CCCCCC] capitalize text-[14px] font-bold`,
  isActive && tw`text-[#85CEE3]`,
]);

export const StepCircle = styled.div(({ isActive }) => [
  tw`relative flex justify-center items-center w-[2.375rem] h-[2.375rem]
  bg-[#CCCCCC] text-white text-sm font-medium rounded-full`,
  isActive && tw`bg-[#85CEE3]`,
]);

export const Check = tw.span`
  absolute right-0 top-0 bg-green text-white w-4 h-4 rounded-full 
  text-center text-xs
`;
