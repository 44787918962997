// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import mapboxgl from 'mapbox-gl';
import MapMarker from './MapMarker';
import NavigationControls from 'components/NavigationControls';
import i18next from 'i18next';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Layer, Map, Marker, Source } from 'react-map-gl';
import usePlaces from 'store/usePlaces';
import {
  ICONS_TAGS,
  MAPBOX_SATELLITE_STYLE_URL,
  MAPBOX_STYLE_URL,
  MAPBOX_TOKEN,
  placesZoom,
} from 'utils/constants';
import useSupercluster from 'use-supercluster';
import { ClusterWrapper, TextWrapper } from './MapComponent.styles';
import { getRegionColor } from 'utils/helpers';
import placeholder from 'assets/placeholder.svg';
import { getBounds, getCenterOfBounds } from 'geolib';
import { useLocation, useNavigate } from 'react-router-dom';
import useTags from 'store/useTags';
import GeoMarker from 'components/GeoMarker';
import { useCurrentPosition } from 'hooks/useCurrentPosition';
import useDirections from 'store/useDirections';
import useSearchFilter from 'store/useSearchFilter';
import boundaries from 'data/my_provinces.json';
import morocco from 'data/morocco.geojson';
import { trackGoogleAnalyticsEvent } from 'utils/google-analytics';
import { regionCategory } from 'utils/google-analytics/events/regionExploration';
import ClusterPopup from 'components/ClusterPopup';
import useClusterPopup from 'store/useClusterPopup';
import { useTranslation } from 'react-i18next';
import ClusterIcon from 'Icons/ClusterIcon';
import mapImage from 'assets/mapImage.png';
import satelliteImage from 'assets/satelliteImage.png';
import useBrekpoint from 'hooks/useBrekpoint';

const dataLayer = {
  id: 'boundaries',
  type: 'fill',
  paint: {
    'fill-color': {
      property: 'rank',
      //
      stops: [
        [0, '#B5CD3A'],
        [1, '#F5B12F'],
        [2, '#239BAB'],
        [3, '#EB672C'],
        [4, '#4C7846'],
      ],
    },
    'fill-opacity': {
      stops: [
        [7, 0.4],
        [placesZoom, 0],
      ],
    },
  },
};

const borderLayer = {
  id: 'boundaries-border',
  type: 'line',
  paint: {
    'line-color': '#FFFFFF',
    'line-width': 4,
  },
};

const moroccoLayer = {
  id: 'morocco-border',
  type: 'line',
  paint: {
    'line-color': 'black',
    'line-opacity': 0.3,
    'line-width': 1,
  },
};

mapboxgl.workerClass = MapboxWorker;
function MapComponent() {
  const {
    filterByCity,
    setCitySelected,
    setPlaces,
    places,
    withoutCommercialPlaces,
    cities,
    cityIsSelected,
  } = usePlaces();
  const { t } = useTranslation();
  const { activeTags } = useTags();
  const { activeSearchFilters } = useSearchFilter();
  const { isDirections } = useDirections();
  const { isOpen, updatedOnce, openClusterPopup, closeClusterPopup } =
    useClusterPopup();
  const [position, error] = useCurrentPosition();
  const mapRef = useRef(null);
  const { isSmallDevice } = useBrekpoint();

  const [viewState, setViewState] = useState({
    latitude: 32.5,
    longitude: -6.172516742659279,
    zoom: 7.5,
  });

  const [mapStyle, setMapStyles] = useState(MAPBOX_STYLE_URL);
  const maxZoom = 20;
  const navigate = useNavigate();
  const location = useLocation();

  //zoom level based on device
  useEffect(() => {
    if (isSmallDevice) {
      setViewState((prev) => ({ ...prev, zoom: 6.9 }));
    } else {
      setViewState((prev) => ({ ...prev, zoom: 7.5 }));
    }
  }, [isSmallDevice]);

  //switch between sateleite
  const toggleStyle = () => {
    setMapStyles((prev) =>
      prev === MAPBOX_STYLE_URL ? MAPBOX_SATELLITE_STYLE_URL : MAPBOX_STYLE_URL,
    );
  };

  //get the center based on the bounderies
  const getRegionCenter = (region) => {
    const coords = boundaries.features.filter(
      (feature) => feature.properties.region === region,
    )[0]?.geometry?.coordinates[0];
    const center = getCenterOfBounds(coords);
    return center;
  };

  const isPlacesRestricted = useMemo(() => {
    const pagesToRestrict = [
      'program-result',
      'program',
      'shared',
      'similar-poi',
      'suggestion-previw',
    ];
    const page = location.pathname.split('/')[1];
    if (pagesToRestrict.includes(page)) return true;
    return false;
  }, [location.pathname]);

  const isIcon = useMemo(() => {
    if (isPlacesRestricted) {
      return true;
    } else if (activeTags?.length > 0 || activeSearchFilters.length > 0) {
      return true;
    } else {
      return cityIsSelected;
    }
  }, [isPlacesRestricted, cityIsSelected, activeTags, activeSearchFilters]);

  const getTags = (tags) => {
    const filteredTags = tags.filter((tag) => ICONS_TAGS.includes(tag?.slug));
    return filteredTags.length > 2 ? filteredTags.slice(0, 3) : filteredTags;
  };

  //retoate the map back
  const isPoi = location.pathname.split('/')[1] === 'poi';
  useEffect(() => {
    if (!isPlacesRestricted && !isPoi) {
      mapRef?.current?.rotateTo(0, { pitch: 0, duration: 1000 });
    }
  }, [isPlacesRestricted, isPoi]);

  //Animate the trasnition between places
  const animateTo = useCallback(({ longitude, latitude, zoom, duration }) => {
    mapRef.current?.flyTo({
      center: [longitude, latitude],
      duration,
      curve: 2,
      zoom,
    });
  }, []);

  const headTo = useCallback(
    (coordsArr) => {
      if (coordsArr?.length > 0) {
        const { minLat, maxLat, minLng, maxLng } = getBounds(coordsArr);
        mapRef?.current?.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          {
            padding: { top: 100, bottom: 100 },
            curve: 2,
            speed: 0.5,
          },
        );
      }
    },
    [mapRef],
  );

  // const filterOnZoomIn = (latitude, longitude) => {
  // const coordsArr = allPlaces.map((place) => ({
  //   latitude: place?.location.coordinates[1],
  //   longitude: place?.location.coordinates[0],
  // }));
  // const nearestToLocationCoords = findNearest(
  //   { latitude, longitude },
  //   coordsArr,
  // );
  // const nearest = allPlaces.find(
  //   (place) =>
  //     nearestToLocationCoords.latitude === place?.location.coordinates[1] &&
  //     nearestToLocationCoords.longitude === place?.location.coordinates[0],
  // );

  // const regionSlug = nearest?.contact?.address?.city;
  // filterByCity(regionSlug);
  // trackGoogleAnalyticsEvent(regionCategory, 'region_zoom', regionSlug);
  //   return regionSlug;
  // };

  //Naviagtion controls
  const zoomIn = () => {
    const { longitude, latitude, zoom } = viewState;
    animateTo({ longitude, latitude, zoom: zoom + 1, duration: 1000 });
  };

  const zoomOut = () => {
    const { longitude, latitude, zoom } = viewState;
    animateTo({ longitude, latitude, zoom: zoom - 1, duration: 1000 });
  };

  const handleUserLocation = () => {
    if (!error && position?.coords) {
      const { latitude, longitude } = position?.coords;
      animateTo({ latitude, longitude, zoom: 16, duration: 5000 });
    } else if (error) {
      alert(error);
    }
  };

  //Create GeoJson Feature objects
  const points = useMemo(
    () =>
      places?.map((place) => ({
        type: 'Feature',
        properties: {
          ...place,
          cluster: false,
        },
        geometry: {
          type: 'Point',
          coordinates: place?.location?.coordinates || [
            place?.coordinates?.longitude,
            place?.coordinates?.latitude,
          ],
        },
      })),
    [places],
  );

  const bounds = mapRef.current?.getMap().getBounds().toArray().flat();
  const { clusters, supercluster } = useSupercluster({
    points: points || [],
    bounds: bounds || null,
    zoom: viewState.zoom,
    options: {
      radius:
        isPlacesRestricted ||
        (!cityIsSelected &&
          activeSearchFilters?.length === 0 &&
          activeTags?.length === 0)
          ? 0
          : 120,
      maxZoom,
    },
  });

  //All the markers
  const pins = useMemo(
    () =>
      clusters?.map((cluster) => {
        const [longitude, latitude] = cluster.geometry.coordinates;
        const { cluster: isCluster, point_count } = cluster.properties;

        const goToPoi = (id) => {
          navigate('/poi/' + id);
        };

        return isCluster ? (
          <Marker
            key={`cluster-${cluster.id}`}
            latitude={latitude}
            longitude={longitude}
            onClick={(evt) => {
              evt.originalEvent.stopPropagation();
              const expansionZoom = Math.min(
                supercluster.getClusterExpansionZoom(cluster.id),
                maxZoom,
              );
              if (isOpen) closeClusterPopup();
              if (expansionZoom === maxZoom) {
                const children = supercluster.getChildren(cluster.id);
                const pois = children.map((child) => child.properties);
                navigate('/similar-poi', {
                  state: {
                    pois,
                  },
                });
              } else {
                animateTo({
                  longitude,
                  latitude,
                  duration: 2000,
                  zoom: expansionZoom,
                });
              }
            }}
          >
            <ClusterWrapper
              // width={`${50 + (point_count / points.length) * 35}px`}
              // height={`${50 + (point_count / points.length) * 35}px`}
              bgColor={getRegionColor(
                activeTags.length === 0 && activeSearchFilters.length === 0
                  ? cityIsSelected
                  : 'filter',
              )}
            >
              <ClusterIcon />
              <TextWrapper>{`${point_count} ${t('places')}`}</TextWrapper>
            </ClusterWrapper>
          </Marker>
        ) : (
          <Marker
            key={cluster.properties.id}
            longitude={
              cluster.properties?.slug
                ? getRegionCenter(cluster.properties?.slug).longitude
                : longitude
            }
            latitude={
              cluster.properties?.slug
                ? getRegionCenter(cluster.properties?.slug)?.latitude
                : latitude
            }
            onClick={(evt) => {
              evt.originalEvent.stopPropagation();
              if (!cityIsSelected && !isPlacesRestricted) {
                animateTo({
                  longitude,
                  latitude,
                  duration: 2000,
                  zoom: placesZoom,
                });
              }

              if (
                cityIsSelected ||
                activeTags.length > 0 ||
                activeSearchFilters.length > 0
              ) {
                goToPoi(cluster.properties.id);
              }

              if (cluster.properties?.slug) {
                setTimeout(() => {
                  filterByCity(cluster.properties?.slug);
                  setCitySelected(cluster.properties?.slug);
                }, [2000]);
              }
            }}
          >
            <MapMarker
              image={
                !isIcon
                  ? cluster.properties?.media[0]?.url || placeholder
                  : null
              }
              tags={
                isIcon
                  ? cluster.properties?.tags &&
                    getTags(cluster.properties?.tags)
                  : null
              }
              name={cluster.properties?.title[i18next.language]}
              slug={
                cluster.properties?.slug ||
                cluster.properties?.contact?.address?.city
              }
              order={cluster.properties?.number}
            />
          </Marker>
        );
      }),
    [
      isPlacesRestricted,
      isIcon,
      animateTo,
      navigate,
      supercluster,
      clusters,
      filterByCity,
      setCitySelected,
      cityIsSelected,
      activeTags,
      activeSearchFilters,
      isOpen,
      closeClusterPopup,
      t,
    ],
  );

  return (
    <Map
      id="main_map"
      ref={mapRef}
      mapboxAccessToken={MAPBOX_TOKEN}
      mapStyle={mapStyle}
      style={{ width: '100%', height: '100vh' }}
      interactiveLayerIds={['boundaries']}
      {...viewState}
      maxZoom={maxZoom}
      minZoom={4}
      onMove={(evt) => {
        setViewState(evt.viewState);
      }}
      onClick={(evt) => {
        const zoom = mapRef?.current?.getZoom();
        const coordinates = evt.features[0]?.geometry?.coordinates[0];
        const regionSlug = evt.features[0]?.properties?.region;
        if (zoom < placesZoom && coordinates && !isDirections) {
          headTo(coordinates);
          filterByCity(regionSlug);
          setCitySelected(regionSlug);
          trackGoogleAnalyticsEvent(regionCategory, 'region_click', regionSlug);

          if (!updatedOnce) openClusterPopup();
        }

        // const regionSlug = evt.features[0]?.properties?.region;
        // const { longitude, latitude } = cities.find(
        //   (city) => city.slug === regionSlug,
        // )?.coordinates;
        // if (longitude && latitude) {
        //   animateTo({
        //     longitude,
        //     latitude,
        //     duration: 2000,
        //     zoom: 8.6,
        //   });
        // }
      }}
      onZoom={(evt) => {
        let oldZoom = mapRef?.current?.getZoom();
        const { zoom } = evt.viewState;

        if (
          activeTags.length === 0 &&
          activeSearchFilters.length === 0 &&
          !isPlacesRestricted &&
          !isDirections
        ) {
          if (zoom > oldZoom && zoom > placesZoom && !cityIsSelected) {
            // const city = filterOnZoomIn(latitude, longitude);
            setPlaces(withoutCommercialPlaces);
            setCitySelected('all');
            if (!updatedOnce) openClusterPopup();
          } else if (zoom < oldZoom && zoom <= 7.5 && cityIsSelected) {
            setCitySelected(false);
            setPlaces(cities);
          }
        }
      }}
    >
      {!isDirections && (
        <Source type="geojson" data={boundaries}>
          <Layer {...dataLayer} />
          <Layer {...borderLayer} />
        </Source>
      )}
      <Source type="geojson" data={morocco}>
        <Layer {...moroccoLayer} />
      </Source>
      <NavigationControls
        handleUserLocation={handleUserLocation}
        zoomIn={zoomIn}
        zoomOut={zoomOut}
        toggleStyle={toggleStyle}
        text={mapStyle === MAPBOX_STYLE_URL ? 'Satellite' : 'map'}
        image={mapStyle === MAPBOX_STYLE_URL ? satelliteImage : mapImage}
      />
      {isOpen && <ClusterPopup handleClose={closeClusterPopup} />}
      {pins}
      {position?.coords && (
        <Marker
          latitude={position.coords.latitude}
          longitude={position.coords.longitude}
          onClick={handleUserLocation}
        >
          <GeoMarker />
        </Marker>
      )}
    </Map>
  );
}

export default MapComponent;
