import Button from 'components/Button';
import tw, { styled } from 'twin.macro';

export const Wrapper = styled.div`
  ${tw`hidden md:inline-block relative w-[32.5rem] h-[25.2rem]  rounded-[1.875rem] `}
`;

export const Image = tw.img`
  w-[32.5rem] h-[25.2rem] object-cover rounded-[1.875rem] isolate
`;

export const ShadowDrop = styled.div`
  ${tw`absolute bottom-0 w-full h-44 rounded-b-[1.875rem] opacity-60 z-20`};
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 42.19%);
`;

export const AddFav = styled.button(({ isFav }) => [
  tw`absolute z-20 right-6 top-6 flex justify-center items-center
  text-white bg-[#525252] w-10 h-10 rounded-full cursor-pointer `,
  isFav && tw`text-white bg-pink`,
]);

export const BackButton = styled(Button)`
  ${tw`absolute top-6 left-6 bg-[rgba(255, 255, 255, 0.77)] z-10`}
`;

export const Infos = tw.div`
  absolute left-6 bottom-6 flex flex-col justify-start gap-2 
  w-[60%] text-white z-30
`;

export const Type = tw.span`
   capitalize text-orange text-lg 
`;
