import MobilePoi from 'components/MobilePoi';
import PoiCard from 'components/PoiCard';
import { orderByDistance } from 'geolib';
import i18next from 'i18next';
import AddressIcon from 'Icons/AddressIcon';
import Label from 'Icons/Label';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import useFavorites from 'store/useFavorites';
import usePlaces from 'store/usePlaces';
import {
  Address,
  Content,
  Infos,
  Price,
  Wrapper,
  CloseBtn,
  Extras,
  Duration,
  Tags,
  Tag,
  IconWrapper,
} from './InterestPoint.styles';
import { useMap } from 'react-map-gl';
import SideCloseBtn from 'components/SideCloseBtn';
import ChevronDown from 'Icons/ChevronDown';
import { getArrayCoords, getIcon } from 'utils/helpers';
import useBrekpoint from 'hooks/useBrekpoint';
import InfoSection from './InfoSection';
import { useTranslation } from 'react-i18next';
import DirectionsIcon from 'Icons/DIrectionsIcon';
import Directions from './Directions';
import Button from 'components/Button';
import useDirections from 'store/useDirections';
import useTags from 'store/useTags';
import useSearchFilter from 'store/useSearchFilter';

function InterestPoint() {
  const { main_map } = useMap();
  const {
    places,
    allPlaces,
    withoutCommercialPlaces,
    filterByCity,
    setCitySelected,
    setPlaces,
  } = usePlaces();
  const { activeSearchFilters } = useSearchFilter();
  const { activeTags } = useTags();
  const { toggleFavorites, isFavorites } = useFavorites();
  const { isSmallDevice } = useBrekpoint();
  const { isDirections, toggleIsDirection } = useDirections();
  const navigate = useNavigate();
  const location = useLocation();

  const animateTo = useCallback(
    ({ longitude, latitude }) => {
      main_map?.flyTo({
        pitch: 0,
        bearing: 0,
        center: [longitude, latitude],
        curve: 1.4,
        duration: 5000,
        zoom: 16,
        padding: !isSmallDevice && { left: 250 },
      });
    },
    [main_map, isSmallDevice],
  );

  const { poi, nearby } = useMemo(() => {
    const poi = allPlaces.find(
      (place) => location.pathname.split('/')[2] === place.id,
    );

    if (poi && activeTags.length === 0 && activeSearchFilters.length === 0) {
      setCitySelected(poi.contact?.address?.city);
      filterByCity(poi.contact?.address?.city);
    }
    const filteredBycity = withoutCommercialPlaces?.filter(
      (place) => poi?.contact?.address?.city === place.contact?.address?.city,
    );

    const nearbyCords = orderByDistance(
      {
        latitude: poi?.location.coordinates[1],
        longitude: poi?.location.coordinates[0],
      },
      getArrayCoords(filteredBycity),
    )
      .filter(
        //Filtering the duplicates
        (coords, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              coords.latitude === t.latitude &&
              coords.longitude === t.longitude,
          ),
      )
      .slice(1, 6);

    const nearby = nearbyCords.map((cords) =>
      filteredBycity.find(
        (place) =>
          cords.latitude === place?.location.coordinates[1] &&
          cords.longitude === place?.location.coordinates[0],
      ),
    );

    return { poi, nearby };
  }, [
    location.pathname,
    allPlaces,
    withoutCommercialPlaces,
    filterByCity,
    setCitySelected,
    activeSearchFilters,
    activeTags,
  ]);

  //scroll to the top and animate to the poi
  const wrapperRef = useRef(null);
  useEffect(() => {
    if (poi && !isDirections) {
      const poiIndex = places.findIndex((place) => place.id === poi.id);
      //add the poi if it does not exist on the map
      if (poiIndex === -1) {
        setPlaces([...places, poi]);
      }
      wrapperRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
      const coords = poi?.location.coordinates;
      animateTo({ longitude: coords[0], latitude: coords[1] });
    }
  }, [animateTo, poi, nearby, setPlaces, places, isDirections]);

  const handleGoBack = () => {
    if (isDirections) {
      toggleIsDirection(false);
    } else {
      navigate(-1);
    }
  };

  const pageRef = useRef(null);
  const mobileRef = useRef(null);
  const handleClose = () => {
    pageRef?.current?.classList.add('animate__fadeOutDown');
    mobileRef?.current?.classList.add('animate__fadeOut');

    setTimeout(() => {
      toggleIsDirection(false);
      navigate('/');
    }, 600);
  };
  const { t } = useTranslation();
  console.log(poi);
  return poi ? (
    <>
      {!isDirections && (
        <MobilePoi
          ref={mobileRef}
          title={poi?.title[i18next.language]}
          media={poi?.media}
          type={poi?.tags[0][i18next.language]}
          handleClick={handleGoBack}
          handleFav={() => toggleFavorites(poi)}
          isFavorites={isFavorites(poi?.id)}
          className="animate__animated animate__fadeIn"
        />
      )}
      <Wrapper
        ref={pageRef}
        className={`animate__animated ${
          isSmallDevice ? 'animate__fadeInUp' : 'animate__fadeInLeft'
        } `}
      >
        <SideCloseBtn page={pageRef} />
        <CloseBtn onClick={handleClose}>
          <ChevronDown />
        </CloseBtn>
        <Content ref={wrapperRef}>
          <PoiCard
            isDirections={isDirections}
            title={poi.title[i18next.language]}
            media={poi.media}
            type={poi.tags[0][i18next.language]}
            handleClick={handleGoBack}
            handleFav={() => toggleFavorites(poi)}
            isFavorites={isFavorites(poi.id)}
          />
          <Infos>
            {poi.tags && (
              <Tags>
                {Array.isArray(poi.tags) &&
                  poi.tags.slice(0, 4).map((tag) => (
                    <Tag key={tag.slug}>
                      <IconWrapper>{getIcon(tag.slug)}</IconWrapper>
                      {tag.name[i18next.language]}
                    </Tag>
                  ))}
              </Tags>
            )}
            <Address>
              <span>
                <AddressIcon />
              </span>
              {`${
                poi?.contact?.address?.street_address
                  ? poi?.contact?.address?.street_address
                  : ''
              } ${poi?.contact?.address?.city}`}
            </Address>
            <Extras>
              {poi?.misc?.cost && poi?.misc?.cost !== 0 && (
                <Price>
                  <span>
                    <Label />
                  </span>
                  {poi?.misc?.cost} DH
                </Price>
              )}
              {poi?.misc?.minVisitDuration && (
                <Duration>
                  {t('averageDuration')}: {poi?.misc?.minVisitDuration} Min
                </Duration>
              )}
            </Extras>
            <Button
              disabled={isDirections}
              variant={'primary'}
              width="100%"
              size="small"
              onClick={toggleIsDirection}
            >
              <DirectionsIcon />
              {t('directions')}
            </Button>
          </Infos>
          {isDirections ? (
            <Directions
              poi={poi}
              setPlaces={setPlaces}
              toggleIsDirection={toggleIsDirection}
              isDirections={isDirections}
            />
          ) : (
            <InfoSection poi={poi} nearby={nearby} />
          )}
        </Content>
      </Wrapper>
    </>
  ) : (
    <Navigate to="/*" replace={true} />
  );
}

export default InterestPoint;
