import i18next from 'i18next';
import Checkbox from '../Checkbox';
import { CheckboxListWrapper } from './CheckboxList.styles';

function CheckboxList({ items, checkedResults, handleChange, name, isResult }) {
  const isChecked = (slug) => {
    if (name === 'destinations') {
      return checkedResults.some((checked) => checked.slug === slug);
    } else {
      return checkedResults.includes(slug);
    }
  };

  return (
    <CheckboxListWrapper>
      {items?.length > 0 &&
        items.map((item) => (
          <Checkbox
            isResult={isResult}
            key={item.slug}
            label={item.name[i18next.language]}
            isChecked={isChecked(item.slug)}
            onChange={() => handleChange(item.slug, item.name)}
          />
        ))}
    </CheckboxListWrapper>
  );
}

export default CheckboxList;
