const BookMark = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0344 0H1.33118C0.622368 0 0 0.583107 0 1.27331V11.1479C0 11.379 0.0643024 11.5715 0.167936 11.7203C0.291872 11.8981 0.491429 12.0001 0.705416 12C0.907732 12 1.12313 11.9099 1.32205 11.74L5.21585 8.43401C5.33611 8.3313 5.50885 8.27244 5.68846 8.27244C5.868 8.27244 6.04039 8.3313 6.161 8.43429L10.0418 11.7395C10.2414 11.9099 10.442 12.0001 10.6439 12.0001C10.9855 12.0001 11.3184 11.7366 11.3184 11.148V1.27331C11.3184 0.583107 10.7432 0 10.0344 0Z"
        fill="#EB672C"
      />
    </svg>
  );
};

export default BookMark;
