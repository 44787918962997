import tw, { styled } from 'twin.macro';

export const ProgramSaveWrapper = styled.button(({ isAtTop }) => [
  tw`self-start bg-[#EB672C] flex items-center justify-start gap-[0.625rem]
   py-6 pr-[1.192rem] pl-1 md:pl-2 rounded-r-[4.063rem] capitalize z-50 whitespace-nowrap
  text-white text-base font-bold leading-[1.21rem] -tracking-[.03em]
  max-w-[18rem] h-[2.875rem]  transition-all duration-[1s] overflow-hidden
`,
  !isAtTop && tw`relative right-4 md:right-10`,
  isAtTop &&
    tw`fixed left-0 py-3 z-20 max-w-[2.25rem] md:max-w-[2.75rem] hover:w-[12rem] `,
]);

export const TagWrapper = tw.div`
w-6 h-6 md:(w-[1.875rem] h-[1.875rem]) rounded-full bg-white 
flex items-center justify-center shrink-0
`;
