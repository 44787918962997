import tw, { styled } from 'twin.macro';

export const StartPointWrapper = styled.div(({ isAtTop }) => [
  tw` w-full rounded-[1.75rem] p-[1.5rem] gap-4 flex flex-col items-center [&>*:nth-child(1)]:self-start
  `,

  isAtTop && tw`mb-20`,
  `border: 1px solid rgba(0, 0, 0, 0.2);`,
]);

export const SearchWrapper = tw.div`
  w-full relative 
`;

export const Devider = styled.span`
  ${tw`w-full flex justify-between items-center text-2xl font-bold tracking-[-0.063rem] leading-7 capitalize`}
  &:after,  &:before {
    ${tw`[content: ''] w-[38%] h-[1px] bg-[#00000020]`}
  }
`;

export const Time = tw.div`
 w-full flex flex-col gap-2 justify-center items-center
`;
export const LabelDescription = tw.label`
 text-sm font-semibold leading-[1.059rem] capitalize text-[#00000070] 
`;

export const TimeLabel = tw.div`
py-[1rem] px-[2.813rem] rounded-[0.688rem] bg-[#F3F3F3]
`;

export const SearchBox = tw.div`
   w-full absolute z-50 top-[4.5rem] left-1/2 -translate-x-1/2  bg-white py-6
   flex flex-col justify-start items-start border-[1px] border-[rgba(33, 35, 36, 0.2)] 
   max-h-[16.6rem] overflow-y-hidden
   [box-shadow: 0px 16px 18px rgba(0, 0, 0, 0.11)] rounded-[1.875rem]
`;

export const BoxContent = tw.div`
  max-h-full overflow-auto flex flex-col justify-start items-start gap-9
  pl-8 pr-10 
`;

export const SearchItem = tw.button`
  flex justify-center items-center gap-7 cursor-pointer
  outline-0 text-start
`;

export const MarkerWrapper = tw.span`
  shrink-0 
`;
