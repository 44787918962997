import tw, { styled } from 'twin.macro';

export const Wrapper = styled.div(({ hasScroll }) => [
  tw`w-full flex flex-col justify-center gap-4
  bg-white rounded-2xl px-2.5 py-3 md:p-4  text-[#212324]`,
  hasScroll && tw`md:pr-0`,
]);

export const Days = styled.ul`
  ${tw`w-full  flex flex-col justify-start gap-2 
       max-h-[8rem] overflow-auto`}

  ::-webkit-scrollbar {
    ${tw`w-0 md:w-[1.375rem]`}
  }
  ::-webkit-scrollbar-thumb {
    border: 9px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: rgba(181, 205, 58, 1);
  }
`;
