import tw, { styled } from 'twin.macro';

export const StepAddWrapper = tw.div`
 w-full flex items-center justify-between gap-0.5 px-0.5 isolate
`;

export const StepAddButton = styled.button`
  ${tw`-z-10 relative bg-[#B5CD3A] flex items-center justify-center gap-[0.625rem]
  py-1 pr-4 pl-1 rounded-[4.063rem] capitalize whitespace-nowrap
  text-white text-base font-bold leading-[1.21rem] -tracking-[.03em]`}
  &:after {
    ${({ $isFirst }) => !$isFirst && tw`[content: '']`}
    ${tw`absolute w-[0.188rem] h-[0.5rem] left-[1.563rem] -top-[0.813rem] bg-[#B5CD3A]`}
    ${({ $isLast }) => $isLast && tw`h-[0.4rem] -top-[0.65rem]`}
    border-radius: 0px 0px 1.5px 1.5px;
  }
  &:before {
    ${tw`absolute w-[0.188rem] h-[0.5rem] left-[1.563rem] -bottom-[0.813rem] bg-[#B5CD3A]`}
    ${({ $isLast }) => !$isLast && tw`[content: '']`}
    border-radius: 1.5px 1.5px 0px 0px;
  }
`;

export const TagWrapper = tw.div`
w-[1.875rem] h-[1.875rem] rounded-full bg-white 
flex items-center justify-center 
`;

export const DistanceTag = tw.div`
text-base font-medium leading-[1.21rem]
flex items-center justify-center gap-1 bg-[#D9D9D920] px-[0.563rem] py-[0.25rem] rounded-[0.688rem]
`;

export const IconWrapper = tw.div`
flex items-center justify-center
w-[2.188rem] h-[2.188rem] rounded-full bg-white 
`;
export const InfosWrapper = tw.button`
 relative
`;
export const InfoPopup = tw.div`
   absolute bottom-full right-full w-48 h-[4.5rem] px-4 py-2 capitalize z-50
   bg-white rounded-[4px] text-sm text-start [box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.25)]
`;
