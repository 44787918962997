import Title from 'components/Typography/Title';
import Heart from 'Icons/Heart';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import placeholder from 'assets/placeholder.svg';

import {
  NearbyCardWrapper,
  NearbyImage,
  Distance,
  InfoWrapper,
  AddFav,
} from './NearbyCard.styles';
import i18next from 'i18next';
import { getDistanceWithUnit } from 'utils/helpers';

function NearbyCard({
  image,
  title,
  id,
  distance,
  poiTitle,
  handleClick,
  handleFav,
  isFavorites,
}) {
  const { t } = useTranslation();

  return (
    <NearbyCardWrapper>
      <AddFav onClick={handleFav} isFav={isFavorites}>
        <Heart />
      </AddFav>
      <Link to={`/poi/${id}`}>
        <NearbyImage onClick={handleClick} src={image ? image : placeholder} />
        <InfoWrapper>
          {title && (
            <Title level="h3" size="lg">
              {title[i18next.language]}
            </Title>
          )}
          {distance && poiTitle && (
            <Distance>{`${getDistanceWithUnit(distance)} ${t(
              'from',
            )} ${poiTitle}`}</Distance>
          )}
        </InfoWrapper>
      </Link>
    </NearbyCardWrapper>
  );
}

NearbyCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.shape({
    en: PropTypes.string,
    fr: PropTypes.string,
  }),
  tag: PropTypes.string,
};

export default NearbyCard;
