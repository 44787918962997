import React from 'react';
import { useTranslation } from 'react-i18next';
import { FilterTag, FilterTagsWrapper } from './FilterTags.styles';

function FilterTags({ filters, active, setActive }) {
  const { t } = useTranslation();

  return (
    <FilterTagsWrapper>
      {filters.length > 0 &&
        filters.map((filter) => {
          return (
            <FilterTag
              key={filter}
              isSelected={active === filter}
              onClick={() => setActive(filter)}
            >
              {t(filter)}
            </FilterTag>
          );
        })}
    </FilterTagsWrapper>
  );
}

export default FilterTags;
