import tw, { styled } from 'twin.macro';

export const Wrapper = tw.div`
 w-full flex justify-center items-start gap-4
 flex-col-reverse md:flex-col
`;

export const TravelModes = tw.div`
   flex justify-start items-center gap-2
   md:gap-3
`;

export const Mode = styled.button(({ isActive }) => [
  tw`flex justify-center items-center gap-1 px-2  py-3 rounded-2xl
      bg-[#D9D9D9] text-sm font-bold bg-opacity-20
      md:text-base md:px-5`,
  isActive && tw`bg-[#FFC107] bg-opacity-20`,
]);

export const ModeInfos = tw.div`
  self-stretch flex flex-col justify-between items-start
`;

export const Distance = tw.span`
  font-normal text-sm 
`;

export const IconWrapper = tw.span`
 flex justify-center items-center text-center
 w-11 h-11 bg-white rounded-lg
`;
