const Fb = () => {
  return (
    <svg
      width="9"
      height="16"
      viewBox="0 0 9 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.58464 9.18751H7.5638L8.35547 6.02084H5.58464V4.43751C5.58464 3.62209 5.58464 2.85418 7.16797 2.85418H8.35547V0.194177C8.09739 0.160135 7.12284 0.0833435 6.09368 0.0833435C3.9443 0.0833435 2.41797 1.39513 2.41797 3.80418V6.02084H0.0429688V9.18751H2.41797V15.9167H5.58464V9.18751Z"
        fill="white"
      />
    </svg>
  );
};

export default Fb;
