import tw from 'twin.macro';

export const ChildWrapper = tw.div`
 w-full bg-[#F7F5F2] rounded-[1rem] flex flex-col justify-center gap-8 p-4
 md:p-6 md:mb-[6rem]
`;

export const ButtonWrapper = tw.span`
  absolute bottom-4 left-0 w-full flex justify-between items-center
  bg-white pt-4 px-8
  md:(left-10 right-10 px-0 w-[unset] bottom-10)
`;
