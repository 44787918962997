const DirectionsIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="11"
        y="0.083252"
        width="15.4386"
        height="15.4386"
        transform="rotate(45 11 0.083252)"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4547 7.04816L14.9157 10.5285C15.0148 10.628 15.0692 10.7611 15.0689 10.903C15.0693 11.0444 15.0148 11.1774 14.9158 11.2771L11.4548 14.7573C11.356 14.8566 11.2243 14.9115 11.0839 14.9115C10.9433 14.9115 10.8117 14.8573 10.7129 14.758L10.3987 14.443C10.3 14.3437 10.2456 14.2129 10.2456 14.0718C10.2456 13.9306 10.3 13.8017 10.3987 13.7023L12.4391 11.66H8.93902V14.1169C8.93902 14.5557 8.58325 14.9115 8.14439 14.9115C7.70552 14.9115 7.34975 14.5557 7.34975 14.1169V11.1311C7.34966 11.1269 7.34961 11.1227 7.34961 11.1185L7.34975 11.077V10.9383C7.34975 10.928 7.34995 10.9177 7.35034 10.9075L7.35117 10.6678C7.35117 10.3769 7.58164 10.1518 7.87087 10.1518H8.03008C8.06741 10.1465 8.10557 10.1437 8.14439 10.1437C8.1832 10.1437 8.22137 10.1465 8.25869 10.1518H12.4197L10.3986 8.11448C10.2999 8.01529 10.2455 7.87834 10.2455 7.73715C10.2455 7.59589 10.2999 7.46341 10.3986 7.36422L10.7129 7.04816C10.8117 6.9489 10.9433 6.89429 11.0838 6.89429C11.2242 6.89429 11.3559 6.94882 11.4547 7.04816Z"
        fill="#653915"
      />
    </svg>
  );
};

export default DirectionsIcon;
