import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Check, Step, StepCircle, Wrapper } from './Steps.styles';

function Steps({ activeStep, steps }) {
  const { t } = useTranslation();
  return (
    <Wrapper>
      {steps.map((step, index) => (
        <Step key={step.name} isActive={index + 1 === activeStep}>
          <StepCircle isActive={index + 1 === activeStep}>
            {index + 1}
            {step.isCompleted && <Check>✓</Check>}
          </StepCircle>
          {t(step.name)}
        </Step>
      ))}
    </Wrapper>
  );
}

Steps.propTypes = {
  activeStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      isCompleted: PropTypes.bool.isRequired,
    }),
  ),
};

export default Steps;
