import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const usePlaces = create(
  persist(
    (set) => ({
      isSearching: false,
      cityIsSelected: false,
      allPlaces: [],
      places: [],
      withoutCommercialPlaces: [],
      popPlaces: [],
      seePlaces: [],
      cities: [],
      setAllPlaces: (places) => set({ allPlaces: places }),
      setPlaces: (places) => set({ places }),
      setPopPlaces: (places) => set({ popPlaces: places }),
      setWithoutCommercialPlaces: (places) =>
        set({ withoutCommercialPlaces: places }),
      setSeePlaces: (places) => set({ seePlaces: places }),
      setCities: (cities) => set({ cities }),
      filterByCity: (city) => {
        set((state) => ({
          places: state.seePlaces.filter(
            (place) => city === place.contact.address?.city,
          ),
        }));
      },
      setIsSearching: (value) => set({ isSearching: value }),
      setCitySelected: (value) => set({ cityIsSelected: value }),
    }),
    {
      name: 'places',
      partialize: (state) => {
        const { cityIsSelected, ...rest } = state;
        return { ...rest };
      },
    },
  ),
);

export default usePlaces;
