import tw, { styled } from 'twin.macro';

export const StepCardWrapper = styled.div`
  ${tw`relative w-full h-80 rounded-[1.75rem]`}
  &:after {
    content: '';
    ${tw`absolute bottom-0 w-full h-[7.3rem] rounded-b-[1.75rem]`};
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    opacity: 0.6;
  }
`;

export const StepImage = tw.img`
  w-full h-full object-cover rounded-[1.75rem]
`;

export const AddStep = tw.button`absolute z-20 right-6 top-6 flex justify-center items-center
  text-white bg-orange w-10 h-10 rounded-full cursor-pointer `;

export const InfoWrapper = tw.div`
   flex flex-col justify-start gap-1 capitalize
   text-white w-[80%] absolute bottom-6 left-6 z-20
`;

export const Distance = tw.p`
  text-[#85CEE3] text-sm font-medium
`;
