import tw, { styled } from 'twin.macro';

export const MarkerWrapper = tw.div`
  flex items-center justify-center gap-3.5
`;

export const Marker = styled.div`
  ${tw`relative w-11 h-11 rounded-full flex items-center justify-center gap-1`};
  ${({ isImage }) => isImage && tw`w-[4.625rem] h-[4.625rem]`}
  ${({ $isLarge }) => $isLarge && tw`w-[5.313rem]`}
  background-color: ${({ color }) => color};
  box-shadow: ${({ color }) => `0px 4px 10px ${color}`};
`;

export const IconWrapper = tw.span`
  absolute top-[calc(100% - 2px )] left-1/2 -translate-x-1/2
`;

export const StyledImg = tw.img`
  w-16 h-16 rounded-full border-2 border-white object-cover
`;

export const TagIcon = styled.span(({ $isLarge }) => [
  tw`w-4 h-4 rounded-full flex justify-center items-center shrink-0
  text-white text-xl`,
  $isLarge && tw``,
]);

export const NameWrapper = styled.p`
  ${tw`absolute left-[calc(100% + 0.75rem)] w-max top-1/2 -translate-y-1/2  max-w-[12.625rem] rounded-[2.625rem] px-4 py-2 font-normal
        text-sm font-primary  text-ellipsis overflow-hidden  border-2  bg-white 
  `};
  ${({ hasTag }) => !hasTag && tw`-translate-x-1/2 `}
  ${({ slug, hasTag }) =>
    !hasTag &&
    slug === 'fquih-ben-saleh' &&
    tw`-translate-x-[54%] -translate-y-[80%]`}
  ${({ slug, hasTag }) =>
    !hasTag && slug === 'beni-mellal' && tw`-translate-y-[94%]`}
  border-color: ${({ color }) => color};
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  max-height: 3.5rem;
`;
