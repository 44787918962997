const Craft = () => {
  return (
    <svg
      width="33"
      height="35"
      viewBox="0 0 33 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.6419 1.0543C27.5353 0.814323 27.2953 0.654297 27.0353 0.654297H18.4486C18.1886 0.654297 17.9486 0.814323 17.842 1.0543C17.7353 1.29427 17.782 1.57436 17.9553 1.77436C19.1286 3.0611 19.4486 4.59443 18.9219 6.41436H26.5619C26.0352 4.58766 26.3619 3.06103 27.5286 1.77436C27.702 1.57436 27.7486 1.29443 27.642 1.0543H27.6419Z"
        fill="currentColor"
      />
      <path
        d="M19.0939 19.9072C19.614 20.3939 20.2806 20.6538 20.9672 20.6538H24.5205C25.1872 20.6538 25.8205 20.4205 26.2872 20.0004C29.7872 16.8538 30.1339 12.9137 27.3539 8.31374C27.2539 8.12038 27.1605 7.9338 27.0739 7.74707L18.4139 7.7472C18.334 7.92051 18.2406 8.1006 18.1472 8.28053C15.3739 12.8672 15.6939 16.7805 19.0939 19.9072Z"
        fill="currentColor"
      />
      <path
        d="M28.6485 22.5348L23.8352 26.7881C22.9219 27.5881 21.7552 28.0281 20.5418 28.0281H17.3619L19.8819 26.6347C20.4552 26.3147 20.8619 25.7881 21.0353 25.1548C21.202 24.5215 21.1087 23.8614 20.762 23.2948C20.1353 22.2548 18.8154 21.8614 17.6887 22.3815C16.202 23.0682 14.6353 23.4749 13.0354 23.5948C12.0621 23.6681 11.1354 24.0548 10.1288 24.8148C8.3888 26.1148 6.15544 27.4349 5.55544 27.7815L1.3151 27.7814C0.941804 27.7814 0.648438 28.0747 0.648438 28.448V34.028C0.648438 34.3947 0.941798 34.6947 1.3151 34.6947H20.6151C22.4085 34.6947 24.1618 33.9614 25.4284 32.6881L31.9951 26.0881C32.4684 25.6148 32.7218 24.9814 32.7017 24.3081C32.6885 23.6415 32.4084 23.0214 31.915 22.5682C30.9952 21.7214 29.5884 21.7081 28.6485 22.5348L28.6485 22.5348Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Craft;
