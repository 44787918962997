import ReactGA from 'react-ga4';
const initializeGoogleAnalytics = () => {
  // Initialize GA4 - Add your measurement ID
  const isDev = process.env.NODE_ENV === 'development';

  ReactGA.initialize('G-YK0DKJ3E99', {
    legacyDimensionMetric: false,
    testMode: isDev,
    gtagOptions: {
      send_page_view: true,
    },
  });
};

const trackGoogleAnalyticsEvent = (category, action, label, value) => {
  // Send GA4 Event
  ReactGA.event({
    category,
    action,
    label,
    value,
  });
};
const trackPageView = (path) => {
  ReactGA.send({
    hitType: 'pageview',
    page: path,
  });
};

export { initializeGoogleAnalytics, trackGoogleAnalyticsEvent, trackPageView };
