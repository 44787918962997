import Button from 'components/Button';

import ChevronDown from 'Icons/ChevronDown';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PopPlacesSwiper from './PopPlacesSwiper';
import { Header, Wrapper, Title } from './PopularPlaces.styles';

function PopularPlaces({ places, selectedCity, filterExist, isDirections }) {
  const popularPlacesRef = useRef();
  const [isOpen, setIsOpen] = useState();
  const { t } = useTranslation();
  const filterPopPlacesBycity = () => {
    return selectedCity === 'all'
      ? places
      : places.filter(
          (place) => place?.contact?.address?.city === selectedCity,
        );
  };
  const toggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    let popularPlacesWrapper = popularPlacesRef.current;
    const handleClick = (e) => {
      if (popularPlacesWrapper && popularPlacesWrapper !== e.target) {
        if (!popularPlacesWrapper.contains(e.target)) {
          setIsOpen(false);
        }
      }
    };
    document.addEventListener('click', (e) => handleClick(e));

    return () => {
      document.removeEventListener('click', (e) => handleClick(e));
    };
  }, []);

  return (
    <Wrapper
      ref={popularPlacesRef}
      isOpen={isOpen}
      filterExist={filterExist}
      isDirections={isDirections}
    >
      <Header>
        <Title>{t('popularPlaces')}</Title>
        <Button
          variant="roundedBlue"
          onClick={toggle}
          className={isOpen && 'rotate-180'}
        >
          <ChevronDown />
        </Button>
      </Header>
      {isOpen && (
        <PopPlacesSwiper
          places={selectedCity ? filterPopPlacesBycity() : places}
          setIsOpen={setIsOpen}
        />
      )}
    </Wrapper>
  );
}

export default PopularPlaces;
