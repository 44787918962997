const Marker = ({ color }) => {
  return (
    <svg
      width="9"
      height="11"
      viewBox="0 0 9 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.04737 9.69248C4.8654 9.82926 4.71336 9.93618 4.60615 10.0093C4.59219 10.0188 4.57805 10.0287 4.56379 10.0388C4.46923 10.1052 4.36728 10.1768 4.24998 10.1768C4.13314 10.1768 4.03002 10.1045 3.93502 10.0379C3.92113 10.0282 3.90742 10.0185 3.89381 10.0093C3.78661 9.93618 3.63457 9.82926 3.45259 9.69248C3.08922 9.41923 2.60364 9.02457 2.11673 8.53932C1.15964 7.58543 0.125 6.21043 0.125 4.67676C0.125 2.39857 1.97181 0.551758 4.25 0.551758C6.52819 0.551758 8.375 2.39857 8.375 4.67676C8.375 6.21043 7.34045 7.58543 6.38327 8.53932C5.89632 9.02458 5.41077 9.41924 5.04741 9.69248L5.04737 9.69248ZM2.53122 4.67675C2.53122 3.72754 3.30075 2.958 4.24997 2.958C5.19918 2.958 5.96872 3.72753 5.96872 4.67675C5.96872 5.62597 5.19918 6.3955 4.24997 6.3955C3.30075 6.3955 2.53122 5.62597 2.53122 4.67675Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  );
};

export default Marker;
