import tw from 'twin.macro';
export const Wrapper = tw.section`
fixed z-50 left-0 top-96 w-full bottom-0 pt-10 bg-white  rounded-t-[1.25rem] 
md:top-0 md:max-w-[37.5rem]  md:rounded-t-[2.5rem] md:rounded-tl-none
md:[box-shadow: 0px 0px 30px 15px rgba(0, 0, 0, 0.02)]

`;

export const Content = tw.div`
  w-full h-full flex flex-col gap-8  items-start 
  overflow-y-auto overflow-x-hidden px-4 pb-6 md:pb-10 md:px-10 md:pr-6
`;

export const CloseBtn = tw.button`
  absolute top-4 block md:hidden text-black left-1/2 -translate-x-1/2
`;

export const Infos = tw.div`
 w-full flex flex-col justify-start items-start gap-4 
`;

export const Tags = tw.div`
  flex items-center gap-2
`;

export const Tag = tw.div`
  flex justify-center items-center gap-1 py-2 px-4 bg-[#E6E6E6]
  rounded-full text-black text-base tracking-[-1px] capitalize font-medium
`;

export const IconWrapper = tw.span`
 shrink-0 w-4 h-4 flex justify-center items-center
`;

export const Address = tw.p`
   w-full flex justify-start items-center gap-2.5
   text-lg font-light text-[#B0B0B0] capitalize text-start
`;

export const Price = tw.p`
w-full flex justify-start items-center gap-2
text-orange text-lg font-bold uppercase
`;

export const SeeMore = tw.button`
  underline font-bold capitalize
`;

export const Extras = tw.div`
 flex items-center gap-4
`;

export const Duration = tw.p`
  text-lg text-orange font-bold
`;
