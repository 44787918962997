import tw, { styled } from 'twin.macro';

export const Wrapper = tw.section`
 w-full flex flex-col  gap-6 md:gap-8
`;

export const Box = tw.div`
    w-full flex flex-col justify-center items-start
    gap-5 md:gap-6 p-5 md:p-10 bg-[#F2F1EF] rounded-[1.75rem]
`;

export const Header = tw.div`
  flex gap-5 items-center
`;

export const Hours = tw.ul`
  w-full flex flex-col justify-center items-center gap-2
`;

export const Hour = styled.li`
  ${tw`w-full flex justify-between items-center pb-2
       border-b-[1px] border-b-black text-lg last:pb-0 last:border-0`}
  span {
    ${tw`font-bold`}
  }
`;

export const Contacts = tw.div`
  w-full flex flex-col flex-wrap justify-start items-start  gap-5 
  md:flex-row md:gap-x-14 md:gap-y-8 md:items-center
`;

export const Contact = tw.div`
 flex justify-center items-center gap-2
`;

export const Social = tw.a`
 w-[38px] h-[38px] rounded-full flex justify-center items-center bg-green
`;
