import React from 'react';
import PropTypes from 'prop-types';
import {
  ProgramCardWrapper,
  ProgramDiscp,
  ProgramImage,
  ProgramNumber,
  ProgramTag,
  ProgramTitle,
} from './ProgramCard.styles';
import Heart from 'Icons/Heart';
import { Link } from 'react-router-dom';

function ProgramCard({
  id,
  image,
  title,
  number,
  handleFav,
  handleHover,
  isFavorites,
}) {
  return (
    <ProgramCardWrapper onMouseEnter={handleHover}>
      <ProgramTag onClick={handleFav} isFav={isFavorites}>
        <Heart />
      </ProgramTag>
      <Link to={`/poi/${id}`}>
        <ProgramImage src={image} />
        <ProgramDiscp>
          <ProgramNumber>{number}</ProgramNumber>
          {title && (
            <ProgramTitle level="h2" size="xl">
              {title}
            </ProgramTitle>
          )}
        </ProgramDiscp>
      </Link>
    </ProgramCardWrapper>
  );
}

ProgramCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string,
  number: PropTypes.number,
};

export default ProgramCard;
