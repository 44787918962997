import PropTypes from 'prop-types';
import { sizes, StyledBtn, variants } from './Button.styles';

const Button = ({ children, variant, size, width, ...rest }) => {
  return (
    <StyledBtn variant={variant} width={width} size={size} {...rest}>
      {children}
    </StyledBtn>
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf(Object.keys(variants)).isRequired,
  size: PropTypes.oneOf(Object.keys(sizes)),
  width: PropTypes.string,
};

export default Button;
