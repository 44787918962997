const Distance = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.4798 0.222168C2.61621 0.222884 1.78813 0.56634 1.1775 1.177C0.566872 1.78767 0.223378 2.61575 0.222663 3.4793C0.221446 4.06476 0.379928 4.63946 0.680997 5.14155L3.02766 8.67384C3.13143 8.82122 3.29958 8.9098 3.47984 8.91217C3.66081 8.91267 3.83024 8.82337 3.93208 8.67384L6.29708 5.11717C6.68546 4.45438 6.82661 3.67549 6.69547 2.91863C6.56441 2.16174 6.16959 1.47575 5.581 0.982268C4.99234 0.488625 4.24804 0.219436 3.47982 0.222296L3.4798 0.222168ZM3.4798 4.56717C3.19248 4.56631 2.91719 4.45158 2.71446 4.24798C2.51165 4.04445 2.39791 3.76873 2.39813 3.48143C2.39842 3.19411 2.51272 2.91862 2.71589 2.71545C2.91906 2.51228 3.19455 2.39799 3.48187 2.39769C3.76919 2.39748 4.04491 2.5112 4.24843 2.71402C4.45202 2.91676 4.56675 3.19204 4.56761 3.47936C4.5694 3.7684 4.45539 4.04619 4.25099 4.25057C4.04661 4.45495 3.76881 4.56896 3.47978 4.56719L3.4798 4.56717Z"
        fill="black"
      />
      <path
        d="M13.996 4.02327C12.4634 4.02527 10.9941 4.635 9.91027 5.71874C8.8264 6.80247 8.2168 8.27165 8.21486 9.80432C8.21257 10.8344 8.48922 11.8459 9.01537 12.7316L13.5437 19.5333C13.643 19.6865 13.8134 19.7786 13.9959 19.7777C14.1781 19.7771 14.3479 19.6854 14.4482 19.5333L18.6099 13.2877L18.9888 12.7071V12.7071C19.5084 11.8283 19.7808 10.8254 19.777 9.80424C19.775 8.27161 19.1654 6.80234 18.0816 5.71866C16.9979 4.63498 15.5285 4.02519 13.9959 4.02319L13.996 4.02327ZM13.996 11.9006C13.4401 11.9006 12.907 11.6797 12.5138 11.2866C12.1207 10.8935 11.9 10.3604 11.9 9.80439C11.9 9.24853 12.1207 8.71535 12.5138 8.32225C12.907 7.92917 13.4401 7.70831 13.996 7.70831C14.552 7.70831 15.0851 7.92917 15.4783 8.32225C15.8713 8.71534 16.0921 9.24851 16.0921 9.80439C16.0923 10.3604 15.8715 10.8936 15.4784 11.2867C15.0852 11.6798 14.552 11.9007 13.996 11.9005V11.9006Z"
        fill="black"
      />
      <path
        d="M11.6287 18.6917C9.61243 18.6895 7.67936 17.8875 6.25376 16.4619C4.82816 15.0362 4.02608 13.1032 4.02388 11.0869V10.5437C4.02388 10.3496 3.92032 10.1703 3.75225 10.0733C3.58417 9.97623 3.37713 9.97623 3.20905 10.0733C3.04097 10.1703 2.9375 10.3496 2.9375 10.5437V11.0869C2.94008 13.3912 3.85668 15.6004 5.48602 17.2297C7.11536 18.8589 9.32447 19.7756 11.6288 19.7782C11.8229 19.7782 12.0022 19.6747 12.0992 19.5066C12.1963 19.3386 12.1963 19.1314 12.0992 18.9634C12.0022 18.7954 11.8229 18.6918 11.6288 18.6918L11.6287 18.6917Z"
        fill="black"
      />
    </svg>
  );
};

export default Distance;
