const Adrenaline = () => {
  return (
    <svg
      width="38"
      height="40"
      viewBox="0 0 38 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.2841 0.00488281H30.2161L29.8737 8.92964L26.5294 14.1222L24.6692 12.262L22.7736 14.201L22.7733 9.33339L15.7169 9.47746L15.7016 12.9264L6.98047 21.6768L11.6882 26.3845C10.8051 27.6617 10.8042 29.3228 11.6357 30.5917C7.55842 30.7986 5.68621 35.7589 8.55048 38.6231C11.4345 41.5072 16.4763 39.584 16.5851 35.4198C19.0143 36.1146 21.6808 35.4809 23.5324 33.6291L34.6574 22.2502L32.3385 19.9312L37.1433 10.2872L37.2841 0.00488281ZM18.0607 11.7843L20.4192 11.7362L20.4201 23.1017L15.9327 29.3499C15.534 29.9044 14.7971 30.0943 14.1801 29.8014C13.3851 29.424 13.1238 28.4178 13.639 27.7008L18.0171 21.6042L18.0607 11.7843ZM15.6866 16.2762L15.6663 20.8418L13.0784 24.4454L10.3069 21.6741L15.6866 16.2762ZM13.5444 36.9585C12.0705 38.4324 9.52551 37.3853 9.52551 35.2937C9.52551 33.2158 12.0566 32.1413 13.5444 33.6291H13.5444C14.4644 34.5492 14.4645 36.0382 13.5444 36.9585ZM21.8631 31.9691C20.1186 33.7087 17.3394 33.7929 15.4932 32.2245C16.4134 32.0484 17.2639 31.5317 17.8447 30.7235L22.7743 23.8595L22.7738 17.5683L24.6882 15.6103L31.3468 22.2688L21.8631 31.9691ZM34.7965 9.71806L30.5829 18.1757L28.2326 15.8254L32.2015 9.66321L32.4817 2.35908H34.8974L34.7965 9.71806Z"
        fill="currentColor"
      />
      <path d="M0.71875 0H3.07295V9.41679H0.71875V0Z" fill="currentColor" />
      <path
        d="M5.42578 4.70801H7.77998V14.1248H5.42578V4.70801Z"
        fill="currentColor"
      />
      <path d="M10.1367 0H12.4909V9.41679H10.1367V0Z" fill="currentColor" />
    </svg>
  );
};

export default Adrenaline;
