import tw from 'twin.macro';
export const Wrapper = tw.section`
fixed z-50 left-0 top-96 w-full bottom-0 pt-10 bg-white rounded-t-[1.25rem]
md:top-0 md:max-w-[37.5rem]  md:rounded-t-[2.5rem] md:rounded-tl-none
[box-shadow: 0px 20px 113px rgba(0, 0, 0, 0.25)] md:[box-shadow: 0px 0px 30px 15px rgba(0, 0, 0, 0.02)]

`;
export const Content = tw.div`
  w-full h-full flex flex-col gap-8  items-center 
  overflow-y-auto overflow-x-hidden px-4 pb-6 
  md:pb-10 md:pl-10 md:pr-6
`;

export const CloseBtn = tw.button`
  absolute top-4 block md:hidden text-black left-1/2 -translate-x-1/2
`;
export const ResultWrapper = tw.div`
  w-full flex flex-col   border-0
  md:p-0 md:border-[1px] border-[#EDEDED] rounded-[1.875rem]
`;
export const Header = tw.div`
  w-full flex justify-between items-center gap-2
`;

export const ResultTitle = tw.div`
 w-full flex justify-between items-center md:p-6
`;
export const EditBtn = tw.button`
 flex justify-center items-center gap-1.5
 capitalize text-base font-bold
`;
export const Result = tw.div`
  w-full bg-[#F7F5F2]  p-4 md:p-6 md:rounded-b-[1.875rem]
  flex flex-col justify-center gap-6 [&>button]:self-end
`;
