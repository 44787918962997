import {
  Cities_API,
  GEO_CODE_TOKEN,
  LOCATIONIQ_BASE_URL,
  MAPBOX_DIRECTIONS_API,
  MAPBOX_TOKEN,
  PLACES_API,
  TOURS_API,
  Tags_API,
} from 'utils/constants';

export const getAllPlaces = async () => {
  const res = await fetch(PLACES_API);
  if (!res.ok) {
    throw new Error('we could not fetch the places');
  }

  return res.json();
};

export const getAllCities = async () => {
  const res = await fetch(Cities_API);
  if (!res.ok) {
    throw new Error('we could not fetch the cities');
  }

  return res.json();
};

export const getTags = async () => {
  const res = await fetch(Tags_API);
  if (!res.ok) {
    throw new Error('We could not fetch the tags filters');
  }

  return res.json();
};

export const getPrograms = async () => {
  const res = await fetch(TOURS_API);
  if (!res.ok) {
    throw new Error('We could not fetch the tags filters');
  }

  return res.json();
};

export const searchPlaces = async (string) => {
  if (string !== '') {
    const url = `${LOCATIONIQ_BASE_URL}${GEO_CODE_TOKEN}&q=${string}&format=json&countrycodes=ma`;
    const res = await fetch(url);

    if (!res.ok) {
      throw new Error('We could not fetch the tags filters');
    }
    return res.json();
  }
};

export const getDirections = async (travelMode, coordinates) => {
  if (travelMode && coordinates.length > 1) {
    const coordinatesString = coordinates.join(';');
    const url = `${MAPBOX_DIRECTIONS_API}mapbox/${travelMode}/${coordinatesString}?geometries=geojson&access_token=${MAPBOX_TOKEN}&overview=full`;
    const res = await fetch(url);

    if (!res.ok) {
      throw new Error('We could not geth the directions');
    }

    return res.json();
  }
};
