import Title from 'components/Typography/Title';
import { Link } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

export const Wrapper = styled(Link)`
  ${tw`relative block w-[8.3rem] h-[7.875rem]  md:w-[11rem] md:h-[11.31rem] rounded-xl isolate`}
  &:after {
    content: '';
    ${tw`absolute bottom-0 w-full h-20 rounded-b-xl`};
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.78) 100%
    );
  }
`;

export const Image = tw.img`
  w-full h-full object-cover rounded-xl
`;

export const Tag = styled.div`
  ${tw`absolute top-2 left-2  bg-white p-1.5 text-[0.625rem]
       flex justify-center items-center gap-1 rounded-full
       md:top-4 md:left-4 md:p-2`}
  color: ${({ color }) => color}
`;

export const AbsTitle = styled(Title)`
  ${tw`absolute bottom-2 left-2 right-2.5 text-white text-xs  z-20
       md:left-3 md:w-[80%] md:bottom-4`}
`;
