import { keyframes } from 'styled-components';
import tw, { styled } from 'twin.macro';

const heartBeat = keyframes`
   0% { scale: 1; opacity: 1;}
  25% {scale: 1.2; opacity: 0.7;}
  50% {scale: 0.8; opacity: 0.9;}
  75% {scale: 1.3; opacity: 0.8;}
  100% {scale: 1; opacity: 1;}
`;
export const Wrapper = tw.div`
  fixed bottom-6 right-4 flex flex-col-reverse justify-center items-center max-h-max gap-6 
  lg:(z-40 flex-row top-[2.6rem] right-[6.938rem] gap-2 h-[3.625rem])
`;

export const ControlsGrp = tw.div`
flex flex-col justify-center items-center bg-transparent gap-2
lg:(flex-row gap-2.5 h-[3.625rem] py-3 px-[9px] bg-white rounded-full)
`;

export const ControleButton = tw.button`
  flex  gap-2.5 justify-center items-center
  bg-blue rounded-full w-10 h-10 text-white
`;

export const FavButton = styled(ControleButton)`
  ${tw`relative bg-pink`}
  animation:  ${heartBeat} 1.5s ease-out 0.5s backwards;
`;

export const Count = tw.span`
absolute -top-1 -right-2 w-5 h-5 flex justify-center items-center
rounded-full border-2 border-white text-white text-center bg-pink
`;

export const ToggleWrapper = tw.button`
  relative w-[3.625rem] h-[3.625rem]  border-2 border-white rounded-[10px] 
`;

export const ToggleImage = tw.img`
  w-full h-full object-contain
`;

export const Overlay = tw.div`
  z-10 absolute inset-0 flex justify-center items-center 
  text-white bg-[rgba(0, 0, 0, 0.2)] rounded-[10px]
`;
