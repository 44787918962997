import Title from 'components/Typography/Title';
import tw, { styled } from 'twin.macro';

export const Wrapper = tw.div`
   w-full flex flex-col justify-start md:flex-row md:justify-center items-stretch min-h-screen
`;

export const LeftSide = tw.div`
w-full h-[50vh]  bg-[rgba(217, 217, 217, 0.2)] flex justify-center items-center px-8 mb-8 
md:w-1/2 md:px-32 md:mb-0 md:h-auto
`;

export const Image = tw.img`
   w-full object-contain
`;

export const RightSide = tw.div`
  flex w-full  flex-col justify-center items-center text-center px-8 gap-6
  md:w-1/2 md:px-32 md:gap-8
`;

export const Description = tw.span`
   block text-base md:text-lg font-medium capitalize 
`;

export const ExtraTitle = styled(Title)`
  ${tw`text-3xl  tracking-[-1px] md:text-[2.875rem] md:leading-[4.24rem]`}
`;
