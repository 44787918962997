const ChevronDown = ({ width = '11', height = '7' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 1.5L6.75 6.75L1.5 1.5"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};

export default ChevronDown;
