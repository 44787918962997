const ClusterIcon = () => {
  return (
    <svg
      width="90"
      height="98"
      viewBox="0 0 90 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M89.5 45C89.5 66.9574 73.5965 85.2014 52.6799 88.8398C52.1147 88.9382 51.5896 89.221 51.2033 89.6577L45.5466 96.0523C44.9496 96.7271 43.8966 96.7271 43.2996 96.0523L37.411 89.3956C37.0423 88.9789 36.5468 88.7017 36.0103 88.5916C15.743 84.4342 0.5 66.4966 0.5 45C0.5 20.4233 20.4233 0.5 45 0.5C69.5767 0.5 89.5 20.4233 89.5 45Z"
        fill="currentColor"
        stroke="white"
      />
    </svg>
  );
};

export default ClusterIcon;
