import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useFavorites = create(
  persist(
    (set, get) => ({
      favFilters: ['all', 'places', 'programs'],
      activeFilter: 'all',
      programs: {},
      favorites: [],
      setActiveFilter: (filter) => set({ activeFilter: filter }),
      addProgram: (name, program) =>
        set((state) => ({ programs: { ...state.programs, [name]: program } })),
      isFavorites: (id) =>
        get().favorites.findIndex((fav) => fav.id === id) > -1,
      removeFav: (id, type) => {
        if (type === 'program') {
          set((state) => {
            const newPrograms = state.programs;
            delete newPrograms[id];
            return { programs: newPrograms };
          });
        } else {
          set((state) => ({
            favorites: state.favorites.filter((fav) => fav.id !== id),
          }));
        }
      },

      toggleFavorites: (place) =>
        set((state) => {
          const index = state.favorites.findIndex((fav) => fav.id === place.id);
          if (index >= 0) {
            return {
              favorites: state.favorites.filter((fav) => fav.id !== place.id),
            };
          } else {
            return { favorites: [...state.favorites, place] };
          }
        }),
      addStepToFavorites: (firstIndex, secIndex, place, name) => {
        get().programs[name][firstIndex].splice(secIndex, 0, place);
      },
      replaceEmptyFavStep: (dayIndex, place, name) =>
        set((state) => {
          const newDayArr = state.programs[name][dayIndex];
          newDayArr.splice(0, 1, place);
          const newProgram = state.programs[name];
          newProgram.splice(dayIndex, 1, newDayArr);

          return { program: newProgram };
        }),
      removeFavStep: (id, name) =>
        set((state) => {
          const newProgram = state.programs[name].map((day) =>
            day.filter((place) => place.id !== id),
          );
          return { programs: { ...state.programs, [name]: newProgram } };
        }),
    }),
    {
      name: 'favorites',
    },
  ),
);

export default useFavorites;
