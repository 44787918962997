import CheckboxList from 'components/CreatePlan/CheckboxList';
import Title from 'components/Typography/Title';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useTags from 'store/useTags';
import useTripForm from 'store/useTripForm';
import { Wrapper } from './Step2.styles';

export default function Step2({ isResult }) {
  const { preferencesTags } = useTags();
  const { preferences, handlePreferences } = useTripForm();
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Title level="h3" size="md">
        {t('preferences')}
      </Title>
      <CheckboxList
        isResult={isResult}
        handleChange={handlePreferences}
        checkedResults={preferences}
        items={preferencesTags}
        name="preferences"
      />
    </Wrapper>
  );
}
