import PropTypes from 'prop-types';
import Title from 'components/Typography/Title';
import ArrowLeft from 'Icons/ArrowLeft';
import placeholder from 'assets/placeholder.svg';
import {
  AddFav,
  BackButton,
  Image,
  Infos,
  ShadowDrop,
  Type,
  Wrapper,
} from './PoiCard.styles';
import Heart from 'Icons/Heart';
import Close from 'Icons/Close';
import { A11y, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

function PoiCard({
  isDirections,
  media,
  type,
  title,
  handleClick,
  handleFav,
  isFavorites,
}) {
  return (
    <Wrapper isDirections={isDirections}>
      <Swiper
        modules={[A11y, Autoplay, Pagination]}
        slidesPerView={1}
        centeredSlides
        loop={media?.length > 1}
        speed={400}
        autoplay={{
          delay: 3000,
        }}
        pagination={true}
      >
        {Array.isArray(media) &&
          media.map((image) => (
            <SwiperSlide key={image.url} className="relative">
              <Image src={image?.url || placeholder} alt={title} />
              <ShadowDrop />
            </SwiperSlide>
          ))}
      </Swiper>
      <BackButton variant="square" onClick={handleClick}>
        {isDirections ? <Close /> : <ArrowLeft />}
      </BackButton>
      <AddFav onClick={handleFav} isFav={isFavorites}>
        <Heart />
      </AddFav>
      <Infos>
        <Type>{type}</Type>
        <Title level="h2" size="md">
          {title}
        </Title>
      </Infos>
    </Wrapper>
  );
}

PoiCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  handleClick: PropTypes.func,
};

export default PoiCard;
