const PrintIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_1809_2651)">
        <path
          d="M10.3118 12.4948H5.68959C5.39506 12.4948 5.15625 12.7336 5.15625 13.0282C5.15625 13.3227 5.39503 13.5615 5.68959 13.5615H10.3118C10.6063 13.5615 10.8452 13.3227 10.8452 13.0282C10.8452 12.7336 10.6064 12.4948 10.3118 12.4948Z"
          fill="black"
        />
        <path
          d="M10.3118 10.8127H5.68959C5.39506 10.8127 5.15625 11.0515 5.15625 11.346C5.15625 11.6406 5.39503 11.8794 5.68959 11.8794H10.3118C10.6063 11.8794 10.8452 11.6406 10.8452 11.346C10.8452 11.0515 10.6064 10.8127 10.3118 10.8127Z"
          fill="black"
        />
        <path
          d="M14.7556 4.18458H13.0586V1.09786C13.0586 0.803327 12.8198 0.564514 12.5253 0.564514H3.47475C3.18022 0.564514 2.94141 0.803295 2.94141 1.09786V4.18458H1.24444C0.55825 4.18458 0 4.74286 0 5.42905V10.7947C0 11.4809 0.55825 12.0392 1.24444 12.0392H2.9415V14.9021C2.9415 15.1966 3.18028 15.4355 3.47484 15.4355H12.5252C12.8197 15.4355 13.0585 15.1967 13.0585 14.9021V12.0392H14.7556C15.4418 12.0392 16 11.4809 16 10.7947V5.42905C16 4.74289 15.4418 4.18458 14.7556 4.18458ZM4.00806 1.6312H11.9919V4.18458H4.00806V1.6312ZM11.9918 14.3688H4.00819C4.00819 14.2645 4.00819 10.1343 4.00819 10.0054H11.9918C11.9918 10.1375 11.9918 14.2685 11.9918 14.3688ZM12.5253 7.3163H11.1677C10.8731 7.3163 10.6343 7.07751 10.6343 6.78295C10.6343 6.48839 10.8731 6.24961 11.1677 6.24961H12.5253C12.8198 6.24961 13.0586 6.48839 13.0586 6.78295C13.0586 7.07751 12.8198 7.3163 12.5253 7.3163Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1809_2651">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PrintIcon;
