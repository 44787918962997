import { create } from 'zustand';

const useStepScroll = create((set) => ({
  id: null,
  setScrollId: (id) => {
    set({ id });
  },
}));

export default useStepScroll;
