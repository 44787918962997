import tw, { styled } from 'twin.macro';

export const FilterTagsWrapper = tw.div`
 flex gap-[1.188rem] items-center text-center
`;

export const FilterTag = styled.div(({ isSelected }) => [
  tw`bg-[#E9E7E5] text-[#212324] w-fit py-2 px-4 rounded-[2.563rem] font-medium
    text-lg leading-[1.361rem] -tracking-[0.063rem] cursor-pointer`,
  isSelected ? tw`opacity-100` : tw`opacity-60`,
]);
