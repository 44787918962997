import useRecommended from 'store/useRecommended';
import { Wrapper } from './suggestions.styles';
import FavoriteCard from 'components/FavoritePlaces/FavoriteCard';
import i18next from 'i18next';
import placeholder from 'assets/placeholder.svg';

export default function Suggestions() {
  const { programs } = useRecommended();

  return (
    <Wrapper>
      {programs?.length > 0 &&
        programs.map((program) => (
          <FavoriteCard
            key={program.id}
            id={program.id}
            title={
              program.type ? program.title : program.title[i18next.language]
            }
            image={program.image || placeholder}
            type={'suggestedProgram'}
            days={program.days}
            regions={program.regions}
            distance={program.distance}
          />
        ))}
    </Wrapper>
  );
}
