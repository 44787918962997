import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Buttons,
  ConfirmMessage,
  ConfirmPanel,
  RemoveBtn,
  FavoriteCardWrapper,
  FavoriteImage,
  FavoriteTitle,
  Tag,
  Wrapper,
  InfoWrapper,
  Breadcrumbs,
  Item,
} from './FavoriteCard.styles';
import Close from 'Icons/Close';
import Modal from 'components/Modal';
import Title from 'components/Typography/Title';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';

function FavoriteCard({
  image,
  title,
  type,
  days,
  regions,
  distance,
  id,
  handleRemove,
}) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
  };
  const getPath = () => {
    if (type === 'program') {
      return `/program/${id.split(' ').join('-')}`;
    } else if (type === 'suggestedProgram') {
      return `/suggestion-previw/${id}`;
    } else {
      return `/poi/${id}`;
    }
  };

  return (
    <Wrapper>
      {handleRemove && (
        <RemoveBtn onClick={() => setIsOpen(true)}>
          <Close />
        </RemoveBtn>
      )}
      <FavoriteCardWrapper to={getPath()}>
        <FavoriteImage src={image} alt={title} />
        <Tag type={type}>{t(type)}</Tag>
        <InfoWrapper>
          <FavoriteTitle level="h2" size="md">
            {title}
          </FavoriteTitle>
          <Breadcrumbs>
            {days && (
              <Item>
                {days} {t('days')}
              </Item>
            )}
            {regions && (
              <Item>
                {regions} {t('region')}
              </Item>
            )}
            {distance && <Item>{distance} </Item>}
          </Breadcrumbs>
        </InfoWrapper>
      </FavoriteCardWrapper>
      <Modal isOpen={isOpen} closeModal={closeModal}>
        <ConfirmPanel>
          <ConfirmMessage>{t('deleteConfirm')}</ConfirmMessage>
          <Title level="h1" size="lg">
            {title}
          </Title>
          <Buttons>
            <Button
              variant="primary"
              size="small"
              onClick={() => handleRemove(id, type)}
            >
              {t('delete')}
            </Button>
            <Button
              variant="secondary"
              size="small"
              onClick={() => setIsOpen(false)}
            >
              {t('cancel')}
            </Button>
          </Buttons>
        </ConfirmPanel>
      </Modal>
    </Wrapper>
  );
}

FavoriteCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
};

export default FavoriteCard;
