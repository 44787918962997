import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import placeholder from 'assets/placeholder.svg';
import { getDistanceWithUnit } from 'utils/helpers';
import { getDistance } from 'geolib';
import {
  Day,
  DayInfo,
  Header,
  HeaderDivider,
  HeaderPlaceHolder,
  InfoDivider,
  List,
  PrintBtn,
  Stops,
  Title,
  TripInfo,
  Wrapper,
} from './print.syles';
import PinIcon from 'Icons/PinIcon';
import PrintCard from 'components/PrintCard';
import { useParams } from 'react-router';
import useFavorites from 'store/useFavorites';
import { useMemo } from 'react';
import { DayWrapper } from 'components/Program/PlanSwiper/PlanSwiper.styles';
import BigPrintIcon from 'Icons/BigPrintIcon';

const Print = () => {
  const { programs } = useFavorites();
  const params = useParams();
  const name = params.name.split('-').join(' ');

  const program = useMemo(() => {
    const myProgram = {};
    if (programs[name]) {
      programs[name]?.forEach((day, index) => {
        if (typeof day[0] !== 'string') {
          const firstPlaceCords = {
            latitude: day[0]?.location.coordinates[1],
            longitude: day[0]?.location.coordinates[0],
          };
          const lastPlaceCords = {
            latitude: day.slice(-1)[0]?.location.coordinates[1],
            longitude: day.slice(-1)[0]?.location.coordinates[0],
          };
          const distance = getDistance(firstPlaceCords, lastPlaceCords) * 1.4;

          myProgram[`day${index + 1}`] = {
            region: day[0].contact?.address?.city,
            distance: getDistanceWithUnit(distance),
            dayPlaces: day,
          };
        } else {
          myProgram[`day${index + 1}`] = {
            region: day[0],
            distance: 0 + 'm',
            dayPlaces: day,
          };
        }
      });
    }
    return myProgram;
  }, [programs, name]);
  const { t } = useTranslation();
  const tripDistance = Object.values(program).reduce(
    (accumulator, day) =>
      accumulator + parseInt(day?.distance?.replace('km', '')),
    0,
  );

  const getPlaceCords = (place) => ({
    latitude: place?.location.coordinates[1],
    longitude: place?.location.coordinates[0],
  });

  return (
    <table style={{ margin: 'auto' }}>
      <PrintBtn onClick={() => window.print()}>
        <BigPrintIcon />
        {t('print')}
      </PrintBtn>
      <thead>
        <tr>
          <td>
            <HeaderPlaceHolder />
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <Wrapper>
              <Header>
                <Title>{name}</Title>
                <TripInfo>
                  <HeaderDivider />
                  <p>{Object.keys(program).length + ' ' + t('day')} </p>
                  <InfoDivider />
                  <p>{`${tripDistance} km`}</p>
                </TripInfo>
              </Header>
              {Array.isArray(Object.keys(program)) &&
                Object.keys(program).map((day, i) => (
                  <List>
                    <DayWrapper>
                      <Day key={day}>
                        <p>{`${t('day')} ${i + 1}`}</p>
                        <DayInfo>
                          <p>{program[day].distance}</p>
                        </DayInfo>
                        <DayInfo>
                          <PinIcon />
                          <p>{program[day].region}</p>
                        </DayInfo>
                      </Day>
                      <Stops>
                        {Array.isArray(program[day].dayPlaces) &&
                          program[day].dayPlaces.map((place, i) =>
                            typeof place !== 'string' ? (
                              <PrintCard
                                key={place.id}
                                prevStop={i}
                                title={place.title[i18next.language]}
                                image={place.media[0].url || placeholder}
                                coords={place.location.coordinates}
                                tags={place.tags}
                                distance={
                                  i === 0
                                    ? null
                                    : getDistanceWithUnit(
                                        getDistance(
                                          getPlaceCords(place),
                                          getPlaceCords(
                                            program[day].dayPlaces[i - 1],
                                          ),
                                        ),
                                      )
                                }
                              />
                            ) : null,
                          )}
                      </Stops>
                    </DayWrapper>
                  </List>
                ))}
            </Wrapper>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Print;
