import { Link } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

export const Wrapper = tw.section`
fixed z-50 left-0 top-0 w-full bottom-0 pt-10 bg-white overflow-hidden rounded-t-[1.25rem] 
 md:max-w-[37.5rem]  md:rounded-t-[2.5rem] md:rounded-tl-none
 md:[box-shadow: 0px 0px 30px 15px rgba(0, 0, 0, 0.02)]

`;

export const Content = tw.div`
  w-full h-full flex flex-col gap-6  items-start
  overflow-y-auto overflow-x-hidden px-4 pb-6
`;

export const Header = tw.div`
  w-full flex justify-between items-center gap-2
`;
export const BtnsWrapper = tw.div`
 flex justify-center items-center gap-2.5
`;
export const Btn = tw.button`
 w-[2.375rem] h-[2.375rem] rounded-full flex justify-center items-center
 border-[1px] border-orange
`;

export const PrintBtn = styled(Link)`
  ${tw`w-[2.375rem] h-[2.375rem] rounded-full flex justify-center items-center
 border-[1px] border-orange`}
`;

export const DayWrapper = tw.div`
 w-full flex flex-col justify-center items-start 
 border-[1px] border-[#CACACA] rounded-[1.75rem] 
`;
export const Breadcrumbs = tw.ul`
   flex gap-4 justify-center items-center px-7 pt-7
`;

export const Breadcrumb = styled.li`
  ${tw`flex justify-center items-center gap-1.5 whitespace-nowrap
       capitalize text-sm md:text-lg font-bold
       last:text-[#B0B0B0] last:text-sm`}
  &:after {
    content: '';
    ${tw`bg-[#D9D9D9] w-0.5 h-4 ml-2`}
  }
  &:last-child:after {
    display: none;
  }
`;
