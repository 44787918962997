const Vacation = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.9921 36.0532H6.03929C6.3846 36.0532 6.65929 36.3279 6.65929 36.6732V37.8517C6.65929 39.0298 5.69397 39.9951 4.51554 39.9951C3.33741 39.9951 2.36304 39.0295 2.36304 37.8517V36.6732C2.36304 36.3279 2.64647 36.0532 2.9921 36.0532ZM5.41022 37.3023H3.6121V37.8517C3.6121 38.3476 4.0196 38.746 4.51554 38.746C5.01147 38.746 5.41022 38.3476 5.41022 37.8517V37.3023Z"
        fill="currentColor"
      />
      <path
        d="M12.6122 36.0532H15.6594C16.0047 36.0532 16.2794 36.3279 16.2794 36.6732V37.8517C16.2794 39.0298 15.3138 39.9951 14.1357 39.9951C12.9572 39.9951 11.9832 39.0295 11.9832 37.8517V36.6732C11.9835 36.3279 12.2666 36.0532 12.6122 36.0532ZM15.0307 37.3023H13.2325V37.8517C13.2325 38.3476 13.64 38.746 14.1363 38.746C14.6325 38.746 15.031 38.3476 15.031 37.8517L15.0307 37.3023Z"
        fill="currentColor"
      />
      <path
        d="M22.728 26.6724H36.3345C37.5655 26.6724 38.5758 27.6821 38.5758 28.9224V33.3689C38.5758 33.7142 38.3011 33.998 37.9558 33.998H21.107C20.7617 33.998 20.4783 33.7146 20.4783 33.3689V28.9224C20.4783 27.6821 21.497 26.6724 22.728 26.6724ZM36.3345 27.9214H22.728C22.1786 27.9214 21.7361 28.373 21.7361 28.9224V32.7489H37.3264V28.9224C37.3264 28.373 36.8839 27.9214 36.3345 27.9214Z"
        fill="currentColor"
      />
      <path
        d="M20.9739 32.749H38.0883C39.1424 32.749 40.0017 33.6084 40.0017 34.6537V36.7971C40.0017 37.1428 39.7186 37.4262 39.373 37.4262H19.6899C19.3445 37.4262 19.0608 37.1431 19.0608 36.7971V34.6537C19.0608 33.6084 19.9202 32.749 20.9739 32.749ZM38.0883 33.9981H20.9739C20.6108 33.9981 20.3099 34.2906 20.3099 34.6537V36.1775H38.753V34.6537C38.753 34.2993 38.4514 33.9981 38.0883 33.9981Z"
        fill="currentColor"
      />
      <path
        d="M22.0197 36.1777H26.1034C26.4487 36.1777 26.7322 36.4521 26.7322 36.7974C26.7322 36.9746 26.6612 37.134 26.5459 37.2493L24.3493 39.7824C24.2256 39.9243 24.0568 39.9952 23.88 39.9952H22.0197C21.6743 39.9952 21.3997 39.7118 21.3997 39.3752V36.7974C21.3997 36.4518 21.674 36.1777 22.0197 36.1777ZM24.7393 37.4265H22.649V38.7465H23.5962L24.7393 37.4265Z"
        fill="currentColor"
      />
      <path
        d="M36.4139 37.4265H34.3236L35.4661 38.7465H36.4139V37.4265ZM32.9596 36.1777H37.0433C37.3886 36.1777 37.6633 36.4521 37.6633 36.7974V39.3752C37.6633 39.7118 37.3886 39.9952 37.0433 39.9952H35.183C35.0061 39.9952 34.8377 39.9243 34.7136 39.7824L32.5164 37.2493C32.4017 37.134 32.3308 36.9746 32.3308 36.7974C32.3308 36.4518 32.6139 36.1777 32.9596 36.1777Z"
        fill="currentColor"
      />
      <path
        d="M24.3493 30.4019H27.5914C28.2914 30.4019 28.858 30.9687 28.858 31.6687V33.3694C28.858 33.7147 28.5749 33.9984 28.2293 33.9984H23.703C23.3577 33.9984 23.083 33.715 23.083 33.3694V31.6687C23.0827 30.9778 23.6499 30.4019 24.3493 30.4019ZM27.5914 31.6509H24.3493C24.3408 31.6509 24.3314 31.6597 24.3314 31.6687V32.7494H27.6093V31.6687C27.6093 31.66 27.5999 31.6509 27.5914 31.6509Z"
        fill="currentColor"
      />
      <path
        d="M31.4715 30.4019H34.7137C35.4046 30.4019 35.9802 30.9778 35.9802 31.6687V33.3694C35.9802 33.7147 35.6971 33.9984 35.3515 33.9984H30.8246C30.4793 33.9984 30.2046 33.715 30.2046 33.3694V31.6687C30.2046 31.3234 30.3468 31.0044 30.5768 30.774C30.8068 30.5437 31.1168 30.4019 31.4715 30.4019ZM34.7137 31.6509H31.4715C31.4624 31.6509 31.4537 31.6597 31.4537 31.6687V32.7494H34.7312V31.6687C34.7315 31.66 34.7221 31.6509 34.7137 31.6509Z"
        fill="currentColor"
      />
      <path
        d="M29.1149 17.2563L30.0365 20.4538C30.098 20.6576 30.0543 20.8523 29.9387 21.0032L28.5299 22.8367C28.2821 23.1732 27.7859 23.1645 27.538 22.8367L25.7399 20.6135C25.5537 20.3832 25.5624 20.0467 25.7484 19.8251L28.0249 17.017C28.2374 16.7426 28.6274 16.707 28.9021 16.9195C29.0087 17.0082 29.0887 17.1323 29.1149 17.2563ZM28.7518 20.4982L28.2471 18.727L27.0337 20.2238L28.0255 21.4551L28.7518 20.4982Z"
        fill="currentColor"
      />
      <path
        d="M24.7571 16.4678L22.9321 16.7247L22.3564 17.7788L23.8883 18.1863L24.7571 16.4678ZM22.4449 15.5288L25.7402 15.0772C26.2358 14.9709 26.6527 15.5022 26.4133 15.9719L24.7746 19.2053L24.7655 19.1966C24.6417 19.4534 24.3408 19.595 24.0567 19.5244L21.293 18.7891C21.2399 18.7803 21.1867 18.7538 21.1424 18.7272C20.8324 18.5678 20.7261 18.1869 20.8855 17.8856L21.9842 15.8569C22.0727 15.6881 22.2411 15.5644 22.4449 15.5288Z"
        fill="currentColor"
      />
      <path
        d="M33.9958 1.04572C35.2892 0.141968 36.6977 -0.353969 37.7164 0.310406C38.7083 0.948218 38.8236 2.43634 38.5405 3.98666C38.3542 4.96103 37.7164 7.00728 37.0964 7.77791L26.5105 20.8701C26.2449 21.1979 25.8374 21.3842 25.4211 21.4017C25.0139 21.4282 24.5795 21.2954 24.243 21.0826C23.9061 20.861 23.6136 20.5157 23.4636 20.1348C23.313 19.7363 23.322 19.311 23.5167 18.9301L31.1436 3.92447C31.5952 3.04759 33.1895 1.60353 33.9958 1.04572ZM34.7136 2.06416C33.6949 2.77291 32.738 3.77384 32.2508 4.50916L24.6239 19.4885C24.5439 19.6835 24.7833 19.9404 24.9249 20.0379C25.067 20.1263 25.3858 20.2329 25.5364 20.091L36.1039 7.01634C36.5739 6.28103 37.087 4.98791 37.318 3.75634C37.5211 2.65791 37.5127 1.65697 37.0433 1.35572C36.5386 1.02791 35.617 1.42634 34.7136 2.06416Z"
        fill="currentColor"
      />
      <path
        d="M19.9112 5.85588L30.1071 6.77713C30.4433 6.80369 30.7002 7.114 30.6649 7.45057C30.6565 7.53025 30.6387 7.60994 30.6033 7.68088L28.3799 11.9593C28.2377 12.2428 27.9015 12.3668 27.609 12.2606L17.803 8.5665C17.484 8.44275 17.3158 8.07932 17.4399 7.76025C17.4843 7.64525 17.5549 7.54775 17.6524 7.47682L19.4596 5.98869C19.5837 5.88244 19.7521 5.83807 19.9112 5.85588ZM29.0708 7.92869L20.053 7.11369L19.2468 7.77807L27.529 10.8962L29.0708 7.92869Z"
        fill="currentColor"
      />
      <path
        d="M38.7084 19.214L34.2797 11.3124L32.2062 13.9256L38.425 20.215L38.7084 19.214ZM34.9175 9.89526L39.9131 18.8334C39.9931 18.9662 40.0194 19.1346 39.9753 19.3028L39.3459 21.5703C39.3197 21.6768 39.2666 21.774 39.1866 21.8628C38.9387 22.1021 38.5403 22.1021 38.3009 21.854L30.9491 14.4306C30.7097 14.209 30.6828 13.8371 30.8869 13.5803L33.8722 9.83307C33.9253 9.76214 33.9875 9.70026 34.0669 9.65589C34.3684 9.48776 34.7491 9.59401 34.9175 9.89526Z"
        fill="currentColor"
      />
      <path
        d="M4.35617 11.9058H14.2952C15.4912 11.9058 16.5805 12.3929 17.369 13.1814C18.1574 13.9698 18.6446 15.0595 18.6446 16.2551V34.3083C18.6446 35.132 18.3168 35.8764 17.7677 36.4251C17.2274 36.9654 16.4834 37.3023 15.6593 37.3023H2.99211C2.16836 37.3023 1.41555 36.9658 0.874922 36.4251C0.334609 35.8761 -0.00195312 35.1317 -0.00195312 34.3083V16.2551C-0.00195312 15.0592 0.485234 13.9698 1.27367 13.1814C2.06211 12.3929 3.15148 11.9058 4.35617 11.9058ZM14.2952 13.1548H4.35617C3.4968 13.1548 2.72648 13.5001 2.1593 14.0673C1.60117 14.6254 1.24711 15.4048 1.24711 16.2551V34.3083C1.24711 34.7864 1.44211 35.2208 1.76086 35.5395C2.07992 35.8495 2.51367 36.0533 2.99211 36.0533H15.6593C16.1377 36.0533 16.5715 35.8498 16.8905 35.5395C17.2005 35.2204 17.3955 34.7864 17.3955 34.3083V16.2551C17.3955 15.4048 17.0502 14.6251 16.4834 14.0673C15.9249 13.5004 15.1455 13.1548 14.2952 13.1548Z"
        fill="currentColor"
      />
      <path
        d="M5.2067 6.73242H13.4448C13.7901 6.73242 14.0648 7.00711 14.0648 7.35242V12.5255C14.0648 12.8709 13.7901 13.1546 13.4448 13.1546H5.2067C4.86139 13.1546 4.57764 12.8712 4.57764 12.5255V7.35242C4.57764 7.00711 4.86107 6.73242 5.2067 6.73242ZM12.8158 7.98148H5.83545V11.9055H12.8158V7.98148Z"
        fill="currentColor"
      />
      <path
        d="M1.63683 14.2978C1.29152 14.2799 1.03433 13.9787 1.05214 13.6421C1.06995 13.2968 1.36214 13.0309 1.70777 13.0487C4.79933 13.2259 5.40183 16.1315 5.36652 18.7003L5.37527 36.6734C5.37527 37.0187 5.09183 37.2934 4.75527 37.2934C4.40995 37.2934 4.1262 37.0187 4.1262 36.6734L4.11714 18.7093C4.11714 16.9199 3.97527 14.4306 1.63683 14.2978Z"
        fill="currentColor"
      />
      <path
        d="M16.9528 13.0487C17.2981 13.0309 17.5906 13.2968 17.6084 13.6421C17.6262 13.9787 17.3603 14.2799 17.0237 14.2978C14.6853 14.4306 14.5346 16.9199 14.5346 18.7093L14.5259 36.6737C14.5259 37.019 14.2512 37.2937 13.9059 37.2937C13.5606 37.2937 13.2859 37.019 13.2859 36.6737L13.2946 18.7006C13.2587 16.1315 13.8525 13.2259 16.9528 13.0487Z"
        fill="currentColor"
      />
      <path
        d="M4.74622 29.1973C4.40091 29.1973 4.12622 28.9139 4.12622 28.5682C4.12622 28.2226 4.40091 27.9482 4.74622 27.9482H13.9056C14.2509 27.9482 14.5347 28.2229 14.5347 28.5682C14.5347 28.9136 14.2512 29.1973 13.9056 29.1973H4.74622Z"
        fill="currentColor"
      />
      <path
        d="M4.74622 19.701C4.40091 19.701 4.12622 19.4176 4.12622 19.0719C4.12622 18.7263 4.40091 18.4429 4.74622 18.4429H13.9056C14.2509 18.4429 14.5347 18.7263 14.5347 19.0719C14.5347 19.4176 14.2512 19.701 13.9056 19.701H4.74622Z"
        fill="currentColor"
      />
      <path
        d="M4.86112 6.73273H13.7811V5.47492H4.86112V6.73273ZM14.4102 7.9818H4.24112C3.89581 7.9818 3.61206 7.69836 3.61206 7.35273V4.84586C3.61206 4.50055 3.8955 4.2168 4.24112 4.2168H14.4105C14.7558 4.2168 15.0305 4.50023 15.0305 4.84586V7.35273C15.0305 7.69836 14.7558 7.9818 14.4102 7.9818Z"
        fill="currentColor"
      />
      <path
        d="M4.74622 22.6509C4.40091 22.6509 4.12622 22.3762 4.12622 22.0309C4.12622 21.6856 4.40091 21.4019 4.74622 21.4019H13.9056C14.2509 21.4019 14.5347 21.6853 14.5347 22.0309C14.5347 22.3765 14.2512 22.6509 13.9056 22.6509H4.74622Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Vacation;
