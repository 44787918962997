import { getDistance } from 'geolib';

import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useRecommended = create(
  persist(
    (set) => ({
      programs: [],
      setPrograms: (programs) => set({ programs }),
      getDays: (places) => handleCreateProgramDays(places),
    }),
    { name: 'recommended' },
  ),
);

//

const calcTravelTime = (firsPoint, secondPoint) => {
  //speed of m/min (60km/h)
  const speed = 1000;
  const distance = getDistance(firsPoint, secondPoint) * 1.4;

  return parseInt((distance / speed).toFixed(0));
};

const handleCreateProgramDays = (places) => {
  const maxMinutesPerDay = 600;
  let currentDuration = 0;
  let currentDay = [];

  const days = [];

  for (let i = 0; i < places.length; i++) {
    const duration = places[i].misc?.minVisitDuration;
    const currentRegion = places[i]?.contact?.address?.city;
    let prevRegion = null;
    let travelDuration = 0;
    if (currentDay.length > 0) {
      prevRegion = places[i - 1]?.contact?.address?.city;
      const startCoords = {
        latitude: places[i - 1]?.location.coordinates[1],
        longitude: places[i - 1]?.location.coordinates[0],
      };
      const endCoords = {
        latitude: places[i]?.location.coordinates[1],
        longitude: places[i]?.location.coordinates[0],
      };
      travelDuration = calcTravelTime(startCoords, endCoords);
    }

    if (
      (prevRegion && currentRegion !== prevRegion) ||
      currentDuration + duration + travelDuration > maxMinutesPerDay
    ) {
      days.push(currentDay);
      currentDay = [];
      currentDuration = 0;
    }

    currentDay.push(places[i]);
    currentDuration += duration + travelDuration;
  }

  if (currentDay.length > 0) {
    days.push(currentDay);
  }

  return days;
};

export default useRecommended;
