import { FavoriteListWrapper, FavoriteMapList } from './FavoriteList.styles';
import FavoriteCard from '../FavoriteCard';
import i18next from 'i18next';
import FilterTags from 'components/FilterTags';
import useFavorites from 'store/useFavorites';
import placeholder from 'assets/placeholder.svg';

function FavoriteList({ items }) {
  const { favFilters, activeFilter, setActiveFilter, removeFav } =
    useFavorites();

  const handleRemove = (id, type) => {
    removeFav(id, type);
  };
  return (
    <FavoriteListWrapper>
      <FilterTags
        filters={favFilters}
        active={activeFilter}
        setActive={setActiveFilter}
      />
      <FavoriteMapList>
        {items?.length > 0 &&
          items.map((item) => (
            <FavoriteCard
              key={item.id}
              id={item.id}
              title={item.type ? item.title : item.title[i18next.language]}
              image={
                item.type
                  ? item.image || placeholder
                  : item.media[0]?.url || placeholder
              }
              type={item.type ? item.type : 'place'}
              handleRemove={handleRemove}
            />
          ))}
      </FavoriteMapList>
    </FavoriteListWrapper>
  );
}

export default FavoriteList;
