const Minus = () => {
  return (
    <svg
      width="11"
      height="3"
      viewBox="0 0 11 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.421875 1.75649C0.421875 2.39272 0.937643 2.90849 1.57388 2.90849H9.13388C9.77011 2.90849 10.2859 2.39272 10.2859 1.75649C10.2859 1.12026 9.77011 0.604492 9.13387 0.604492H1.57387C0.937642 0.604492 0.421875 1.12026 0.421875 1.75649Z"
        fill="white"
      />
    </svg>
  );
};

export default Minus;
