const Culture = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.9531 9.89258V11.0656H29.8528C31.6854 11.0656 33.1763 12.5566 33.1763 14.3892C33.1763 14.4325 33.1755 14.476 33.1739 14.5193C33.1084 16.2432 31.7067 17.6448 29.9827 17.7103C28.2917 17.7761 26.8662 16.5706 26.581 14.9757H28.2887V13.8027H25.3562V14.3892C25.3562 16.8686 27.3733 18.8858 29.8528 18.8858C29.9107 18.8858 29.969 18.8847 30.0273 18.8825C32.3604 18.7939 34.2574 16.897 34.346 14.5635C34.3482 14.5053 34.3493 14.4472 34.3493 14.3893C34.3493 11.9099 32.3322 9.89266 29.8528 9.89266L24.9531 9.89258Z"
        fill="currentColor"
      />
      <path
        d="M9.97031 18.8825C10.0288 18.8846 10.0871 18.8857 10.145 18.8857C12.6245 18.8857 14.6416 16.8686 14.6416 14.3892V13.8026H11.709V14.9757H13.4167C13.1389 16.5295 11.7775 17.7127 10.145 17.7127C10.1018 17.7127 10.0586 17.7119 10.0149 17.7102C8.2911 17.6448 6.88941 16.2431 6.82396 14.5195C6.82232 14.4759 6.82146 14.4324 6.82146 14.3892C6.82146 12.5565 8.31237 11.0656 10.145 11.0656H23.7791V9.89258H10.145C7.66556 9.89258 5.64844 11.9097 5.64844 14.3892C5.64844 14.447 5.64961 14.5051 5.6518 14.5637C5.7404 16.8968 7.63741 18.7938 9.97031 18.8825Z"
        fill="currentColor"
      />
      <path
        d="M13.625 1.86523L14.6408 1.27872L16.5959 4.66484L15.58 5.25135L13.625 1.86523Z"
        fill="currentColor"
      />
      <path
        d="M9.54688 6.37305L10.1334 5.35721L13.5195 7.31225L12.933 8.32808L9.54688 6.37305Z"
        fill="currentColor"
      />
      <path
        d="M26.4805 7.3125L29.8666 5.35747L30.4531 6.3733L27.067 8.32834L26.4805 7.3125Z"
        fill="currentColor"
      />
      <path
        d="M23.4023 4.66504L25.3574 1.27892L26.3732 1.86543L24.4182 5.25155L23.4023 4.66504Z"
        fill="currentColor"
      />
      <path
        d="M38.9622 36.4811V32.6411L32.1267 31.7686L31.9781 32.9321L37.7892 33.674V36.4811H26.7237V37.6541H38.8058V38.8272H9.67578V40.0002H39.9788V36.4811H38.9622Z"
        fill="currentColor"
      />
      <path
        d="M1.19255 37.6542H25.5523V36.4812H20.5865V35.2526C20.5865 33.4197 22.0741 31.9285 23.9028 31.9285C24.0446 31.9285 24.1886 31.9377 24.3309 31.9558L30.8162 32.7837L30.9648 31.6202L30.7527 31.5931V20.0198H29.5796V31.4433L27.9374 31.2336V21.8966C27.9374 20.7108 26.9726 19.7461 25.7869 19.7461C24.6011 19.7461 23.6363 20.7108 23.6363 21.8966V30.7637C23.1121 30.7947 22.6116 30.9158 22.1505 31.1124V21.8966C22.1505 20.7108 21.1857 19.7461 20 19.7461C18.8142 19.7461 17.8494 20.7108 17.8494 21.8966V31.1124C17.3884 30.9158 16.8879 30.7946 16.3636 30.7637V21.8966C16.3636 20.7108 15.3988 19.7461 14.2131 19.7461C13.0273 19.7461 12.0625 20.7108 12.0625 21.8966V31.2336L10.4203 31.4433V20.0198H9.24729V31.593L1.03615 32.6412V36.4812H0.0195312V40.0002H8.50438V38.8272H1.19255V37.6542ZM24.8094 21.8966C24.8094 21.3576 25.2478 20.9191 25.7869 20.9191C26.3259 20.9191 26.7644 21.3576 26.7644 21.8966V31.0839L24.8094 30.8343V21.8966ZM19.0225 21.8966C19.0225 21.3576 19.4609 20.9191 20 20.9191C20.539 20.9191 20.9775 21.3576 20.9775 21.8966V31.8446C20.5881 32.1806 20.2562 32.5818 20 33.0318C19.7438 32.5818 19.4119 32.1806 19.0225 31.8446V21.8966ZM13.2356 21.8966C13.2356 21.3576 13.674 20.9191 14.2131 20.9191C14.7521 20.9191 15.1906 21.3576 15.1906 21.8966V30.8343L13.2356 31.0839V21.8966ZM2.20917 33.6741L15.669 31.9558C15.8112 31.9376 15.9552 31.9284 16.0971 31.9284C17.9258 31.9284 19.4135 33.4196 19.4135 35.2525V36.4812H2.20917V33.6741Z"
        fill="currentColor"
      />
      <path d="M19.4141 0H20.5871V3.91007H19.4141V0Z" fill="currentColor" />
      <path
        d="M21.7578 13.8027H22.9308V14.9758H21.7578V13.8027Z"
        fill="currentColor"
      />
      <path
        d="M17.0664 13.8027H18.2394V14.9758H17.0664V13.8027Z"
        fill="currentColor"
      />
      <path
        d="M19.4141 13.8027H20.5871V14.9758H19.4141V13.8027Z"
        fill="currentColor"
      />
      <path
        d="M29.6172 13.8027H30.7902V14.9758H29.6172V13.8027Z"
        fill="currentColor"
      />
      <path
        d="M9.20703 13.8027H10.3801V14.9758H9.20703V13.8027Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Culture;
