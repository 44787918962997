import Title from 'components/Typography/Title';
import Clock from 'Icons/Clock';
import Fb from 'Icons/Fb';
import Insta from 'Icons/Insta';
import Message from 'Icons/Message';
import Phone from 'Icons/Phone';
import Send from 'Icons/Send';
import Twitter from 'Icons/Twitter';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Contact,
  Contacts,
  Header,
  Hour,
  Hours,
  Social,
  Wrapper,
} from './OpenHours.styles';
import { getPhoneNumbeWithCountryCode } from 'utils/helpers';

function OpenHours({ hours, contacts, socials }) {
  const mapKeyToDay = (key) => {
    switch (key) {
      case 0:
        return 'monday';
      case 1:
        return 'tuesday';
      case 2:
        return 'wednesday';
      case 3:
        return 'thursday';
      case 4:
        return 'friday';
      case 5:
        return 'saturday';
      case 6:
        return 'sunday';
      default:
        return null;
    }
  };
  const getHasHours = (hours) => {
    const hoursArr = Object.values(hours);
    hoursArr.pop();
    const hasHours = hoursArr.some(
      (hour) => hour?.opens !== null && hour?.closes !== null,
    );

    return hasHours;
  };

  const { t } = useTranslation();
  return (
    <Wrapper>
      {hours && getHasHours(hours) && (
        <Box>
          <Header>
            <Clock />
            <Title level="h2" size="md">
              {t('openHours')}
            </Title>
          </Header>
          <Hours>
            {Object.keys(hours).map(
              (key) =>
                hours[key]?.opens &&
                hours[key]?.closes && (
                  <Hour>
                    <p>{mapKeyToDay(parseInt(key))}</p>
                    {
                      <span>
                        {`${t('from')} ${hours[key]?.opens} 
                  ${t('to')} ${hours[key]?.closes}`}
                      </span>
                    }
                  </Hour>
                ),
            )}
          </Hours>
        </Box>
      )}
      <Contacts>
        {contacts?.url && (
          <Contact>
            <Send />
            {contacts?.url}
          </Contact>
        )}
        {contacts?.email && (
          <Contact>
            <Message />
            {contacts?.email}
          </Contact>
        )}
        {contacts?.phone && (
          <Contact>
            <Phone />
            {getPhoneNumbeWithCountryCode(contacts?.phone)}
          </Contact>
        )}
        {socials && (
          <Contact>
            <Social href={socials.fb}>
              <Fb />
            </Social>
            <Social href={socials.insta}>
              <Insta />
            </Social>
            <Social href={socials.twitter}>
              <Twitter />
            </Social>
          </Contact>
        )}
      </Contacts>
    </Wrapper>
  );
}

OpenHours.propTypes = {};

export default OpenHours;
