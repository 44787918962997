import Title from 'components/Typography/Title';
import tw, { styled } from 'twin.macro';

export const ProgramCardWrapper = styled.div`
  ${tw`relative w-full h-[15.188rem] lg:h-[18.188rem] cursor-pointer`}
  &:after {
    content: '';
    ${tw`absolute bottom-0 w-full h-[8.313rem] rounded-b-[1.75rem]`};
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    opacity: 0.6;
  }
`;

export const ProgramImage = tw.img`
  w-full h-full object-cover rounded-[1.75rem]
`;

export const ProgramTag = styled.button(({ isFav }) => [
  tw`absolute z-20 right-6 top-6 flex justify-center items-center
  text-white bg-[#525252] w-10 h-10 rounded-full cursor-pointer `,
  isFav && tw`text-white bg-pink`,
]);

export const ProgramNumber = tw.div`
min-w-[2.375rem] min-h-[2.5rem] rounded-full bg-[#4C7846] flex items-center justify-center
text-white font-medium text-base leading-[1.45rem]
`;

export const ProgramDiscp = tw.div`
absolute bottom-6 left-6 right-6 z-20 flex items-center gap-2
`;

export const ProgramTitle = styled(Title)`
  ${tw` text-white`}
`;
