import { Listbox } from '@headlessui/react';
import tw, { styled } from 'twin.macro';

export const ListButton = styled(Listbox.Button)`
  ${tw`relative rounded-full bg-white w-[3.25rem] h-[3.25rem] lg:(w-[3.75rem] h-[3.75rem])
     flex justify-center items-center transition-all duration-150`}
  ${({ $isOpen }) => $isOpen && tw`bg-[#FCC433] border-[0.5px] border-white `}
  &:after {
    content: ${({ lang }) => `"${lang.toString()}"`};
    ${tw`absolute z-10 top-0 right-0 w-6 h-6 rounded-full text-white bg-orange capitalize
        flex justify-center items-center text-center font-bold text-[10px]
        border-2 [border-color: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.3) 100%)]`}
  }
`;
export const Icon = tw.img`
  w-6 h-6 object-cover
`;

export const Dropdown = tw.div`
  absolute top-[4.75rem] left-0 z-50 w-48 max-h-[15rem] overflow-hidden 
  rounded-2xl bg-white lg:(left-[unset] right-0)
`;

export const Options = styled(Listbox.Options)`
  ${tw` w-full h-full  overflow-auto`}
`;

export const Option = styled(Listbox.Option)`
  ${tw`w-full capitalize text-lg font-bold px-6
      bg-['#D9D9D9'] py-4 cursor-pointer`}
  &:hover {
    background-color: ${({ $isActive }) =>
      $isActive ? tw`bg-orange` : 'rgba(217, 217, 217, 0.2)'};
  }
  background-color: ${({ $isActive }) => $isActive && tw`bg-orange text-white`};
`;
