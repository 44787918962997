import Button from 'components/Button';
import tw, { styled } from 'twin.macro';

export const SavePanel = tw.div`
first-letter:capitalize relative flex flex-col justify-center items-center gap-4
bg-[#F9FCFD] rounded-3xl px-6 py-12 text-center md:(px-16 py-[4.5rem] )
`;
export const CloseButton = styled(Button)`
  ${tw`absolute -top-4 -right-4`}
`;

export const SavePanelMobile = tw.div`
  bg-[#F9FCFD]  w-full py-8 px-4 rounded-3xl
 flex flex-col justify-center items-center gap-8  md:hidden
`;

export const Input = tw.input`
  w-full bg-[#EEEEEE] py-4 px-6 border-[1px] border-[rgba(0, 0, 0, 0.2)] 
  text-black text-base font-medium rounded-full outline-orange
`;

export const ErrorMessage = tw.p`
   text-red-500 text-base font-medium capitalize
`;

export const MobileInputWrapper = tw.div`
 w-full flex justify-center items-center gap-2
`;

export const MobileSaveButton = tw.button`
   w-[3.9rem] h-[3.9rem] rounded-full bg-orange flex justify-center items-center shrink-0
`;

export const SavedIcon = styled.div`
  ${tw`relative w-14 h-14 flex justify-center items-center rounded-full bg-pink`}
  &:before {
    content: '✓';
    ${tw`absolute left-0 top-0 flex justify-center items-center
        w-[22px] h-[22px] rounded-full bg-green text-white text-center`}
  }
`;
