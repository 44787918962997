import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'twin.macro';

export const Wrapper = tw.div`
   w-full flex justify-center items-center gap-1.5
   bg-[#EBEBEB] p-1.5 rounded-[1.31rem]
`;

export const SwitchItem = styled(NavLink)`
  ${tw`w-full flex justify-center items-center p-5 rounded-2xl 
  bg-[#DDDDDD] text-[#7F7872] text-base font-bold`}
  &.active {
    ${tw`bg-orange text-white`}
  }
`;
