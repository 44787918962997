import { useTranslation } from 'react-i18next';
import { SwitchItem, Wrapper } from './Switch.styles';

const Switch = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <SwitchItem to="trip-planer/1">{t('planTrip')}</SwitchItem>
      <SwitchItem to="suggestion">{t('programsSuggestion')}</SwitchItem>
    </Wrapper>
  );
};

export default Switch;
