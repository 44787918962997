const PinIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <g clip-path="url(#clip0_1819_2926)">
        <path
          d="M10.7123 10.129L7 13.8413L3.28767 10.129C2.55345 9.39478 2.05344 8.45932 1.85087 7.44092C1.64831 6.42252 1.75228 5.36692 2.14964 4.40761C2.547 3.44831 3.21991 2.62837 4.08327 2.0515C4.94662 1.47462 5.96165 1.16672 7 1.16672C8.03835 1.16672 9.05338 1.47462 9.91674 2.0515C10.7801 2.62837 11.453 3.44831 11.8504 4.40761C12.2477 5.36692 12.3517 6.42252 12.1491 7.44092C11.9466 8.45932 11.4466 9.39478 10.7123 10.129ZM7 8.75C7.61884 8.75 8.21233 8.50417 8.64992 8.06659C9.0875 7.629 9.33334 7.03551 9.33334 6.41667C9.33334 5.79783 9.0875 5.20434 8.64992 4.76676C8.21233 4.32917 7.61884 4.08334 7 4.08334C6.38116 4.08334 5.78767 4.32917 5.35009 4.76676C4.9125 5.20434 4.66667 5.79783 4.66667 6.41667C4.66667 7.03551 4.9125 7.629 5.35009 8.06659C5.78767 8.50417 6.38116 8.75 7 8.75ZM7 7.58334C6.69058 7.58334 6.39384 7.46042 6.17505 7.24163C5.95625 7.02284 5.83334 6.72609 5.83334 6.41667C5.83334 6.10725 5.95625 5.81051 6.17505 5.59171C6.39384 5.37292 6.69058 5.25001 7 5.25001C7.30942 5.25001 7.60617 5.37292 7.82496 5.59171C8.04375 5.81051 8.16667 6.10725 8.16667 6.41667C8.16667 6.72609 8.04375 7.02284 7.82496 7.24163C7.60617 7.46042 7.30942 7.58334 7 7.58334Z"
          fill="#898989"
        />
      </g>
      <defs>
        <clipPath id="clip0_1819_2926">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PinIcon;
