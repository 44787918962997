import Button from 'components/Button';
import tw, { styled } from 'twin.macro';

export const Wrapper = styled.div`
  ${tw` w-full block fixed top-0 left-0 h-[25rem] z-20 md:hidden`}
`;

export const Image = tw.img`
  w-full h-[25rem] object-cover 
`;

export const ShadowDrop = styled.div`
  ${tw`absolute bottom-0 w-full h-44  opacity-60`};
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 42.19%);
`;

export const BackButton = styled(Button)`
  ${tw`absolute top-4 left-4 z-10`}
`;

export const Infos = tw.div`
  absolute left-4 bottom-10 flex flex-col justify-start gap-2 
  w-[80%] text-white z-30
`;

export const Type = tw.span`
   capitalize text-orange text-lg 
`;

export const AddFav = styled.button(({ isFav }) => [
  tw`absolute z-20 right-6 top-6 flex justify-center items-center
  text-white bg-[#525252] w-10 h-10 rounded-full cursor-pointer `,
  isFav && tw`text-white bg-pink`,
]);
