import { useEffect, useState } from 'react';

function useBrekpoint() {
  const [isSmallDevice, setIsSmallDevice] = useState(true);
  const [heigth, setHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
      if (window.innerWidth < 768) {
        setIsSmallDevice(true);
      } else {
        setIsSmallDevice(false);
      }
    };
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { isSmallDevice, heigth };
}

export default useBrekpoint;
