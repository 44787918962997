const Walk = () => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.7442 11.322C27.0239 10.0423 27.0239 7.96749 25.7442 6.68781C24.4645 5.40812 22.3897 5.40812 21.11 6.68781C19.8304 7.96749 19.8304 10.0423 21.11 11.322C22.3897 12.6016 24.4645 12.6016 25.7442 11.322Z"
        fill="currentColor"
      />
      <path
        d="M12.5972 33.446L15.0067 32.9321C17.1821 32.4639 19.0035 30.9679 19.8771 28.9239L20.4537 27.5707L22.178 28.1588C23.3656 28.5585 24.2849 29.5177 24.6332 30.7224L25.2784 32.9149C25.5125 33.7314 26.2547 34.2738 27.0826 34.2738C27.1569 34.2738 27.2311 34.2681 27.311 34.2567C27.8534 34.1939 28.3387 33.897 28.6471 33.446C28.9554 32.9949 29.0468 32.4353 28.9097 31.9043L28.2074 29.2379C27.7621 27.5364 26.74 26.012 25.3469 24.9443L23.2857 23.3684L24.0736 19.0119C25.8779 20.131 28.1617 20.1653 30.0288 19.0405L30.3942 18.8236C31.1307 18.3782 31.3706 17.4132 30.9252 16.6767C30.5084 15.9801 29.612 15.7232 28.8868 16.0829C27.9391 16.5511 26.7743 16.2941 26.1177 15.4663L25.7922 15.0552C25.0671 14.1416 24.028 13.5421 22.8746 13.3708L20.4023 13.0054C19.5459 12.8798 18.6666 12.994 17.873 13.348L17.4733 13.5193C14.5157 14.8268 12.6601 17.87 12.8599 21.096C12.8827 21.5071 13.0826 21.8896 13.4023 22.1522C13.722 22.4092 14.1331 22.5291 14.5442 22.472C15.2522 22.3692 15.7889 21.7754 15.8175 21.056C15.8689 19.6172 16.634 18.3097 17.8158 17.5445L15.6234 28.0275L11.4611 29.6604C10.6959 29.9573 10.1992 30.6824 10.1992 31.5047C10.1992 32.1042 10.4675 32.6694 10.9358 33.0463C11.4039 33.4231 12.0092 33.5715 12.5972 33.446Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Walk;
