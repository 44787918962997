import NearbyCard from 'components/NearbyList/NearbyCard';
import SideCloseBtn from 'components/SideCloseBtn';
import useBrekpoint from 'hooks/useBrekpoint';
import ChevronDown from 'Icons/ChevronDown';
import { useCallback, useEffect, useRef } from 'react';
import { useMap } from 'react-map-gl';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import useFavorites from 'store/useFavorites';
import { CloseBtn, Content, Wrapper } from './SimilarPoi.styles';

export default function SimilarPoi() {
  const { main_map } = useMap();
  const pageRef = useRef(null);
  const { toggleFavorites, isFavorites } = useFavorites();
  const { isSmallDevice } = useBrekpoint();

  const navigate = useNavigate();
  const location = useLocation();
  const pois = location.state?.pois;

  const animateTo = useCallback(
    ({ longitude, latitude, left }) => {
      main_map?.flyTo({
        pitch: 0,
        bearing: 0,
        center: [longitude, latitude],
        curve: 1.4,
        duration: 2000,
        zoom: 20,
        padding: !isSmallDevice && { left },
      });
    },
    [main_map, isSmallDevice],
  );

  useEffect(() => {
    const firstPoi = pois[0];
    if (firstPoi.location?.coordinates) {
      const [longitude, latitude] = firstPoi.location?.coordinates;
      animateTo({ longitude, latitude, left: isSmallDevice ? 0 : 250 });
    }
  }, [animateTo, pois, main_map, isSmallDevice]);

  const handleClose = () => {
    pageRef?.current?.classList.add('animate__fadeOutDown');
    setTimeout(() => {
      navigate('/');
    }, 600);
  };

  return pois ? (
    <Wrapper
      ref={pageRef}
      className={`animate__animated ${
        isSmallDevice ? 'animate__fadeInUp' : 'animate__fadeInLeft'
      } `}
    >
      <SideCloseBtn page={pageRef} />
      <CloseBtn onClick={handleClose}>
        <ChevronDown />
      </CloseBtn>
      <Content>
        {pois.map((poi) => (
          <NearbyCard
            key={poi.id}
            id={poi.id}
            title={poi.title}
            image={poi.media ? poi.media[0]?.url : null}
            handleFav={() => toggleFavorites(poi)}
            isFavorites={isFavorites(poi.id)}
          />
        ))}
      </Content>
    </Wrapper>
  ) : (
    <Navigate to="/*" replace={true} />
  );
}
