import i18next from 'i18next';
import Marker from 'Icons/Marker';
import PropTypes from 'prop-types';
import usePlaces from 'store/usePlaces';
import { getcityName, getRegionColor } from 'utils/helpers';
import { AbsTitle, Image, Tag, Wrapper } from './PopPlaceCard.styles';
function PopPlaceCard({ image, title, region, id, ...rest }) {
  const { cities } = usePlaces();
  return (
    <Wrapper {...rest} to={`/poi/${id}`}>
      <Image src={image} />
      {region && (
        <Tag color={getRegionColor(region)}>
          <Marker />
          {getcityName(cities, region)[i18next.language]}
        </Tag>
      )}
      {title && (
        <AbsTitle level="h2" size="sm">
          {title}
        </AbsTitle>
      )}
    </Wrapper>
  );
}

PopPlaceCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  region: PropTypes.string,
};

export default PopPlaceCard;
