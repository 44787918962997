import PropTypes from 'prop-types';
import { sizes, StyledTitle } from './Title.styles';

function Title({ children, level, size, ...rest }) {
  return (
    <StyledTitle as={level} size={size} {...rest}>
      {children}
    </StyledTitle>
  );
}

Title.propTypes = {
  level: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(sizes)),
};

export default Title;
