import Button from 'components/Button';
import StepCard from 'components/Program/StepCard';
import FilterTags from 'components/FilterTags';

import getDistance from 'geolib/es/getPreciseDistance';
import i18next from 'i18next';
import ArrowLeft from 'Icons/ArrowLeft';
import React, { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import usePlaces from 'store/usePlaces';
import { Content, FilterWrapper, StepsList, Wrapper } from './AddStep.styles';
import { DO_TAGS, EAT_TAGS, SEE_TAGS, STEPS_TAGS } from 'utils/constants';
import { orderByDistance } from 'geolib';
import useProgram from 'store/useProgram';
import useFavorites from 'store/useFavorites';
import placeholder from 'assets/placeholder.svg';
import useStepScroll from 'store/useStepScroll';

export default function AddStep() {
  const { allPlaces, cities } = usePlaces();
  const { program, addStepToProgram, replaceEmptyProgramStep } = useProgram();
  const { setScrollId } = useStepScroll();

  const { programs, addStepToFavorites, replaceEmptyFavStep } = useFavorites();
  const [activeFilter, setActiveFilter] = useState('all');

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const name = location.state?.name;
  const dayIndex = location?.state?.dayIndex;
  const isFirst = location?.state?.isFirst;
  const getTagsArr = useCallback(() => {
    switch (activeFilter) {
      case 'do':
        return DO_TAGS;
      case 'see':
        return SEE_TAGS;
      case 'eat':
        return EAT_TAGS;
      default:
        return DO_TAGS;
    }
  }, [activeFilter]);

  const { poi, nearby } = useMemo(() => {
    const poi =
      dayIndex || dayIndex === 0
        ? cities.find((city) => params.id === city.slug)
        : allPlaces.find((place) => params.id === place.id);
    let filtered = [];

    const arr = name ? programs[name] : program;
    if (activeFilter === 'all') {
      filtered = allPlaces?.filter((place) => {
        const slug = poi?.contact?.address?.city || poi?.slug;
        return (
          slug === place.contact?.address?.city &&
          arr?.flat().every((programPlace) => place.id !== programPlace.id)
        );
      });
    } else {
      filtered = allPlaces?.filter((place) => {
        const slug = poi?.contact?.address?.city || poi?.slug;
        return (
          slug === place.contact?.address?.city &&
          place.tags.some((tag) => getTagsArr().includes(tag.slug)) &&
          arr?.flat().every((programPlace) => place.id !== programPlace.id)
        );
      });
    }

    const cordsArr = filtered.map((place) => ({
      latitude: place?.location.coordinates[1],
      longitude: place?.location.coordinates[0],
    }));

    const nearbyCords = orderByDistance(
      {
        latitude: poi?.location?.coordinates[1] || poi?.coordinates?.latitude,
        longitude: poi?.location?.coordinates[0] || poi?.coordinates?.longitude,
      },
      cordsArr,
    )
      .filter(
        //Filtering the duplicates
        (coords, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              coords.latitude === t.latitude &&
              coords.longitude === t.longitude,
          ),
      )
      .slice(0, 6);

    const nearby = nearbyCords.map((cords) =>
      filtered.find(
        (place) =>
          cords.latitude === place?.location.coordinates[1] &&
          cords.longitude === place?.location.coordinates[0],
      ),
    );

    return { poi, nearby };
  }, [
    params.id,
    allPlaces,
    activeFilter,
    program,
    programs,
    getTagsArr,
    name,
    cities,
    dayIndex,
  ]);

  const getPlaceCords = (place) => ({
    latitude: place?.location?.coordinates[1] || place?.coordinates?.latitude,
    longitude: place?.location?.coordinates[0] || place?.coordinates?.longitude,
  });

  const myGetDistance = (place) => {
    const poiCords = getPlaceCords(poi);
    const placeCords = getPlaceCords(place);
    return (getDistance(poiCords, placeCords) * 1.4).toFixed();
  };

  const findNestedIndex = () => {
    let firstIndex;
    let poiIndex;
    const arr = name ? programs[name] : program;

    arr?.forEach((day, i) => {
      const index = day.findIndex((place) => place.id === poi.id);
      if (index > -1) {
        firstIndex = i;
        poiIndex = index;
      }
    });
    return { firstIndex, poiIndex };
  };

  const handleAdd = (place) => {
    setScrollId(place.id);
    if (dayIndex || dayIndex === 0) {
      if (name) {
        replaceEmptyFavStep(dayIndex, place, name);
      } else {
        replaceEmptyProgramStep(dayIndex, place);
      }
    } else {
      const { firstIndex, poiIndex } = findNestedIndex();
      const addToIndex = isFirst ? 0 : poiIndex + 1;
      if (name) {
        addStepToFavorites(firstIndex, addToIndex, place, name);
      } else {
        addStepToProgram(firstIndex, addToIndex, place);
      }
    }

    navigate(location.state?.prevRoute, {
      state: { prevRoute: 'add-step' },
      replace: true,
    });
  };

  const handleClose = () => {
    navigate(-1, { replace: true });
  };

  return (
    <Wrapper>
      <Content>
        <Button onClick={handleClose} variant="square" size="large">
          <ArrowLeft />
        </Button>
        <FilterWrapper>
          <FilterTags
            filters={STEPS_TAGS}
            active={activeFilter}
            setActive={setActiveFilter}
          />
          <StepsList>
            {nearby?.length > 0 &&
              nearby.map((place) => (
                <StepCard
                  key={place.id}
                  id={place.id}
                  poiTitle={poi.title[i18next.language]}
                  title={place.title[i18next.language]}
                  image={place.media[0]?.url || placeholder}
                  distance={myGetDistance(place)}
                  handleAdd={() => handleAdd(place)}
                />
              ))}
          </StepsList>
        </FilterWrapper>
      </Content>
    </Wrapper>
  );
}
