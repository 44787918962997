import { create } from 'zustand';

const useServiceWorker = create((set) => ({
  initialized: false,
  updated: false,
  registration: null,
  setInitialized: (value) => set({ initialized: value }),
  setUpdated: (value) => set({ updated: value }),
  setRegistration: (registration) => set({ registration }),
}));

export default useServiceWorker;
