import tw, { styled } from 'twin.macro';

export const SearchInputWrapper = tw.div`
  relative w-full
`;
export const StyledInput = styled.input(({ $isFocused }) => [
  tw`w-full outline-none rounded-full h-[3.25rem] pl-6 pr-14 transition-all duration-1000 
  lg:(w-[14.25rem]  h-[3.75rem] pr-16)`,
  $isFocused && tw`w-full lg:w-[30.625rem]`,
  `border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 16px 18px rgba(0, 0, 0, 0.11);`,
]);

export const InputButton = tw.span`
     absolute w-11 h-11 bg-[#EB672C] rounded-[1.875rem] flex items-center justify-center 
     right-1 top-1/2 -translate-y-1/2 
     lg:(w-[3.25rem] h-[3.25rem])
     [box-shadow: 0px 0px 30px 15px rgba(0, 0, 0, 0.02)]
  `;

export const RedPoint = tw.span`
    absolute -top-1.5 right-2 w-3.5 h-3.5 bg-[#EA4235] rounded-full
  `;
