import CheckboxList from 'components/CreatePlan/CheckboxList';
import DestinationDates from 'components/CreatePlan/DestinationDates';
import Title from 'components/Typography/Title';
import React from 'react';
import { useTranslation } from 'react-i18next';
import usePlaces from 'store/usePlaces';
import useTripForm from 'store/useTripForm';
import { Wrapper } from './Step1.styles';

export default function Step1() {
  const { cities } = usePlaces();
  const {
    destinations,
    handleDestinations,
    updateDestinations,
    addDay,
    removeDay,
  } = useTripForm();

  const { t } = useTranslation();
  const alldestinitions = cities.map((city) => ({
    name: city.title,
    slug: city.slug,
  }));

  return (
    <Wrapper>
      <Title level="h3" size="md">
        {t('destinition')}
      </Title>
      <CheckboxList
        handleChange={handleDestinations}
        checkedResults={destinations}
        items={alldestinitions}
        name="destinations"
      />
      {destinations?.length > 0 && (
        <DestinationDates
          destinations={destinations}
          orderList={updateDestinations}
          handleMinus={removeDay}
          handlePlus={addDay}
        />
      )}
    </Wrapper>
  );
}
