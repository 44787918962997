import AddStep from 'pages/AddStep';
import CreateProgram from 'pages/CreateProgram';
import Step1 from 'pages/CreateProgram/Step1';
import Step2 from 'pages/CreateProgram/Step2';
import Favorites from 'pages/Favorites';
import Home from 'pages/Home';
import InterestPoint from 'pages/InterestPoint';
import ProgramResult from 'pages/ProgramResult';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { MapProvider } from 'react-map-gl';
import Program from 'pages/Program';
import Share from 'pages/Share';
import NotFound from 'pages/NotFound';
import useTripForm from 'store/useTripForm';
import SimilarPoi from 'pages/SimilarPoi';
import TripPlaner from 'pages/CreateProgram/TripPlaner';
import Suggestions from 'pages/CreateProgram/Suggestions';
import SuggestionPreview from 'pages/SuggestionPreview';
import Print from 'pages/Print';

const queryClient = new QueryClient();

function App() {
  const { destinations } = useTripForm();
  return (
    <QueryClientProvider client={queryClient}>
      <MapProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/print/:name" element={<Print />} />
            <Route path="/" element={<Home />}>
              <Route path="poi/:id" element={<InterestPoint />} />
              <Route path="similar-poi" element={<SimilarPoi />} />
              <Route path="favorites" element={<Favorites />} />
              <Route path="create-program" element={<CreateProgram />}>
                <Route path="trip-planer" element={<TripPlaner />}>
                  <Route path="1" element={<Step1 />} />
                  <Route path="2" element={<Step2 />} />
                </Route>
                <Route path="suggestion" element={<Suggestions />} />
              </Route>
              <Route
                path="suggestion-previw/:id"
                element={<SuggestionPreview />}
              />
              <Route
                path="program-result"
                element={
                  destinations?.length > 0 ? (
                    <ProgramResult />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route path="program/:name" element={<Program />} />
              <Route path="shared/:link" element={<Share />} />
              <Route path="add-step/:id" element={<AddStep />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </MapProvider>
    </QueryClientProvider>
  );
}

export default App;
