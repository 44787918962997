import tw, { styled } from 'twin.macro';

export const Wrapper = tw.div`
  w-full flex flex-col gap-6 justify-center items-center
`;

export const Header = tw.div`
  w-full flex justify-between items-center
`;

export const NavButtons = tw.div`
  flex justify-center items-center gap-2
`;

export const NavButton = tw.button`
  w-12 h-12 rounded-xl flex justify-center items-center
  bg-blue disabled:bg-[#CCCCCC] text-white
`;

export const Card = styled.a(({ isActive }) => [
  tw`relative flex flex-col justify-between items-start cursor-pointer
     bg-[#BFBFBF] text-white capitalize p-3 rounded-2xl w-32 h-40`,
  isActive && tw`bg-green`,
]);

export const DayWrapper = tw.div`
  flex flex-col gap-0.5
`;
export const Region = tw.span`
  text-sm font-medium w-28 text-ellipsis whitespace-nowrap overflow-hidden
`;
export const Day = tw.p`
  text-lg font-medium
`;

export const Distance = styled.div`
  ${tw`w-full flex justify-between items-end gap-2 text-base font-bold leading-[21.78px]`}
  span {
    ${tw`text-[10px] font-semibold`}
  }
`;

export const Road = tw.img`
  absolute left-1.5 top-10
`;
