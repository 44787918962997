import React, { forwardRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BoxContent,
  Devider,
  LabelDescription,
  MarkerWrapper,
  SearchBox,
  SearchItem,
  SearchWrapper,
  StartPointWrapper,
  Time,
  TimeLabel,
} from './StartPoint.styles';
import Title from 'components/Typography/Title';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import { searchPlaces } from 'services';
import { useQuery } from 'react-query';
import useDebounce from 'hooks/useDebounce';
import { format } from 'date-fns';
import SearchMarker from 'Icons/SearchMarker';
import Geo from 'Icons/Goe';
import SearchInput from './SearchInput';

const StartPoint = forwardRef(
  (
    { startingPoint, handleUseMyLocation, handleChooseLocation, isAtTop },
    ref,
  ) => {
    const { t } = useTranslation();
    const searchWrapperRef = useRef(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState(null);
    const debouncedSearchTerm = useDebounce(searchTerm, 400);
    const { data } = useQuery(['search', debouncedSearchTerm], () =>
      searchPlaces(debouncedSearchTerm),
    );

    useEffect(() => {
      if (data) {
        setResults(data);
      } else {
        setResults(null);
      }
    }, [data, setResults]);

    const handleChoose = (placeInfos) => {
      handleChooseLocation(placeInfos);
      setSearchTerm('');
    };

    const getDate = () => {
      return format(new Date(), 'MMMM do. HH:mm a z');
    };

    useEffect(() => {
      let searchWrapper = searchWrapperRef.current;
      const handleClick = (e) => {
        if (searchWrapper && searchWrapper !== e.target) {
          if (!searchWrapper.contains(e.target)) {
            setResults(true);
          }
        }
      };
      document.addEventListener('click', (e) => handleClick(e));

      return () => {
        document.removeEventListener('click', (e) => handleClick(e));
      };
    }, []);

    return (
      <StartPointWrapper ref={ref} isAtTop={isAtTop}>
        <Title level="h1" size="lg">
          {t('enterStartingPoint')}
        </Title>
        <SearchWrapper ref={searchWrapperRef}>
          <SearchInput
            type="search"
            size="sm"
            value={searchTerm}
            placeholder={t('searchForLocation')}
            onChange={(e) => setSearchTerm(e.target.value)}
            isFocused={true}
          />
          {results?.length > 0 && (
            <SearchBox>
              <BoxContent>
                {results.map((item) => (
                  <SearchItem
                    key={item.place_id}
                    onClick={() =>
                      handleChoose({
                        latitude: item.lat,
                        longitude: item.lon,
                        date: getDate(),
                        type: 'place',
                      })
                    }
                  >
                    <MarkerWrapper>
                      <SearchMarker />
                    </MarkerWrapper>
                    {item.display_name}
                  </SearchItem>
                ))}
              </BoxContent>
            </SearchBox>
          )}
        </SearchWrapper>
        {startingPoint && startingPoint.type === 'place' && (
          <Time>
            <LabelDescription>{t('usingYourLocationFrom')}</LabelDescription>
            <TimeLabel>
              <Title level="p" size="md">
                {startingPoint.date}
              </Title>
            </TimeLabel>
          </Time>
        )}
        <Devider>{t('or')}</Devider>
        <Title level="h2" size="lg">
          {t('useCurrentLocaction')}
        </Title>
        <LabelDescription>{t('asStartingPoint')}</LabelDescription>
        <Button
          variant={
            startingPoint && startingPoint.type === 'user'
              ? 'secondary'
              : 'primary'
          }
          size="large"
          className="w-full"
          onClick={handleUseMyLocation}
        >
          {!startingPoint || (startingPoint.type === 'place' && <Geo />)}
          {startingPoint && startingPoint.type === 'user'
            ? t('updateLocation')
            : t('useMyLocation')}
        </Button>

        {startingPoint && startingPoint.type === 'user' && (
          <Time>
            <LabelDescription>{t('usingYourLocationFrom')}</LabelDescription>
            <TimeLabel>
              <Title level="p" size="md">
                {startingPoint.date}
              </Title>
            </TimeLabel>
          </Time>
        )}
      </StartPointWrapper>
    );
  },
);

StartPoint.propTypes = {
  title: PropTypes.string,
  DeviderLabel: PropTypes.string,
  title1: PropTypes.string,
  searchProps: PropTypes.shape({
    value: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
  }),
  buttonProps: PropTypes.shape({
    buttonLabel: PropTypes.string,
    btnClick: PropTypes.func,
  }),
};

StartPoint.defaultProps = {
  searchProps: {
    value: '',
    placeholder: 'Search for startingPoint',
    onChange: undefined,
    onClick: undefined,
  },
  buttonProps: {
    buttonLabel: 'Use My startingPoint',
    btnClick: undefined,
  },
};
export default StartPoint;
