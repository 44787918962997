import { Link } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

export const Wrapper = styled(Link)`
  ${tw`flex items-start justify-start gap-3 md:gap-4 w-full h-[7.9rem]
 border-t-[1px] border-[#D9D9D9] pt-4  first:border-0 first:pt-0 outline-transparent`}
`;

export const Image = tw.img`
   w-[7.25rem] md:w-[8.75rem] h-[8rem]  object-cover rounded-3xl shrink-0
`;

export const Infos = tw.div`
  flex flex-col items-start gap-1.5 md:gap-2
`;

export const Breadcrumbs = tw.ul`
   flex gap-x-4 gap-y-1 justify-start items-center flex-wrap
`;

export const Item = styled.li`
  ${tw`flex justify-center items-center gap-1.5 whitespace-nowrap
       capitalize text-[10px] md:text-sm text-[#6C6C6C] font-medium`}
  &:after {
    content: '';
    ${tw`bg-[#D9D9D9] w-0.5 h-4 ml-2`}
  }
  &:last-child:after {
    display: none;
  }
`;
export const IconWrapper = tw.span`
 shrink-0 flex justify-center items-center w-2.5 h-2.5
`;

export const Region = styled.span`
  color: ${({ regionColor }) => regionColor};
`;

export const Description = styled.p`
  ${tw`text-[0.938rem] text-[#BBBBBB] text-ellipsis overflow-hidden`};
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  @media (max-width: 480px) {
    -webkit-line-clamp: 2;
  }
`;
