import Button from 'components/Button';

import { useTranslation } from 'react-i18next';
import { Wrapper } from './updatePopup.styles';
import useServiceWorker from 'store/useServiceWorker';

const UpdatePopup = () => {
  const { registration, setUpdated } = useServiceWorker();
  const updateServiceWorker = () => {
    const registrationWaiting = registration.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
      registrationWaiting.addEventListener('statechange', (e) => {
        if (e.target.state === 'activated') {
          setUpdated(false);
          window.location.reload();
        }
      });
    }
  };
  const { t } = useTranslation();

  return (
    <Wrapper>
      <p>{t('newVersionAvailable')}</p>
      <Button variant="primary" size="small" onClick={updateServiceWorker}>
        {t('update')}
      </Button>
    </Wrapper>
  );
};

export default UpdatePopup;
