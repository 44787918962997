import { CloseBtn, Description, Video, Wrapper } from './ClusterPopup.styles';
import close from 'assets/close.png';
import gif from './gif.mp4';

import Title from 'components/Typography/Title';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const ClusterPopup = ({ handleClose, ...rest }) => {
  const { t } = useTranslation();

  useEffect(() => {
    const timeoutId = setTimeout(() => handleClose(), 10000);
    return () => clearTimeout(timeoutId);
  }, [handleClose]);

  return (
    <Wrapper className="animate__animated animate__fadeIn" {...rest}>
      <CloseBtn onClick={handleClose}>
        <img src={close} alt="close-button" />
      </CloseBtn>

      <Video muted autoPlay playsInline loop>
        <source src={gif} type="video/mp4" />
      </Video>
      <Title level="h2" size="lg" className="w-full">
        {t('clustersTitle')}
      </Title>
      <Description>{t('clustersDescription')}</Description>
    </Wrapper>
  );
};

export default ClusterPopup;
