import Fuse from 'fuse.js';
import useDebounce from 'hooks/useDebounce';
import { useEffect, useMemo, useRef, useState } from 'react';
import useSearchFilter from 'store/useSearchFilter';
import { Wrapper } from './Search.styles';
import SearchInput from './SearchInput';
import SearchResult from './SearchResult';
let fuse = null;

export default function Search({ places }) {
  const { activeSearchFilters, searchFilters, setActiveFilters } =
    useSearchFilter();
  const searchWrapperRef = useRef(null);
  const searchBox = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [result, setResult] = useState(null);
  const debouncedSearchTerm = useDebounce(searchTerm, 400);
  const options = useMemo(
    () => ({
      keys: [
        ['title', 'en'],
        ['title', 'fr'],
      ],
      minMatchCharLength: 1,
      threshold: 0.2,
    }),
    [],
  );

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (places) {
      fuse = new Fuse(places, options);
    }
  }, [places, options]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      const items = fuse.search(debouncedSearchTerm).map((r) => r.item);
      setResult(items);
    } else if (debouncedSearchTerm === '') {
      setResult(null);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    let searchWrapper = searchWrapperRef.current;
    const handleClick = (e) => {
      if (searchWrapper && searchWrapper !== e.target) {
        if (!searchWrapper.contains(e.target)) {
          setIsOpen(false);
        }
      }
    };
    document.addEventListener('click', (e) => handleClick(e), {
      capture: true,
    });

    return () => {
      document.removeEventListener('click', (e) => handleClick(e), {
        capture: true,
      });
    };
  }, []);

  const handleFocus = () => {
    setIsOpen(true);
  };

  return (
    <Wrapper ref={searchWrapperRef} tabIndex="1">
      <SearchInput
        type="text"
        value={searchTerm}
        onChange={(e) => handleChange(e)}
        onFocus={handleFocus}
        filterIsActive={activeSearchFilters.length > 0}
        isFocused={isOpen}
      />

      {isOpen && (
        <SearchResult
          ref={searchBox}
          results={result}
          handleClose={handleClose}
          activeSearchFilters={activeSearchFilters}
          setActiveFilters={setActiveFilters}
          searchFilters={searchFilters}
        />
      )}
    </Wrapper>
  );
}
