import tw, { styled } from 'twin.macro';

export const Wrapper = tw.div`
  w-full flex justify-center items-center fixed z-50 bottom-0 py-2 px-4
  bg-white border-t-[1px] border-[#B8B8B8] md:border-[1px] md:rounded-[19px]
  md:bottom-4 md:left-10 md:p-5 md:w-fit md:max-w-[32.5rem]
`;

export const Card = styled.a(({ isActive }) => [
  tw`flex flex-col justify-center items-start cursor-pointer
     bg-[#BFBFBF] text-white capitalize p-2 rounded-[9px] transition-all duration-500
     md:w-[5rem]
     `,
  isActive && tw`bg-green `,
]);

export const Day = tw.p`
  text-xs tracking-[-0.04em] md:text-base font-bold
`;

export const Distance = tw.p`
 text-lg  font-bold
`;
