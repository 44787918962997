import tw from 'twin.macro';

export const Wrapper = tw.div`
  w-full flex flex-col justify-center items-start gap-4
  md:gap-6
`;

export const List = tw.ul`
 w-full flex flex-col justify-start items-center gap-4
`;
