import { create } from 'zustand';

const useDirections = create(
  (set) => ({
    isDirections: false,
    toggleIsDirection: (value) => set(() => ({ isDirections: value })),
  }),
  {
    name: 'directions',
  },
);

export default useDirections;
