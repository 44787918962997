import Title from 'components/Typography/Title';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Navigation } from 'swiper';
import {
  Card,
  Day,
  DayWrapper,
  Distance,
  Header,
  NavButton,
  NavButtons,
  Region,
  Road,
  Wrapper,
} from './PlanSwiper.styles';
import { forwardRef, useRef } from 'react';
import ChevronLeft from 'Icons/ChevronLeft';
import ChevronRight from 'Icons/ChevronRight';
import road from 'assets/road.svg';
import { useTranslation } from 'react-i18next';

const PlanSwiper = forwardRef(({ days, active, setActive }, ref) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Header>
        <Title level="h2" size="lg">
          {t('allDays')}
        </Title>
        <NavButtons>
          <NavButton ref={prevRef}>
            <ChevronLeft />
          </NavButton>
          <NavButton ref={nextRef}>
            <ChevronRight />
          </NavButton>
        </NavButtons>
      </Header>
      <Swiper
        ref={ref}
        className="w-full h-full"
        modules={[A11y, Navigation]}
        slidesPerView="auto"
        spaceBetween={24}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
      >
        {days?.length !== 0 &&
          days.map((item) => (
            <SwiperSlide key={item.number}>
              <Card
                href={`#${item.number}`}
                onClick={() => setActive(item.number)}
                isActive={active === item.number}
              >
                <Road src={road} />
                <DayWrapper>
                  <Region>{item.region}</Region>
                  <Day>
                    {t('day')} {item.number}
                  </Day>
                </DayWrapper>
                <Distance>
                  <span>{t('distance')}</span>
                  <p>{item.distance}</p>
                </Distance>
              </Card>
            </SwiperSlide>
          ))}
      </Swiper>
    </Wrapper>
  );
});

PlanSwiper.propTypes = {
  days: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.number.isRequired,
      distance: PropTypes.string.isRequired,
    }),
  ),
};

export default PlanSwiper;
