import PropTypes from 'prop-types';
import {
  IconWrapper,
  Marker,
  MarkerWrapper,
  NameWrapper,
  StyledImg,
  TagIcon,
} from './MapMarker.styles';
import Triangle from 'Icons/Triangle';
import { getIcon, getRegionColor } from 'utils/helpers';
import MarkerPusIcon from 'Icons/MarkerPusIcon';

function MapMarker({ image, name, slug, tags, order, ...rest }) {
  return (
    <MarkerWrapper {...rest}>
      {order && (
        <Marker color={getRegionColor(slug)} isImage={image ? true : false}>
          <TagIcon>{order}</TagIcon>
          <IconWrapper>
            <Triangle color={getRegionColor(slug)} />
          </IconWrapper>
        </Marker>
      )}
      {!order && tags && (
        <Marker
          color={getRegionColor(slug)}
          isImage={image ? true : false}
          $isLarge={tags.length > 1}
        >
          {image && <StyledImg src={image} alt={name} />}
          {Array.isArray(tags) &&
            tags.length > 0 &&
            tags.map((tag, i) => (
              <TagIcon key={tag.slug} $isLarge={tags.length > 1}>
                {getIcon(tag.slug)} {i !== tags.length - 1 && <MarkerPusIcon />}
              </TagIcon>
            ))}
          <IconWrapper>
            <Triangle color={getRegionColor(slug)} />
          </IconWrapper>
        </Marker>
      )}
      {!order && name && (
        <NameWrapper
          slug={slug}
          hasTag={tags ? true : false}
          color={getRegionColor(slug)}
        >
          {name}
        </NameWrapper>
      )}
    </MarkerWrapper>
  );
}

MapMarker.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  tags: PropTypes.array,
};

export default MapMarker;
