// src/styles/GlobalStyles.js
import { createGlobalStyle } from 'styled-components';
import tw, { GlobalStyles as BaseStyles } from 'twin.macro';

const CustomStyles = createGlobalStyle`
*, html {
  ${tw`scroll-smooth`}
  ::-webkit-scrollbar {
    ${tw`w-0 md:w-[18px]`}
  }
  ::-webkit-scrollbar-thumb {
    border: 6px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #aaaaaa;
  }
}
  body {
    ${tw`font-primary text-black`}
    
  }
//print rules for the print page (generetaed circuit)

  @page {
    size: A4;
    margin: 6.35mm;
    margin-top:0;
  }
  @media print {
    * {
    -webkit-print-color-adjust: exact;
    }
    html, body {
      width: 210mm;
      height: 297mm;
      margin: 0;
      padding: 0;
    }
  }

  .swiper-slide {
    width: fit-content !important;
    height: fit-content !important;
  }
  
  .swiper-pagination {
    bottom: 2px !important
  }
  .swiper-pagination-bullet {
    background: white;
  }
`;

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <CustomStyles />
  </>
);

export default GlobalStyles;
