import { create } from 'zustand';

const useTags = create(
  (set) => ({
    activeTags: [],
    tags: null,
    preferencesTags: null,

    setTags: (tags) => set({ tags }),
    setPreferencesTags: (tags) => set({ preferencesTags: tags }),
    resetActiveTags: () => set({ activeTags: [] }),
    setActiveTags: (tags) => set({ activeTags: tags }),
  }),
  {
    name: 'tags',
  },
);

export default useTags;
