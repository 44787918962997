const Nature = () => {
  return (
    <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.79902 14.2642L13.467 17.9322L15.7311 15.6682L9.79909 9.73616V6.26416L12.9991 9.46416L15.2632 7.2001L9.33115 1.26809C9.03102 0.967705 8.62372 0.798828 8.19912 0.798828C7.77452 0.798828 7.36722 0.967708 7.06709 1.26809L1.13509 7.2001L3.39915 9.46416L6.59915 6.26416V9.74016L0.667152 15.6682L2.93122 17.9322L6.59922 14.2642V21.6002H0.199219V24.8002H25.7992V21.6002H9.79922L9.79902 14.2642Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Nature;
