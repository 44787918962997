const Geo = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.29407 5.53236L15.3102 0.862135C16.8866 0.296525 18.4184 1.80306 17.8792 3.38864L13.3533 16.6951C12.713 18.5778 10.0156 18.4736 9.52235 16.5471L8.31552 11.8332C8.13098 11.1124 7.56158 10.5537 6.83743 10.3827L2.51009 9.36137C0.572374 8.90402 0.420088 6.20475 2.29407 5.53236Z"
        fill="white"
      />
    </svg>
  );
};

export default Geo;
