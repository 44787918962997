import { Days, Wrapper } from './DestinationDates.styles';
import Title from 'components/Typography/Title';
import { useTranslation } from 'react-i18next';
import Destination from '../Destination';
import i18next from 'i18next';

function DestinationDates({ isResult, destinations, handlePlus, handleMinus }) {
  const { t } = useTranslation();

  return (
    <Wrapper hasScroll={destinations.length > 1}>
      <Title level="h3" size="md">
        {t('destinationsDays')}
      </Title>
      <Days>
        {destinations?.map((item, index) => (
          <Destination
            isResult={isResult}
            key={item.slug}
            id={item.slug}
            destination={item.title[i18next.language]}
            days={item.days}
            index={index}
            handleMinus={() => (item.days > 0 ? handleMinus(item.slug) : null)}
            handlePlus={() => handlePlus(item.slug)}
          />
        ))}
      </Days>
    </Wrapper>
  );
}

export default DestinationDates;
