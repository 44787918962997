import Title from 'components/Typography/Title';
import getDistance from 'geolib/es/getPreciseDistance';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useFavorites from 'store/useFavorites';
import NearbyCard from './NearbyCard';
import { List, Wrapper } from './NearbyList.styles';

function NearbyList({ nearbyPlaces, poiTitle, poiCords }) {
  const { toggleFavorites, isFavorites } = useFavorites();
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Title level="h2" size="lg">
        {t('nearby')}
      </Title>
      <List>
        {!(nearbyPlaces?.length === 0) &&
          nearbyPlaces.map((place) => (
            <NearbyCard
              key={place.id}
              id={place.id}
              poiTitle={poiTitle}
              title={place.title}
              image={place.media ? place.media[0]?.url : null}
              distance={getDistance(poiCords, {
                latitude: place?.location.coordinates[1],
                longitude: place?.location.coordinates[0],
              })}
              handleFav={() => toggleFavorites(place)}
              isFavorites={isFavorites(place.id)}
            />
          ))}
      </List>
    </Wrapper>
  );
}

NearbyList.propTypes = {};

export default NearbyList;
