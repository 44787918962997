import useBrekpoint from 'hooks/useBrekpoint';
import useScrollVisible from 'hooks/useScrollVisible';
import ArrowLeft from 'Icons/ArrowLeft';
import ChevronDown from 'Icons/ChevronDown';
import { useRef } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import {
  BackDrop,
  CloseButton,
  Content,
  DownButton,
  Wrapper,
} from './createProgram.styles';
import Switch from 'components/CreatePlan/Switch';

export default function CreateProgram() {
  const contentRef = useRef(null);

  const navigate = useNavigate();
  const scrollIsVisible = useScrollVisible(contentRef);
  const { isSmallDevice } = useBrekpoint();

  const pageRef = useRef(null);

  const goHomePage = () => {
    pageRef?.current?.classList.add(
      isSmallDevice ? 'animate__fadeOutDown' : 'animate__fadeOutLeft',
    );
    setTimeout(() => {
      navigate('/');
    }, 600);
  };

  return (
    <BackDrop>
      <Wrapper
        ref={pageRef}
        className={`animate__animated ${
          isSmallDevice ? 'animate__fadeInUp' : 'animate__fadeInLeft'
        } `}
      >
        <CloseButton variant="square" onClick={goHomePage}>
          <ArrowLeft />
        </CloseButton>
        <DownButton onClick={goHomePage}>
          <ChevronDown />
        </DownButton>
        <Content ref={contentRef} hasScroll={scrollIsVisible}>
          <Switch />
          <Outlet />
        </Content>
      </Wrapper>
    </BackDrop>
  );
}
