import Title from 'components/Typography/Title';
import Plus from 'Icons/Plus';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  StepCardWrapper,
  StepImage,
  Distance,
  InfoWrapper,
  AddStep,
} from './StepCard.styles';

function StepCard({
  id,
  image,
  title,
  distance,
  poiTitle,
  handleClick,
  handleAdd,
}) {
  const { t } = useTranslation();

  return (
    <StepCardWrapper>
      <AddStep onClick={handleAdd}>
        <Plus />
      </AddStep>
      <Link to={`/poi/${id}`}>
        <StepImage src={image} alt={title} />

        <InfoWrapper>
          <Title level="h3" size="lg">
            {title}
          </Title>
          <Distance>{`${distance}m ${t('from')} ${poiTitle}`}</Distance>
        </InfoWrapper>
      </Link>
    </StepCardWrapper>
  );
}

StepCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  tag: PropTypes.string,
};

export default StepCard;
