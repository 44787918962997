import Restaurant from 'Icons/Restaurant';
import Lake from 'Icons/Lake';
import Calender from 'Icons/Calender';
import Craft from 'Icons/Craft';
import Aquatic from 'Icons/Aquatic';
import Sport from 'Icons/Sport';
import Nature from 'Icons/Nature';
import House from 'Icons/House';
import Walk from 'Icons/Walk';
import Culture from 'Icons/Culture';
import Vacation from 'Icons/Vacation';
import Adrenaline from 'Icons/Adrenaline';

export const getRegionColor = (region) => {
  switch (region) {
    case 'khenifra':
      return '#EB672C';
    case 'beni-mellal':
      return '#B5CD3A';
    case 'fquih-ben-saleh':
      return '#4C7846';
    case 'azilal':
      return '#F5B12F';
    case 'khouribga':
      return '#239BAB';
    case 'all':
      return '#07416A';
    case 'filter':
      return '#07416A';
    default:
      return '#07416A';
  }
};

export const getIcon = (iconName) => {
  switch (iconName.toLowerCase()) {
    case 'restaurant':
      return <Restaurant />;
    case 'gastronomie':
      return <Restaurant />;
    case 'lake':
      return <Lake />;
    case 'cooperatives-et-artisanat':
      return <Craft />;
    case 'evenements':
      return <Calender />;
    case 'activites-aquatiques':
      return <Aquatic />;
    case 'activites-sportives':
      return <Sport />;
    case 'nature':
      return <Nature />;
    case 'test-1':
      return <Nature />;
    case 'hebergement':
      return <House />;
    case 'activite':
      return <Walk />;
    case 'culture':
      return <Culture />;
    case 'agence-de-voyages':
      return <Vacation />;
    case 'adrenaline':
      return <Adrenaline />;
    case 'balneaire':
      return <Aquatic />;
    default:
      return <Restaurant />;
  }
};

export const getPhoneNumbeWithCountryCode = (number) => {
  const phoneNumber = number.toString();
  if (phoneNumber.length === 9) {
    return '+212' + phoneNumber;
  } else if (phoneNumber.length === 10 && phoneNumber.startsWith('05')) {
    return phoneNumber.replace('05', '+2125');
  } else if (phoneNumber.length === 10 && phoneNumber.startsWith('06')) {
    return phoneNumber.replace('06', '+2126');
  } else if (phoneNumber.length === 10 && phoneNumber.startsWith('07')) {
    return phoneNumber.replace('07', '+2127');
  } else if (phoneNumber.length === 12 && phoneNumber.startsWith('212')) {
    return '+' + phoneNumber;
  }

  return phoneNumber;
};

export const getDistanceWithUnit = (distance) => {
  if (distance) {
    return distance > 1000
      ? `${(distance / 1000).toFixed(1)}km`
      : `${distance.toFixed(1)}m`;
  } else {
    return 0 + 'm';
  }
};

export const getArrayCoords = (places) => {
  return places.map((place) => ({
    latitude: place?.location.coordinates[1],
    longitude: place?.location.coordinates[0],
  }));
};

export const getcityName = (cities, slug) => {
  return cities.find((city) => city.slug === slug)?.title;
};
