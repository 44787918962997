import FavoriteList from 'components/FavoritePlaces/FavoriteList';
import SideCloseBtn from 'components/SideCloseBtn';
import useBrekpoint from 'hooks/useBrekpoint';
import ChevronDown from 'Icons/ChevronDown';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useFavorites from 'store/useFavorites';
import { CloseBtn, Content, Wrapper } from './Favorites.styles';

export default function Favorites() {
  const { activeFilter, favorites, programs } = useFavorites();
  const { isSmallDevice } = useBrekpoint();

  const navigate = useNavigate();
  const foramtedPrograms = Object.keys(programs)?.map((programName) => ({
    type: 'program',
    id: programName,
    title: programName,
    image: programs[programName][0][0].media[0].url,
  }));

  const getList = () => {
    switch (activeFilter) {
      case 'all':
        return [...favorites, ...foramtedPrograms];
      case 'programs':
        return foramtedPrograms;
      case 'places':
        return favorites;
      default:
        return [...favorites, foramtedPrograms];
    }
  };
  const handleClose = () => {
    pageRef?.current?.classList.add('animate__fadeOutDown');

    setTimeout(() => {
      navigate('/');
    }, 600);
  };
  const pageRef = useRef(null);
  return (
    <Wrapper
      ref={pageRef}
      className={`animate__animated ${
        isSmallDevice ? 'animate__fadeInUp' : 'animate__fadeInLeft'
      } `}
    >
      <SideCloseBtn page={pageRef} />
      <CloseBtn onClick={handleClose}>
        <ChevronDown />
      </CloseBtn>
      <Content>
        <FavoriteList items={getList()} active={activeFilter} />
      </Content>
    </Wrapper>
  );
}
