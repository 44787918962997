import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';

export const placesZoom = 8.2;
export const BASE_URL = 'https://api.visitbenimellal.com/cache/';
export const LOCATIONIQ_BASE_URL = 'https://us1.locationiq.com/v1/search?key=';
export const PLACES_API = BASE_URL + 'places';
export const Cities_API = BASE_URL + 'city';
export const Tags_API = BASE_URL + 'tags';
export const TOURS_API = BASE_URL + 'tours';
export const MAPBOX_TOKEN =
  'pk.eyJ1IjoiY2FzYmFoZGV2IiwiYSI6ImNsZzU0NGZ4eDAxaDgzbm90N3doeG9lMXYifQ.N92Svf7rjH1NSKgn2k-R2w';
export const MAPBOX_STYLE_URL =
  'mapbox://styles/casbahdev/clg55cs3r003401o6xkm7274p';
export const MAPBOX_SATELLITE_STYLE_URL = 'mapbox://styles/mapbox/satellite-v9';
export const GEO_CODE_TOKEN = 'pk.65104f38330f39d384fcbd08bdca80ff';
export const MAPBOX_DIRECTIONS_API = 'https://api.mapbox.com/directions/v5/';

export const ROUTING_PROFILES = ['driving', 'walking'];

export const POPULAR_SLUG = 'populaire';
export const MAIN_FILTER_TAGS = [
  'activite',
  'culture',
  'nature',
  'adrenaline',
  'activites-sportives',
  'activites-aquatiques',
  'cooperatives-et-artisanat',
  'balneaire',
  'test-1',
];

export const ICONS_TAGS = [
  ...MAIN_FILTER_TAGS,
  'hebergement',
  'agence-de-voyages',
  'restaurant',
  'gastronomie',
  'evenements',
];
export const REMOVE_FROM_FILTER_TAGS = [
  'balneaire',
  'activite-deal',
  'restaurant-deal',
  'sejour-deal',
  'hebergement-deal',
  'infos-pratiques',
];
export const PREFERENCES_SLUGS = [
  'activite',
  'culture',
  'nature',
  'adrenaline',
  'activites-sportives',
  'activites-aquatiques',
  'cooperatives-et-artisanat',
  'balneaire',
  'test-1',
];
export const COMMERCIAL_TAGS = [
  'gastronomie',
  'agence-de-voyages',
  'restaurant-deal',
  'sejour-deal',
  'hebergement-deal',
  'hebergement',
];
export const SEARCH_SLUGS = [
  'evenements',
  'agence-de-voyages',
  'hebergement',
  'gastronomie',
];

export const STEPS_TAGS = ['all', 'see', 'do', 'eat'];
export const DO_TAGS = [
  'activités-sportives',
  'adrenaline',
  'activités-deal',
  'activité',
];

export const SEE_TAGS = ['nature', 'balneaire', 'culture'];

export const EAT_TAGS = ['restaurant-deal', 'gastronomie'];

export const socials = [
  {
    name: 'telegram',
    ShareButton: TelegramShareButton,
    Icon: TelegramIcon,
  },
  {
    name: 'linkedIn',
    ShareButton: LinkedinShareButton,
    Icon: LinkedinIcon,
  },
  {
    name: 'whatsapp',
    ShareButton: WhatsappShareButton,
    Icon: WhatsappIcon,
  },
  {
    name: 'email',
    ShareButton: EmailShareButton,
    Icon: EmailIcon,
  },
  {
    name: 'facebook',
    ShareButton: FacebookShareButton,
    Icon: FacebookIcon,
  },
  {
    name: 'twitter',
    ShareButton: TwitterShareButton,
    Icon: TwitterIcon,
  },
];
