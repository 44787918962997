import tw from 'twin.macro';

export const Wrapper = tw.section`
 fixed z-50 left-0 top-0 w-full bottom-0 pt-10 bg-white  
 md:max-w-[37.5rem]  md:rounded-t-[2.5rem] md:rounded-tl-none
 md:[box-shadow: 0px 0px 30px 15px rgba(0, 0, 0, 0.02)]
`;
export const Content = tw.div`
  w-full h-full flex flex-col gap-6 items-start
  overflow-y-auto overflow-x-hidden px-4 pb-6 
  md:px-10
`;

export const CloseBtn = tw.button`
  absolute top-4 block md:hidden text-black left-1/2 -translate-x-1/2
`;
