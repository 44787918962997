import Title from 'components/Typography/Title';
import ChevronLeft from 'Icons/ChevronLeft';
import ChevronRight from 'Icons/ChevronRight';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Navigation } from 'swiper';
import {
  CopyButton,
  CopyWrapper,
  Link,
  MediaWrapper,
  NavigationBtn,
  ShareOption,
  SwiperWrapper,
  Wrapper,
} from './SharePanel.styles';
import CopyIcon from 'Icons/CopyIcon';
import { socials } from 'utils/constants';

function SharePanel({ link }) {
  const { t } = useTranslation();
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const inputRef = useRef(null);
  const copyLink = () => {
    if (inputRef.current) {
      const text = inputRef.current.value;
      navigator.clipboard.writeText(text);
    }
  };

  return (
    <Wrapper>
      <ShareOption>
        <Title level="h2" size="lg">
          {t('shareLink')}
        </Title>
        <CopyWrapper>
          <Link
            aria-label="share link"
            ref={inputRef}
            type="text"
            value={link}
            readOnly
          />
          <CopyButton type="button" onClick={copyLink}>
            <CopyIcon />
          </CopyButton>
        </CopyWrapper>
      </ShareOption>
      <ShareOption>
        <Title level="h2" size="lg">
          {t('shareVia')}
        </Title>
        <SwiperWrapper>
          <NavigationBtn ref={prevRef}>
            <ChevronLeft />
          </NavigationBtn>
          <Swiper
            modules={[A11y, Navigation]}
            slidesPerView="auto"
            spaceBetween={18}
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
          >
            {socials?.map(({ name, ShareButton, Icon }) => (
              <SwiperSlide key={name}>
                <ShareButton url={link}>
                  <MediaWrapper>
                    <Icon borderRadius={12} size={68} />
                    <p>{name}</p>
                  </MediaWrapper>
                </ShareButton>
              </SwiperSlide>
            ))}
          </Swiper>
          <NavigationBtn ref={nextRef}>
            <ChevronRight />
          </NavigationBtn>
        </SwiperWrapper>
      </ShareOption>
    </Wrapper>
  );
}

SharePanel.propTypes = {};

export default SharePanel;
