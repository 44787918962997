import OpenHours from 'components/OpenHours';
import NearbyList from 'components/NearbyList';
import Paragraph from 'components/Typography/Paragraph';
import i18next from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SeeMore, Wrapper } from './InfoSection.styles';

function InfoSection({ poi, nearby }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation();

  const getShortText = (string) => {
    return string?.split(' ').slice(0, 50).join(' ');
  };
  const isLargeText = (string) => {
    return string?.split(' ').length > 49;
  };

  return (
    <Wrapper>
      {poi?.description[i18next.language] && (
        <Paragraph>
          {isExpanded
            ? poi?.description[i18next.language]
            : getShortText(poi?.description[i18next.language])}
          {isLargeText(poi?.description[i18next.language]) && (
            <SeeMore onClick={() => setIsExpanded((prev) => !prev)}>
              {isExpanded ? t('seeLess') : '... ' + t('seeMore')}
            </SeeMore>
          )}
        </Paragraph>
      )}

      <OpenHours hours={poi?.openingHours} contacts={poi?.contact} />

      <NearbyList
        nearbyPlaces={nearby}
        poiTitle={poi?.title[i18next.language]}
        poiCords={{
          latitude: poi?.location.coordinates[1],
          longitude: poi?.location.coordinates[0],
        }}
      />
    </Wrapper>
  );
}

export default InfoSection;
