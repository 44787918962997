import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useClusterPopup = create(
  persist(
    (set) => ({
      isOpen: false,
      updatedOnce: false,
      openClusterPopup: () => set({ updatedOnce: true, isOpen: true }),
      closeClusterPopup: () => set({ isOpen: false }),
    }),
    {
      name: 'popup',
    },
  ),
);

export default useClusterPopup;
