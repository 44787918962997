import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Navigation } from 'swiper';
import { NavButton, NavButtons, Wrapper } from './PopPlacesSwiper.styles';
import PopPlaceCard from '../PopPlaceCard';
import { useRef, useState } from 'react';
import ChevronLeft from 'Icons/ChevronLeft';
import ChevronRight from 'Icons/ChevronRight';
import i18next from 'i18next';
import placeholder from 'assets/placeholder.svg';

function PopPlacesSwiper({ places, setIsOpen }) {
  // eslint-disable-next-line
  const [_, setInit] = useState();
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <Wrapper>
      <Swiper
        className="w-full h-full"
        modules={[A11y, Navigation]}
        slidesPerView="auto"
        spaceBetween={8}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onInit={() => setInit(true)}
      >
        {places?.length > 0 &&
          places?.map((place) => (
            <SwiperSlide key={place.id}>
              <PopPlaceCard
                onClick={() => setIsOpen(false)}
                id={place.id}
                image={place.media[0]?.url || placeholder}
                title={place.title[i18next.language]}
                region={place.contact?.address?.city}
              />
            </SwiperSlide>
          ))}
      </Swiper>
      <NavButtons>
        <NavButton ref={prevRef}>
          <ChevronLeft />
        </NavButton>
        <NavButton ref={nextRef}>
          <ChevronRight />
        </NavButton>
      </NavButtons>
    </Wrapper>
  );
}

export default PopPlacesSwiper;
