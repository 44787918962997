import styled from 'styled-components';
import tw from 'twin.macro';

export const Wrapper = styled.div`
  ${tw`flex flex-col gap-[2.9mm] w-[158mm] mx-auto min-h-full items-center`}
`;

export const HeaderPlaceHolder = styled.div`
  height: 0;
  @media print {
    height: 60px;
  }
`;

export const PrintBtn = styled.button`
  ${tw`z-50 fixed bottom-2 left-1/2 -translate-x-1/2 flex justify-center items-center gap-2
      border-8 border-[#363636] rounded-[18px] w-[11.125rem] h-[4.438rem] 
      text-white text-base font-bold capitalize bg-[#EB672C]
      [box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.41)]`}
  @media print {
    display: none;
  }
`;

export const Header = styled.header`
  ${tw`w-[70%] flex justify-between items-center py-2 pl-5 pr-11
  capitalize text-[#212324] border border-[#212324] border-t-0 rounded-b-lg`}
  @media print {
    ${tw`fixed top-0 left-[calc(50% + 6.35mm)] -translate-x-1/2 `}
  }
`;

export const Title = tw.h1`
 text-xl font-bold
`;

export const TripInfo = tw.div`
  flex items-center gap-3 text-xs font-semibold tracking-[-1px]
`;

export const HeaderDivider = tw.span`
  w-[0.26mm] h-[8.2mm] bg-[#212324] mr-[3.96mm]
`;

export const InfoDivider = tw.span`
 w-[0.26mm] h-[5.55mm] bg-[#D9D9D9]
`;

export const List = tw.div`
  w-full flex flex-col gap-3.5 justify-start 
`;

export const DayWrapper = tw.div`
 w-full flex flex-col gap-6
`;
export const Day = tw.div`
 w-full flex gap-4 border-b border-black  pb-[2.9mm] whitespace-nowrap justify-start
 text-xl text-[#212324] font-semibold tracking-[-1px] capitalize mb-2.5 
`;

export const DayInfo = tw.span`
 flex justify-center items-center gap-0.5
 text-xs text-[#898989] font-semibold tracking-[-1px]
`;

export const Stops = tw.div`
  w-full flex gap-x-[22px] gap-y-4 justify-center flex-wrap
`;
