import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y } from 'swiper';
import { Card, Day, Distance, Wrapper } from './FixedSwiper.styles';

import { useTranslation } from 'react-i18next';

function FixedSwiper({ days, active, setActive, setSwiper }) {
  const { t } = useTranslation();
  return (
    <Wrapper className="animate__animated animate__fadeInUp">
      <Swiper
        className="w-full h-full"
        modules={[A11y]}
        slidesPerView="auto"
        spaceBetween={16}
        onInit={(swiper) => setSwiper(swiper)}
      >
        {days?.length !== 0 &&
          days.map((item) => (
            <SwiperSlide key={item.number} className={'first:ml-2'}>
              <Card
                href={`#${item.number}`}
                onClick={() => setActive(item.number)}
                isActive={active === item.number}
              >
                <Day>
                  {t('day')} {item.number}
                </Day>
                <Distance>{item.distance}</Distance>
              </Card>
            </SwiperSlide>
          ))}
      </Swiper>
    </Wrapper>
  );
}

FixedSwiper.propTypes = {
  days: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.number.isRequired,
      distance: PropTypes.string.isRequired,
    }),
  ),
};

export default FixedSwiper;
