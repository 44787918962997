const Aquatic = () => {
  return (
    <svg
      width="39"
      height="36"
      viewBox="0 0 39 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.0357 27.9203C23.9557 27.0803 24.8357 27.2002 26.4357 28.0403C28.0357 28.8803 29.5957 28.8803 31.4757 27.9203C32.7556 27.1602 34.3957 27.2002 35.6757 28.0002C36.4357 28.4402 37.2757 28.6402 38.1156 28.6002V25.8002C36.5156 25.8002 35.8357 25.2802 35.8357 25.2802C34.1957 24.2802 32.7157 24.4802 30.6357 25.5201C29.4757 26.1201 27.5957 25.9601 26.2757 25.2802C24.4757 24.3201 23.4357 24.5601 21.5157 25.4002C20.1557 26.0402 18.5956 26.0402 17.2357 25.4002C15.3156 24.5603 14.3558 24.3203 12.5157 25.2802C11.1956 25.9601 9.31571 26.1201 8.11572 25.5201C6.07575 24.4402 4.59571 24.2402 2.95571 25.2802C2.95571 25.2802 2.23565 25.8002 0.675781 25.8002V28.6002C1.51575 28.6402 2.35571 28.4402 3.07578 28.0002C4.39585 27.2002 5.99585 27.1603 7.35578 27.9203C9.23571 28.8803 10.7958 28.8803 12.3558 28.0403C13.9158 27.2004 14.7957 27.0803 16.7158 27.8803C18.3958 28.7602 20.3958 28.8002 22.0358 27.9203"
        fill="currentColor"
      />
      <path
        d="M22.0347 35.0004C23.9548 34.1604 24.8347 34.2803 26.4347 35.1205C28.0347 35.9604 29.5948 35.9604 31.4747 35.0004C32.7948 34.2803 34.4348 34.3205 35.7147 35.0804C36.4748 35.5203 37.3147 35.7203 38.1547 35.6804V32.8804C36.5547 32.8804 35.8748 32.3603 35.8748 32.3603C34.2348 31.3603 32.7547 31.5603 30.6748 32.6402C29.5147 33.2402 27.6348 33.0802 26.3148 32.4003C24.5148 31.4402 23.4748 31.6802 21.5548 32.5203C20.1947 33.1603 18.6347 33.1603 17.2748 32.5203C15.3547 31.6803 14.3948 31.4404 12.5548 32.4003C11.2347 33.0802 9.35478 33.2402 8.15478 32.6402C6.11481 31.5603 4.63478 31.3603 2.99478 32.3603C2.99478 32.3603 2.27471 32.8804 0.714844 32.8804V35.6804C1.55481 35.7203 2.39478 35.5203 3.11484 35.0804C4.43491 34.2804 6.03491 34.2404 7.39484 35.0004C9.27478 35.9604 10.8348 35.9604 12.3948 35.1205C13.9549 34.2805 14.8348 34.1604 16.7548 35.0004C18.3949 35.8404 20.3948 35.8803 22.0348 35.0004"
        fill="currentColor"
      />
      <path
        d="M38.6752 12.9196C38.6752 15.3196 36.7551 17.2396 34.3552 17.2396C31.9552 17.2396 30.0352 15.3195 30.0352 12.9196C30.0352 10.5197 31.9552 8.59961 34.3552 8.59961C36.7552 8.63958 38.6752 10.5596 38.6752 12.9196Z"
        fill="currentColor"
      />
      <path
        d="M12.5168 20.5599C14.3168 19.5999 15.3168 19.8399 17.2368 20.68C18.5968 21.3199 20.1568 21.3199 21.5168 20.68C23.4368 19.84 24.4368 19.6 26.2768 20.5599C27.5968 21.2399 29.4768 21.3999 30.6368 20.7999C30.7167 20.7599 30.7568 20.7599 30.7968 20.7199L30.8368 20.68L23.0768 6.35998L30.6768 4.83991C31.9167 4.59994 32.7167 3.35998 32.4768 2.11984C32.2368 0.879876 30.9968 0.0798761 29.7567 0.319843L17.3567 2.79978C16.4366 2.87972 15.7967 3.67971 15.8768 4.59978C15.9167 4.87972 15.9968 5.11984 16.1567 5.31984L20.1167 12.5998L6.63672 20.1598C7.15679 20.3199 7.63672 20.5598 8.11665 20.7998C9.31665 21.3998 11.1967 21.2398 12.5167 20.5598"
        fill="currentColor"
      />
    </svg>
  );
};

export default Aquatic;
