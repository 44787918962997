import i18next from 'i18next';
import ChevronDown from 'Icons/ChevronDown';
import { useCallback, useEffect, useMemo } from 'react';
import usePlaces from 'store/usePlaces';
import SearchItem from '../SearchItem';
import {
  Content,
  Empty,
  FilterWrapper,
  Icon,
  List,
  PlaceHolder,
  Tag,
  TagIcon,
  Wrapper,
} from './SearchResult.styles';
import placeholder from 'assets/placeholder.svg';
import { forwardRef } from 'react';
import { A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getArrayCoords, getIcon } from 'utils/helpers';
import { useMap } from 'react-map-gl';
import { getBounds } from 'geolib';
import MapIcon from 'Icons/MapIcon';
import { useTranslation } from 'react-i18next';
import useTags from 'store/useTags';
import { placesZoom } from 'utils/constants';

const SearchResult = forwardRef(
  (
    {
      results,
      handleClose,
      activeSearchFilters,
      searchFilters,
      setActiveFilters,
    },
    ref,
  ) => {
    const { main_map } = useMap();
    const {
      setIsSearching,
      allPlaces,
      withoutCommercialPlaces,
      setPlaces,
      setCitySelected,
      filterByCity,
      cityIsSelected,
      cities,
    } = usePlaces();
    const { activeTags } = useTags();
    const headTo = useCallback(
      (coordsArr) => {
        if (coordsArr?.length > 0) {
          const { minLat, maxLat, minLng, maxLng } = getBounds(coordsArr);
          main_map?.fitBounds(
            [
              [minLng, minLat],
              [maxLng, maxLat],
            ],
            {
              padding: { top: 200, bottom: 100 },
              curve: 2,
              speed: 0.5,
            },
          );
        }
      },
      [main_map],
    );

    //Detect if the result box is present or not
    useEffect(() => {
      setIsSearching(true);

      return () => setIsSearching(false);
    }, [setIsSearching]);

    const getNewActiveTags = (slug) => {
      const index = activeSearchFilters.findIndex((tag) => slug === tag);
      if (index >= 0) {
        return activeSearchFilters.filter((tag) => slug !== tag);
      } else {
        return [...activeSearchFilters, slug];
      }
    };

    const filterByTags = (tags) => {
      const filtered = allPlaces.filter((place) =>
        place.tags.some((tag) => tags.includes(tag.slug)),
      );
      return filtered;
    };

    const handleClick = (slug) => {
      const zoomLevel = main_map?.getZoom();

      const newActiveTags = getNewActiveTags(slug);
      setActiveFilters(newActiveTags);
      if (newActiveTags.length > 0 || activeTags.length > 0) {
        const filteredPlaces = filterByTags([
          ...newActiveTags,
          ...activeSearchFilters,
        ]);

        headTo(getArrayCoords(filteredPlaces));
        setPlaces(filteredPlaces);
      } else if (cityIsSelected === 'all' && zoomLevel < placesZoom) {
        setPlaces(cities);
        setCitySelected(false);
      } else if (cityIsSelected && cityIsSelected !== 'all') {
        filterByCity(cityIsSelected);
      } else if (zoomLevel > placesZoom) {
        setCitySelected('all');
        setPlaces(withoutCommercialPlaces);
      } else {
        setCitySelected(false);
        setPlaces(cities);
      }
    };

    const filteredResults = useMemo(() => {
      return results?.filter((place) => {
        if (activeSearchFilters.length > 0) {
          return place.tags.some((tag) =>
            activeSearchFilters.includes(tag.slug),
          );
        } else {
          return results;
        }
      });
    }, [activeSearchFilters, results]);

    const { t } = useTranslation();

    return (
      <Wrapper ref={ref}>
        <Icon onClick={handleClose}>
          <ChevronDown />
        </Icon>
        <Content>
          <FilterWrapper>
            <Swiper
              className="w-full"
              modules={[A11y]}
              slidesPerView="auto"
              spaceBetween={8}
            >
              {searchFilters?.length > 0 &&
                searchFilters.map((tag) => (
                  <SwiperSlide key={tag._id}>
                    <Tag
                      isActive={activeSearchFilters.includes(tag.slug)}
                      onClick={() => handleClick(tag.slug)}
                    >
                      <TagIcon>{getIcon(tag.slug)}</TagIcon>
                      {tag.name[i18next.language]}
                    </Tag>
                  </SwiperSlide>
                ))}
            </Swiper>
            <List>
              {filteredResults?.length > 0 ? (
                filteredResults.map((item) => (
                  <SearchItem
                    key={item.id}
                    id={item.id}
                    handleClose={handleClose}
                    title={item.title[i18next.language]}
                    description={item.description[i18next.language]}
                    image={item.media[0]?.url || placeholder}
                    region={item.contact?.address?.city}
                    features={item.tags}
                  />
                ))
              ) : (
                <PlaceHolder>
                  <MapIcon />
                  <Empty>{t('enterSearch')}</Empty>
                </PlaceHolder>
              )}
            </List>
          </FilterWrapper>
        </Content>
      </Wrapper>
    );
  },
);

export default SearchResult;
