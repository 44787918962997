import tw, { styled } from 'twin.macro';

export const Wrapper = tw.div`
  relative w-full lg:(w-[unset] fixed right-10 bottom-[3.25rem] pl-0) 
`;

export const Tag = styled.button(({ isActive }) => [
  tw`flex justify-center items-center gap-[5px] bg-white  whitespace-nowrap
     capitalize text-black text-base tracking-[-1px] px-4 py-2 rounded-[4.125rem]`,

  isActive && tw`bg-orange text-white`,
]);

export const IconWrapper = tw.span`
  flex justify-center items-center w-4 h-4 shrink-0 text-center
`;
export const NavigationButton = styled.button(({ position }) => [
  tw` hidden md:flex justify-center items-center w-11 h-16 bg-white rounded-xl disabled:bg-[#E4E4E4] disabled:text-[rgba(0, 0, 0, 0.2)]
    absolute z-50 top-1/2 -translate-y-1/2 [box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.25)]
  `,
  position === 'right' && tw`-right-5`,
  position === 'left' && tw`-left-5`,
]);
