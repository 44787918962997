import Button from 'components/Button';
import ProgramList from 'components/Program/DayList';
import Modal from 'components/Modal';
import SharePanel from 'components/SharePanel';
import Title from 'components/Typography/Title';
import { getBounds, getDistance, getRhumbLineBearing } from 'geolib';
import ArrowLeft from 'Icons/ArrowLeft';
import Share from 'Icons/Share';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import useFavorites from 'store/useFavorites';
import usePlaces from 'store/usePlaces';
import { getDistanceWithUnit } from 'utils/helpers';
import {
  Breadcrumb,
  Breadcrumbs,
  Content,
  DayWrapper,
  Header,
  ShareBtn,
  Wrapper,
} from './Share.styles';

export default function SharedPage() {
  const { main_map } = useMap();
  const [shareIsOpen, setShareIsOpen] = useState(false);
  const { programs, isFavorites, toggleFavorites } = useFavorites();
  const { setPlaces, allPlaces } = usePlaces();
  const { t } = useTranslation();
  const naviagte = useNavigate();
  const params = useParams();

  const programArr = useMemo(() => {
    function isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    }
    const programLink = params.link;
    const idsArr = isJsonString(decodeURI(programLink))
      ? JSON.parse(decodeURI(programLink))
      : null;

    if (allPlaces?.length > 0) {
      return idsArr?.map((day) => {
        if (typeof day === 'string') {
          return [day];
        } else {
          return day.map(
            (id) => allPlaces.filter((place) => place.id === id)[0],
          );
        }
      });
    } else {
      return null;
    }
  }, [params.link, allPlaces]);

  const closeModal = () => {
    setShareIsOpen(false);
  };

  const animateTo = useCallback(
    ({ longitude, latitude }) => {
      main_map?.flyTo({
        center: [longitude, latitude],
        duration: 2000,
        curve: 2,
        zoom: 10,
      });
    },
    [main_map],
  );

  const headTo = useCallback(
    (start, target) => {
      if ((start, target)) {
        const { minLat, maxLat, minLng, maxLng } = getBounds([start, target]);

        const bearing = getRhumbLineBearing(start, target);
        main_map?.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          {
            padding: { left: 250, top: 50, bottom: 100 },
            pitch: 80,
            bearing,
            curve: 2,
            speed: 0.5,
          },
        );
      }
    },
    [main_map],
  );

  useEffect(() => {
    if (programArr) {
      const firstLocation = programArr[0][0]?.location.coordinates;
      const secondLocation = programArr[0][1]?.location?.coordinates;
      setPlaces(programArr.flat());
      if (secondLocation) {
        headTo(
          {
            longitude: firstLocation[0],
            latitude: firstLocation[1],
          },
          {
            longitude: secondLocation[0],
            latitude: secondLocation[1],
          },
        );
      } else {
        animateTo({
          longitude: firstLocation[0],
          latitude: firstLocation[1],
        });
      }
    }
  }, [programs, animateTo, headTo, setPlaces, programArr]);

  const handleHover = (start, target) => {
    headTo(start, target);
  };

  const goBack = () => {
    naviagte('/');
  };

  const programSummary = useMemo(() => {
    const myProgram = {};

    programArr?.forEach((day, index) => {
      if (day !== undefined && typeof day[0] !== 'string') {
        const firstPlaceCords = {
          latitude: day[0]?.location.coordinates[1],
          longitude: day[0]?.location.coordinates[0],
        };
        const lastPlaceCords = {
          latitude: day.slice(-1)[0]?.location.coordinates[1],
          longitude: day.slice(-1)[0]?.location.coordinates[0],
        };

        const distance =
          firstPlaceCords && lastPlaceCords
            ? getDistance(firstPlaceCords, lastPlaceCords) * 1.4
            : null;

        myProgram[`day${index + 1}`] = {
          region: day[0].contact?.address?.city,
          distance: getDistanceWithUnit(distance),
          dayPlaces: day,
        };
      } else {
        myProgram[`day${index + 1}`] = {
          region: day[0],
          distance: 0 + 'm',
          dayPlaces: day,
        };
      }
    });

    return myProgram;
  }, [programArr]);

  return programArr === undefined ||
    programArr?.flat().some((place) => place === undefined) ? (
    <Navigate to="/" />
  ) : (
    <Wrapper>
      <Content>
        <Button variant="square" onClick={goBack}>
          <ArrowLeft />
        </Button>
        <Header>
          <Title level="h1" size="lg">
            {t('shared')}
          </Title>

          <ShareBtn onClick={() => setShareIsOpen(true)}>
            <Share />
          </ShareBtn>
        </Header>
        {programSummary &&
          Object.keys(programSummary).map((day, index) => (
            <DayWrapper key={day[0].id}>
              <Breadcrumbs>
                <Breadcrumb>{day}</Breadcrumb>
                <Breadcrumb>{programSummary[day].distance}</Breadcrumb>
                <Breadcrumb>{programSummary[day].region}</Breadcrumb>
              </Breadcrumbs>
              <ProgramList
                toggleFavorites={toggleFavorites}
                isFavorites={isFavorites}
                items={programSummary[day]?.dayPlaces}
                handleHover={handleHover}
                withoutSave
                withBorder={false}
              />
            </DayWrapper>
          ))}
      </Content>
      <Modal isOpen={shareIsOpen} closeModal={closeModal} largePanel>
        <SharePanel link={params.link} />
      </Modal>
    </Wrapper>
  );
}
