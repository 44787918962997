import tw from 'twin.macro';

export const Wrapper = tw.div`
  w-full flex flex-col justify-center items-start gap-4 
`;

export const NavButtons = tw.div`
  hidden md:flex justify-center items-center gap-2
`;

export const NavButton = tw.button`
  w-10 h-10 rounded-xl flex justify-center items-center
  bg-blue disabled:bg-[#CCCCCC] text-white
`;
