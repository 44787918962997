import { create } from 'zustand';

const useSearchFilter = create(
  (set) => ({
    activeSearchFilters: [],
    searchFilters: null,

    setSearchFilters: (filters) => set({ searchFilters: filters }),
    resetActiveFilters: () => set({ activeSearchFilters: [] }),
    setActiveFilters: (filters) => set({ activeSearchFilters: filters }),
  }),
  {
    name: 'filters',
  },
);

export default useSearchFilter;
