import { Listbox } from '@headlessui/react';
import { locales } from 'i18n';
import i18next from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dropdown,
  Icon,
  ListButton,
  Option,
  Options,
} from './LangSwitcher.styled';
import langIcon from 'assets/lang.png';

const LangSwitcher = () => {
  const [activeLanguage, setActiveLaguage] = useState(i18next.language);
  const { t } = useTranslation();
  const changeLanguage = (lang) => {
    i18next.changeLanguage(lang);
  };
  return (
    <Listbox
      as="div"
      className="relative w-[3.25rem] h-[3.25rem] lg:w-[3.75rem] lg:h-[3.75rem] z-50 bg-white rounded-full lg:order-1 lg:ml-auto"
      value={activeLanguage}
      onChange={setActiveLaguage}
    >
      {({ open }) => (
        <>
          <ListButton $isOpen={open} lang={activeLanguage}>
            <Icon src={langIcon} alt="language icon" />
          </ListButton>
          <Dropdown>
            <Options>
              {locales?.map((locale) => (
                <Option
                  $isActive={activeLanguage === locale}
                  key={locale}
                  value={locale}
                  onClick={() => changeLanguage(locale)}
                >
                  {t(locale)}
                </Option>
              ))}
            </Options>
          </Dropdown>
        </>
      )}
    </Listbox>
  );
};

export default LangSwitcher;
