import { create } from 'zustand';
import { persist } from 'zustand/middleware';
const useTripForm = create(
  persist(
    (set) => ({
      steps: [
        { name: 'destination', isCompleted: false },
        { name: 'preference', isCompleted: false },
      ],
      destinations: [],
      preferences: [],
      resetForm: () => set({ destinations: [], preferences: [] }),
      handlePreferences: (slug) =>
        set((state) => {
          const index = state.preferences.indexOf(slug);
          if (index >= 0) {
            return {
              preferences: state.preferences.filter((item) => item !== slug),
            };
          } else {
            return { preferences: [...state.preferences, slug] };
          }
        }),
      handleDestinations: (slug, title, coordinates) =>
        set((state) => {
          const index = state.destinations.findIndex(
            (item) => item.slug === slug,
          );
          if (index >= 0) {
            return {
              destinations: state.destinations.filter(
                (item) => item.slug !== slug,
              ),
            };
          } else {
            return {
              destinations: [
                ...state.destinations,
                { slug, days: 1, title, coordinates },
              ],
            };
          }
        }),
      updateDestinations: (destinations) => set({ destinations }),
      addDay: (slug) =>
        set((state) => ({
          destinations: state.destinations.map((item) =>
            item.slug === slug ? { ...item, days: item.days + 1 } : item,
          ),
        })),
      removeDay: (slug) =>
        set((state) => ({
          destinations: state.destinations.map((item) =>
            item.slug === slug ? { ...item, days: item.days - 1 } : item,
          ),
        })),
    }),
    {
      name: 'trip-program',
    },
  ),
);

export default useTripForm;
