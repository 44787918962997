import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Navigation } from 'swiper';
import { IconWrapper, Tag, Wrapper } from './Filter.styles';
import { getIcon } from 'utils/helpers';
import { useCallback, useRef } from 'react';

import useTags from 'store/useTags';
import i18next from 'i18next';
import usePlaces from 'store/usePlaces';
import { useMap } from 'react-map-gl';
import { getBounds } from 'geolib';
import { getArrayCoords } from 'utils/helpers';
import useSearchFilter from 'store/useSearchFilter';
import { placesZoom } from 'utils/constants';

function Filter({ tags }) {
  const { main_map } = useMap();
  const {
    cityIsSelected,
    setPlaces,
    setCitySelected,
    filterByCity,
    cities,
    allPlaces,
    withoutCommercialPlaces,
  } = usePlaces();
  const { activeTags, setActiveTags } = useTags();
  const { activeSearchFilters } = useSearchFilter();
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const headTo = useCallback(
    (coordsArr) => {
      if (coordsArr?.length > 0) {
        const { minLat, maxLat, minLng, maxLng } = getBounds(coordsArr);
        main_map?.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          {
            padding: { top: 200, bottom: 100 },
            curve: 2,
            speed: 0.5,
          },
        );
      }
    },
    [main_map],
  );

  const getNewActiveTags = (tagSlug) => {
    const index = activeTags.findIndex((tag) => tagSlug === tag);
    if (index >= 0) {
      return activeTags.filter((tag) => tagSlug !== tag);
    } else {
      return [...activeTags, tagSlug];
    }
  };

  const filterByTags = (tags) => {
    const filtered = allPlaces.filter((place) =>
      place.tags.some((tag) => tags.includes(tag.slug)),
    );
    return filtered;
  };

  const handleClick = (tagSlug) => {
    const zoomLevel = main_map?.getZoom();
    const newActiveTags = getNewActiveTags(tagSlug);
    setActiveTags(newActiveTags);
    if (newActiveTags.length > 0 || activeSearchFilters.length > 0) {
      const filteredPlaces = filterByTags([
        ...newActiveTags,
        ...activeSearchFilters,
      ]);

      headTo(getArrayCoords(filteredPlaces));
      setPlaces(filteredPlaces);
    } else if (cityIsSelected === 'all' && zoomLevel < placesZoom) {
      setPlaces(cities);
      setCitySelected(false);
    } else if (cityIsSelected && cityIsSelected !== 'all') {
      filterByCity(cityIsSelected);
    } else if (zoomLevel > placesZoom) {
      setCitySelected('all');
      setPlaces(withoutCommercialPlaces);
    } else {
      setCitySelected(false);
      setPlaces(cities);
    }
  };

  return (
    <Wrapper>
      <Swiper
        modules={[A11y, Navigation]}
        slidesPerView="auto"
        spaceBetween={8}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
      >
        {tags?.length > 0 &&
          tags.map((tag) => (
            <SwiperSlide key={tag._id}>
              <Tag
                isActive={activeTags.includes(tag.slug)}
                onClick={() => handleClick(tag.slug)}
              >
                <IconWrapper>{getIcon(tag.slug)}</IconWrapper>
                {tag.name[i18next.language]}
              </Tag>
            </SwiperSlide>
          ))}
      </Swiper>
      {/**<NavigationButton ref={prevRef} position="left">
        <ChevronLeft />
      </NavigationButton>
      <NavigationButton ref={nextRef} position="right">
        <ChevronRight />
          </NavigationButton> **/}
    </Wrapper>
  );
}

Filter.propTypes = {
  handleSelect: PropTypes.func,
  active: PropTypes.string,
};

export default Filter;
