import React from 'react';
import PropTypes from 'prop-types';
import {
  Breadcrumbs,
  Infos,
  Item,
  Region,
  Wrapper,
  Image,
  Description,
  IconWrapper,
} from './SearchItem.styles';
import Title from 'components/Typography/Title';
import Marker from 'Icons/Marker';
import { getcityName, getIcon, getRegionColor } from 'utils/helpers';
import i18next from 'i18next';
import usePlaces from 'store/usePlaces';

function SearchItem({
  image,
  title,
  description,
  region,
  features,
  handleClose,
  id,
}) {
  const { cities } = usePlaces();
  return (
    <Wrapper onClick={handleClose} to={`/poi/${id}`}>
      <Image src={image} alt={title} />
      <Infos>
        <Breadcrumbs>
          {region && (
            <Item>
              <Marker color={getRegionColor(region)} />
              <Region regionColor={getRegionColor(region)}>
                {getcityName(cities, region)[i18next.language]}
              </Region>
            </Item>
          )}
          {features?.map((feature) => (
            <Item key={feature.slug}>
              <IconWrapper>{getIcon(feature.slug)}</IconWrapper>
              {feature.name[i18next.language]}
            </Item>
          ))}
        </Breadcrumbs>
        <Title level="h1" size="md">
          {title}
        </Title>
        <Description>{description}</Description>
      </Infos>
    </Wrapper>
  );
}

SearchItem.propTypes = {
  image: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default SearchItem;
