import Button from 'components/Button';
import tw, { styled } from 'twin.macro';

export const BackDrop = tw.div`
   fixed inset-0 bg-[rgba(75, 75, 75, 0.5)] z-50  
`;
export const Wrapper = tw.section`
  fixed z-20 flex flex-col justify-start bottom-0 left-0 top-[16rem]  w-full 
  bg-white rounded-t-[2.75rem] pt-10  gap-8 pb-6
  md:w-[35.875rem]  md:left-0 md:bottom-0  md:top-0
   md:rounded-l-none md:rounded-r-[2.75rem]
  [box-shadow:  0px 0px 30px 15px rgba(0, 0, 0, 0.05)]
`;

export const Content = styled.form`
  ${tw`w-full h-full flex flex-col gap-8 justify-start items-start px-4 
   overflow-auto md:gap-6 md:px-10`}
  ${({ hasScroll }) => hasScroll && tw`md:pr-8`}
`;
export const CloseButton = styled(Button)`
  ${tw`hidden md:flex ml-10`}
`;
export const DownButton = tw.button`
  absolute top-4 block md:hidden text-black left-1/2 -translate-x-1/2
`;
