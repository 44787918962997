import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import './i18n';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import GlobalStyles from './styles/GlobalStyles';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'animate.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import useServiceWorker from 'store/useServiceWorker';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <GlobalStyles />
    <App />
  </>,
);

serviceWorkerRegistration.register({
  onSuccess: () => useServiceWorker.getState().setInitialized(true),
  onUpdate: (reg) => {
    useServiceWorker.getState().setRegistration(reg);
    useServiceWorker.getState().setUpdated(true);
  },
});

reportWebVitals();
