const Twitter = () => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4702 1.71333C16.834 1.99474 16.1593 2.17956 15.4685 2.26167C16.1967 1.82619 16.7416 1.14084 17.0019 0.333333C16.3185 0.74 15.5694 1.025 14.7885 1.17917C14.264 0.617982 13.5688 0.245803 12.811 0.120487C12.0532 -0.00482821 11.2752 0.123742 10.5979 0.486211C9.9207 0.848679 9.3822 1.42474 9.06614 2.12483C8.75008 2.82492 8.67417 3.60982 8.85021 4.3575C7.46447 4.28804 6.10882 3.92794 4.87128 3.30055C3.63374 2.67317 2.54198 1.79254 1.66688 0.715833C1.35712 1.24788 1.19434 1.85269 1.19521 2.46833C1.19521 3.67667 1.81021 4.74417 2.74521 5.36917C2.19188 5.35175 1.65074 5.20232 1.16688 4.93333V4.97667C1.16704 5.78142 1.44552 6.56135 1.95508 7.18422C2.46465 7.80709 3.17394 8.23456 3.96271 8.39417C3.44905 8.53336 2.91046 8.55388 2.38771 8.45417C2.6101 9.14686 3.04355 9.75267 3.62737 10.1868C4.21119 10.6209 4.91615 10.8615 5.64354 10.875C4.92061 11.4428 4.09286 11.8625 3.20761 12.1101C2.32236 12.3578 1.39698 12.4285 0.484375 12.3183C2.07746 13.3429 3.93196 13.8868 5.82604 13.885C12.2369 13.885 15.7427 8.57417 15.7427 3.96833C15.7427 3.81833 15.7385 3.66667 15.7319 3.51833C16.4143 3.02514 17.0032 2.41418 17.471 1.71417L17.4702 1.71333Z"
        fill="white"
      />
    </svg>
  );
};

export default Twitter;
