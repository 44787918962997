const House = () => {
  return (
    <svg
      width="36"
      height="39"
      viewBox="0 0 36 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.03424 15.0001L17.6742 3.68013L32.3142 15.0001C32.9942 15.5202 34.0743 15.4001 34.5942 14.7202C35.1542 14.0001 34.9942 12.9601 34.3142 12.4402L29.5142 8.76025V2.56025C29.5142 2.08032 29.1142 1.72028 28.6743 1.72028H27.1142C26.6343 1.72028 26.2743 2.12028 26.2743 2.56025V6.20025L18.6743 0.360248C18.0743 -0.0797182 17.2743 -0.0797182 16.6743 0.360248L1.03426 12.4002C0.674226 12.6802 0.474227 13.0402 0.394293 13.4802C0.35432 13.9201 0.47424 14.3601 0.714347 14.6802C1.27425 15.4402 2.31421 15.5602 3.03428 15.0002L3.03424 15.0001Z"
        fill="currentColor"
      />
      <path
        d="M11.5563 24.2C11.5563 25.9673 10.1236 27.4 8.35625 27.4C6.58892 27.4 5.15625 25.9673 5.15625 24.2C5.15625 22.4327 6.58892 21 8.35625 21C10.1236 21 11.5563 22.4327 11.5563 24.2Z"
        fill="currentColor"
      />
      <path
        d="M33.155 24.2004L16.035 20.5604C15.1551 20.3604 14.275 20.9204 14.075 21.8403L12.435 29.4403H3.23503L3.23516 19.9203C3.23516 19.0404 2.51509 18.3203 1.63516 18.3203C0.755223 18.3203 0.0351562 19.0404 0.0351562 19.9203V36.4403C0.0351562 37.3202 0.755223 38.0403 1.63516 38.0403C2.51509 38.0403 3.23516 37.3202 3.23516 36.4403V32.6003H32.0752V36.4403C32.0752 37.3202 32.7952 38.0403 33.6752 38.0403C34.5551 38.0403 35.2752 37.3202 35.2752 36.4403V26.8403C35.2752 25.5604 34.3952 24.4403 33.1551 24.2003L33.155 24.2004Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default House;
