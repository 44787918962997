const Add = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99609 5.00028C9.99609 4.44001 9.5419 3.98582 8.98163 3.98582L6.31481 3.98582L6.31481 0.973014C6.31481 0.501568 5.93263 0.119385 5.46118 0.119385C4.98973 0.119385 4.60755 0.501568 4.60755 0.973015L4.60755 3.98582L1.94073 3.98582C1.38046 3.98582 0.926267 4.44001 0.926267 5.00028C0.926267 5.56056 1.38046 6.01475 1.94073 6.01475L4.60755 6.01475L4.60755 9.02755C4.60755 9.499 4.98973 9.88118 5.46118 9.88118C5.93263 9.88118 6.31481 9.499 6.31481 9.02755L6.31481 6.01475L8.98163 6.01475C9.5419 6.01475 9.99609 5.56056 9.99609 5.00028Z"
        fill="#B5CD3A"
      />
    </svg>
  );
};

export default Add;
