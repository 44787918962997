import PropTypes from 'prop-types';
import {
  DistanceTag,
  IconWrapper,
  InfoPopup,
  InfosWrapper,
  StepAddButton,
  StepAddWrapper,
  TagWrapper,
} from './StepAdd.styles';
import Add from 'Icons/Add';
import Distance from 'Icons/Distance';
import Info from 'Icons/Info';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

function StepAdd({ distance, handleAdd, id, isFirst = false, isLast }) {
  const { t } = useTranslation();
  const [hidden, setHidden] = useState(true);

  return (
    <StepAddWrapper id={id}>
      <StepAddButton
        $isFirst={isFirst}
        $isLast={isLast}
        onClick={() => handleAdd(id, null, isFirst)}
      >
        <TagWrapper>
          <Add />
        </TagWrapper>
        {t('addStep')}
      </StepAddButton>
      {distance && (
        <DistanceTag>
          {!isLast && !isFirst && (
            <IconWrapper>
              <Distance />
            </IconWrapper>
          )}
          {distance} {t('away')}
          <InfosWrapper
            onMouseEnter={() => setHidden(false)}
            onMouseLeave={() => setHidden(true)}
          >
            <Info />
            {!hidden && (
              <InfoPopup className="animate__animated animate__fadeIn animate__faster">
                {t('distanceInfo')}
              </InfoPopup>
            )}
          </InfosWrapper>
        </DistanceTag>
      )}
    </StepAddWrapper>
  );
}

StepAdd.propTypes = {
  label: PropTypes.string,
};

export default StepAdd;
