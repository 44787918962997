import { Btn } from './SideCloseBtn.styles';
import close from 'assets/close.png';
import { useMap } from 'react-map-gl';
import { useLocation, useNavigate } from 'react-router';
import usePlaces from 'store/usePlaces';
import { placesZoom } from 'utils/constants';

const SideCloseBtn = ({ page }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { main_map } = useMap();
  const { setPlaces, setCitySelected, cities, withoutCommercialPlaces } =
    usePlaces();
  const isProgramResult = pathname.includes('program-result');

  const handleClick = () => {
    const zoomLevel = main_map?.getZoom();
    page?.current?.classList.add('animate__fadeOutLeft');

    setTimeout(() => {
      navigate('/');
      if (isProgramResult) {
        if (zoomLevel > placesZoom) {
          setCitySelected('all');
          setPlaces(withoutCommercialPlaces);
        } else {
          setCitySelected(false);
          setPlaces(cities);
        }
      }
    }, 600);
  };

  return (
    <Btn onClick={handleClick}>
      <img src={close} alt="close-button" />
    </Btn>
  );
};

export default SideCloseBtn;
