import PropTypes from 'prop-types';
import {
  CheckboxInput,
  CheckboxLabel,
  CheckboxWrapper,
} from './Checkbox.styles';

function Checkbox({ label, isChecked, onChange, isResult }) {
  return (
    <CheckboxWrapper checked={isChecked} isResult={isResult}>
      <CheckboxInput
        disabled={isResult}
        type="checkbox"
        name={label}
        checked={isChecked}
        onChange={onChange}
      />
      <CheckboxLabel>{label}</CheckboxLabel>
    </CheckboxWrapper>
  );
}

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
};

export default Checkbox;
