import React from 'react';
import PropTypes from 'prop-types';
import {
  InputButton,
  RedPoint,
  SearchInputWrapper,
  StyledInput,
} from './SearchInput.styles';
import Search from 'Icons/Search';

function SearchInput({ filterIsActive, isFocused, ...rest }) {
  return (
    <SearchInputWrapper>
      <StyledInput placeholder="search..." {...rest} $isFocused={isFocused} />
      <InputButton filterIsActive={filterIsActive}>
        {filterIsActive && <RedPoint />}
        <Search />
      </InputButton>
    </SearchInputWrapper>
  );
}

SearchInput.propTypes = {
  onClick: PropTypes.func,
};

export default SearchInput;
