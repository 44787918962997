import tw, { styled } from 'twin.macro';

export const Wrapper = tw.div`
 relative w-full min-h-screen isolate flex justify-center
`;

export const TopBars = styled.div(({ isDirections }) => [
  tw`
   absolute z-10 top-4 left-0 right-0 flex flex-wrap gap-x-2 gap-y-3 pl-4 items-center
  lg:(top-10 left-10 right-10 flex-row justify-start items-start gap-9) 
 `,
  isDirections && tw`hidden md:block`,
]);

export const BackDropTop = styled.div`
  ${tw`absolute top-0 w-full h-40 opacity-30`};
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
`;

export const BackDropBttom = styled.div`
  ${tw`hidden md:block absolute bottom-0 w-full h-24 opacity-30`};
  background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 100%);
`;

export const TripCreatorBtn = styled.button`
  ${tw`fixed left-6 bottom-6 lg:bottom-8 flex gap-3 justify-center items-center p-2 pr-6 bg-orange text-white rounded-full`}
  span {
    ${tw`bg-white text-black w-11 h-11 rounded-full flex justify-center items-center`}
  }
`;
