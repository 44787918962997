import { keyframes } from 'styled-components';
import tw, { styled } from 'twin.macro';
const FadeDown = keyframes`
  0% {opacity: 0; transform: translateY(-40px)};
  100% {opacity: 1; transform: translateY(0px)};
`;

export const Wrapper = styled.div`
  ${tw`w-full fixed z-50 bottom-0 left-0 top-[10rem] bg-white pt-10 rounded-2xl rounded-b-[0] 
       md:(absolute w-[37.5rem] pt-0 rounded-2xl top-[5.125rem] [bottom: unset]) 
       overflow-hidden outline-transparent`};
  animation: ${FadeDown} 0.5s ease-in backwards;
`;

export const Content = tw.div`
 h-full overflow-y-auto md:max-h-[70vh] 
`;

export const FilterWrapper = tw.div`
  w-full flex flex-col items-start gap-7 pt-7 px-3
  md:px-6 
`;

export const List = tw.div`
 flex flex-col w-full max-h-full gap-8  pb-2
 md:py-6  
`;
export const Tag = styled.button(({ isActive }) => [
  tw`flex justify-center items-center gap-1.5 px-4 py-2 rounded-full
     bg-[#ECECEC] text-black text-base font-medium`,
  isActive && tw`bg-orange text-white`,
]);

export const Icon = tw.button`
  absolute top-4 block md:hidden text-black left-1/2 -translate-x-1/2
`;

export const TagIcon = tw.span`
flex justify-center items-center w-4 h-4 shrink-0 text-center
`;

export const PlaceHolder = tw.div`
 justify-self-center flex flex-col justify-center items-center gap-6 opacity-50
`;

export const Empty = tw.p`
 text-2xl capitalize text-black text-center
`;
