const AddressIcon = () => {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.364 15.364L9 21.728L2.636 15.364C1.37734 14.1053 0.520187 12.5017 0.172928 10.7559C-0.17433 9.01004 0.00390685 7.20044 0.685099 5.55591C1.36629 3.91139 2.51984 2.50579 3.99988 1.51686C5.47992 0.52793 7.21998 9.15527e-05 9 9.15527e-05C10.78 9.15527e-05 12.5201 0.52793 14.0001 1.51686C15.4802 2.50579 16.6337 3.91139 17.3149 5.55591C17.9961 7.20044 18.1743 9.01004 17.8271 10.7559C17.4798 12.5017 16.6227 14.1053 15.364 15.364ZM9 13C10.0609 13 11.0783 12.5786 11.8284 11.8284C12.5786 11.0783 13 10.0609 13 9.00001C13 7.93915 12.5786 6.92173 11.8284 6.17159C11.0783 5.42144 10.0609 5.00001 9 5.00001C7.93914 5.00001 6.92172 5.42144 6.17158 6.17159C5.42143 6.92173 5 7.93915 5 9.00001C5 10.0609 5.42143 11.0783 6.17158 11.8284C6.92172 12.5786 7.93914 13 9 13ZM9 11C8.46957 11 7.96086 10.7893 7.58579 10.4142C7.21072 10.0392 7 9.53045 7 9.00001C7 8.46958 7.21072 7.96087 7.58579 7.5858C7.96086 7.21073 8.46957 7.00001 9 7.00001C9.53044 7.00001 10.0391 7.21073 10.4142 7.5858C10.7893 7.96087 11 8.46958 11 9.00001C11 9.53045 10.7893 10.0392 10.4142 10.4142C10.0391 10.7893 9.53044 11 9 11Z"
        fill="#B0B0B0"
      />
    </svg>
  );
};

export default AddressIcon;
