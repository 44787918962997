const MapIcon = () => {
  return (
    <svg
      width="192"
      height="192"
      viewBox="0 0 192 192"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.2" clipPath="url(#clip0_302_4008)">
        <circle opacity="0.2" cx="97" cy="102" r="95" fill="#D9D9D9" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M136.125 164.25C135.375 164.25 134.625 163.875 134.25 163.5L124.875 154.125C124.125 153.375 124.125 151.875 124.875 151.125C126 150 127.125 150 128.25 151.125L137.625 160.5C138.375 161.25 138.375 162.75 137.625 163.5C137.25 163.875 136.5 164.25 136.125 164.25Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M157.125 192C154.875 192 152.625 191.25 151.125 189.375L134.25 172.875C132.75 171 132 168.75 132 166.5C132 164.25 132.75 162 134.25 160.5C136.125 158.625 138.375 157.875 140.625 157.875C142.875 157.875 145.125 158.625 146.625 160.5L163.5 177C165 178.875 166.125 181.125 166.125 183.375C166.125 185.625 165 187.875 163.5 189.375C161.625 191.25 159.75 192 157.125 192ZM137.625 163.5C136.875 164.25 136.5 165.375 136.5 166.5C136.5 167.625 136.875 168.75 137.625 169.5L154.125 186.375C155.25 187.125 156 187.5 157.125 187.5C158.25 187.5 159.375 187.125 160.125 186.375C160.875 185.625 161.625 184.5 161.625 183.375C161.625 182.25 160.875 181.125 160.125 180.375L143.625 163.5C142.875 162.75 141.75 162.375 140.625 162.375C139.5 162.375 138.375 162.75 137.625 163.5Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M100.875 165.375C90.75 165.375 81 161.625 73.5 154.125C58.5 139.125 58.5 114.75 73.5 99.75C81 92.25 90.75 88.5 100.875 88.5C111.375 88.5 120.75 92.25 128.25 99.75C135.375 106.875 139.5 116.625 139.5 127.125C139.5 137.25 135.375 147 128.25 154.125C120.75 161.625 111.375 165.375 100.875 165.375ZM100.875 93C91.875 93 83.25 96.375 76.875 102.75C63.75 116.25 63.75 137.625 76.875 151.125C83.25 157.5 91.875 160.875 100.875 160.875C109.875 160.875 118.5 157.5 124.875 151.125C131.25 144.75 135 136.125 135 127.125C135 117.75 131.25 109.5 124.875 102.75C118.5 96.375 109.875 93 100.875 93Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M100.875 153.375C93.75 153.375 87 150.75 82.125 145.875C72 135.375 72 118.5 82.125 108.375C87 103.125 93.75 100.5 100.875 100.5C108 100.5 114.75 103.125 119.625 108.375C130.125 118.5 130.125 135.375 119.625 145.875C114.75 150.75 108 153.375 100.875 153.375ZM100.875 105C94.875 105 89.625 107.25 85.5 111.375C76.875 120 76.875 133.875 85.5 142.5C89.625 146.625 94.875 148.875 100.875 148.875C106.875 148.875 112.5 146.625 116.625 142.5C125.25 133.875 125.25 120 116.625 111.375C112.5 107.25 106.875 105 100.875 105Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M77.625 77.25C76.875 77.25 76.5 76.875 75.75 76.5C62.25 62.625 50.625 49.5 50.625 27C50.625 12 62.625 0 77.625 0C92.25 0 104.25 12 104.25 27C104.25 49.5 93 62.625 79.125 76.5C78.75 76.875 78 77.25 77.625 77.25ZM77.625 4.5C65.25 4.5 55.125 14.625 55.125 27C55.125 45 63 57 77.625 71.625C91.875 57 99.75 45 99.75 27C99.75 14.625 90 4.5 77.625 4.5Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M77.625 42.375C70.125 42.375 64.125 36.375 64.125 28.875C64.125 21.75 70.125 15.75 77.625 15.75C84.75 15.75 90.75 21.75 90.75 28.875C90.75 36.375 84.75 42.375 77.625 42.375ZM77.625 20.25C72.75 20.25 68.625 24 68.625 28.875C68.625 33.75 72.75 37.875 77.625 37.875C82.5 37.875 86.25 33.75 86.25 28.875C86.25 24 82.5 20.25 77.625 20.25Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.5 142.875C40.125 142.875 39.75 142.875 39.375 142.5L2.625 124.5H2.25C1.875 124.125 1.5 123.75 1.5 123.375L1.125 123C1.125 122.625 1.125 122.625 1.125 122.25V46.5C1.125 45.75 1.5 45 2.25 44.625C3 44.25 3.75 44.25 4.5 44.25L40.5 62.25L59.625 52.875C60.75 52.125 61.875 52.5 62.625 53.625C63 54.75 62.625 56.25 61.5 56.625L41.625 66.75C41.25 66.75 41.25 66.75 41.25 66.75C40.875 66.75 40.875 66.75 40.5 66.75C40.125 66.75 39.75 66.75 39.75 66.75H39.375L5.625 49.875V121.125L40.5 138L63.75 126.75C64.875 126.375 66 126.75 66.75 127.875C67.125 129 66.75 130.125 65.625 130.875L41.625 142.5C41.25 142.5 41.25 142.5 41.25 142.875C40.875 142.875 40.875 142.875 40.875 142.875C40.5 142.875 40.5 142.875 40.5 142.875ZM188.625 142.875H188.25C187.875 142.875 187.875 142.5 187.5 142.5L151.5 124.875L138 131.625C136.875 132 135.75 131.625 135 130.5C134.625 129.375 135 127.875 136.125 127.5L150.375 120.375H150.75C150.75 120.375 151.125 120 151.5 120C151.875 120 152.25 120.375 152.25 120.375H152.625L186.375 136.875V66L151.5 49.125L115.5 66.75C115.125 66.75 114.75 66.75 114.375 66.75C114 66.75 114 66.75 113.625 66.75L93.375 56.625C92.25 56.25 91.875 54.75 92.25 53.625C93 52.5 94.125 52.125 95.25 52.875L114.375 62.25L150.375 44.25H150.75H151.5H152.25H152.625L189.375 62.625H189.75L190.5 63.375C190.5 63.75 190.875 64.125 190.875 64.125V64.5V140.625V141C190.875 141.375 190.5 141.75 190.5 141.75C190.125 142.125 190.125 142.125 189.75 142.5C189.375 142.5 189 142.875 188.625 142.875Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.5 142.875C39.375 142.875 38.25 141.75 38.25 140.625V64.5C38.25 63.375 39.375 62.25 40.5 62.25C41.625 62.25 42.75 63.375 42.75 64.5V140.625C42.75 141.75 41.625 142.875 40.5 142.875Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M77.625 101.625C76.125 101.625 75.375 100.5 75.375 99.375V75C75.375 73.875 76.125 72.75 77.625 72.75C78.75 72.75 79.875 73.875 79.875 75V99.375C79.875 100.5 78.75 101.625 77.625 101.625Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M151.5 124.5C150.375 124.5 149.25 123.75 149.25 122.25V46.5C149.25 45.375 150.375 44.25 151.5 44.25C152.625 44.25 153.75 45.375 153.75 46.5V122.25C153.75 123.75 152.625 124.5 151.5 124.5Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M114.375 95.625C113.25 95.625 112.125 94.5 112.125 93.375V64.5C112.125 63.375 113.25 62.25 114.375 62.25C115.875 62.25 116.625 63.375 116.625 64.5V93.375C116.625 94.5 115.875 95.625 114.375 95.625Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_302_4008">
          <rect width="192" height="192" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MapIcon;
