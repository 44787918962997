import StartPoint from 'components/StartPoint';
import { format } from 'date-fns';
import { getBounds } from 'geolib';
import { useCurrentPosition } from 'hooks/useCurrentPosition';
import Car from 'Icons/Car';
import Legs from 'Icons/Legs';

import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl';
import { getDirections } from 'services';
import { ROUTING_PROFILES } from 'utils/constants';
import { getDistanceWithUnit } from 'utils/helpers';
import {
  Distance,
  IconWrapper,
  Mode,
  ModeInfos,
  TravelModes,
  Wrapper,
} from './Directions.styles';
import useBrekpoint from 'hooks/useBrekpoint';

const Directions = ({ poi, setPlaces, toggleIsDirection, isDirections }) => {
  const { isSmallDevice, heigth } = useBrekpoint();
  const [position, error] = useCurrentPosition();
  const [startingPoint, setStartingPoint] = useState(null);
  const [travelMode, setTravelMode] = useState('driving');
  const [routes, setRoutes] = useState({});

  const { t } = useTranslation();
  const { main_map } = useMap();

  const headTo = useCallback(
    (coordsArr) => {
      if (coordsArr?.length > 0) {
        const { minLat, maxLat, minLng, maxLng } = getBounds(coordsArr);
        main_map?.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          {
            padding: isSmallDevice
              ? { left: 0, top: 50, bottom: (heigth * 65) / 100 }
              : { top: 200, bottom: 100, left: 250 },
            curve: 2,
            speed: 0.5,
          },
        );
      }
    },
    [main_map, isSmallDevice, heigth],
  );

  const drawRoute = useCallback(
    (routeCoords) => {
      const geojson = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: routeCoords,
        },
      };

      if (main_map?.getSource('route')) {
        main_map?.getSource('route').setData(geojson);
      } else {
        main_map?.getMap().addSource('route', {
          type: 'geojson',
          data: geojson,
        });

        main_map?.getMap().addLayer({
          id: 'route',
          type: 'line',
          source: 'route',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#E18C3C',
            'line-width': 8,
          },
        });
      }
    },
    [main_map],
  );

  const getProfileIcon = (profile) => {
    switch (profile) {
      case 'driving':
        return <Car />;
      case 'walking':
        return <Legs />;
      case 'cycling':
        return <Car />;
      default:
        break;
    }
  };

  const getAllRoutes = async (startPoint) => {
    const endPoint = poi.location?.coordinates?.join(', ');
    for (const profile of ROUTING_PROFILES) {
      const directions = await getDirections(profile, [startPoint, endPoint]);
      setRoutes((prev) => ({ ...prev, [profile]: directions?.routes[0] }));
    }
  };

  const handleChooseLocation = async (location) => {
    setStartingPoint(location);
    const startPoint = location?.longitude + ', ' + location?.latitude;
    getAllRoutes(startPoint);
  };

  const handleUseMyLocation = async () => {
    if (!error && position?.coords) {
      const { latitude, longitude } = position?.coords;
      const startPoint = `${longitude}, ${latitude}`;
      const date = format(new Date(position.timestamp), 'MMMM do. HH:mm a z');
      setStartingPoint({
        latitude,
        longitude,
        date,
        type: 'user',
      });

      getAllRoutes(startPoint);
    } else if (error) {
      alert(error);
    }
  };

  const chooseMode = (profile) => {
    setTravelMode(profile);
  };

  //draw a line whenever the travel mode or routes changes change
  useEffect(() => {
    if (routes[travelMode]) {
      const coords = routes[travelMode]?.geometry?.coordinates;
      drawRoute(coords);
      headTo(routes[travelMode]?.geometry?.coordinates);
      setPlaces([poi]);
    }
  }, [routes, travelMode, drawRoute, headTo, setPlaces, poi, main_map]);

  const formatDuration = (duration) => {
    const day = parseInt(duration / (24 * 3600));

    duration = duration % (24 * 3600);
    const hour = parseInt(duration / 3600).toFixed(0);

    duration %= 3600;
    const min = (duration / 60).toFixed(0);

    if (day === 0) {
      return `${hour} Hr ${min} min`;
    } else if (hour === 0) {
      return `${min} min`;
    } else {
      return `${day} ${t('day')} ${hour} Hr ${min} min`;
    }
  };

  useEffect(() => {
    return () => {
      if (main_map?.getLayer('route') && main_map?.getSource('route')) {
        main_map?.getMap().removeLayer('route');
        main_map?.getMap()?.removeSource('route');
      }
      if (isDirections) {
        toggleIsDirection(false);
      }
    };
  }, [main_map, isDirections, toggleIsDirection]);

  return (
    <Wrapper>
      <StartPoint
        startingPoint={startingPoint}
        handleUseMyLocation={handleUseMyLocation}
        handleChooseLocation={handleChooseLocation}
      />
      {Object.keys(routes)?.length > 0 && (
        <TravelModes>
          {ROUTING_PROFILES.map((profile) => (
            <Mode
              key={profile}
              onClick={() => chooseMode(profile)}
              isActive={profile === travelMode}
            >
              <IconWrapper>{getProfileIcon(profile)}</IconWrapper>
              <ModeInfos>
                {formatDuration(routes[profile]?.duration)}
                <Distance>
                  {getDistanceWithUnit(routes[profile]?.distance)}
                </Distance>
              </ModeInfos>
            </Mode>
          ))}
        </TravelModes>
      )}
    </Wrapper>
  );
};

export default Directions;
