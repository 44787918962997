const BigPrintIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_1858_8095)">
        <path
          d="M10.3118 12.4949H5.68959C5.39506 12.4949 5.15625 12.7337 5.15625 13.0282C5.15625 13.3228 5.39503 13.5616 5.68959 13.5616H10.3118C10.6063 13.5616 10.8452 13.3228 10.8452 13.0282C10.8452 12.7337 10.6064 12.4949 10.3118 12.4949Z"
          fill="white"
        />
        <path
          d="M10.3118 10.8127H5.68959C5.39506 10.8127 5.15625 11.0515 5.15625 11.3461C5.15625 11.6406 5.39503 11.8794 5.68959 11.8794H10.3118C10.6063 11.8794 10.8452 11.6406 10.8452 11.3461C10.8452 11.0515 10.6064 10.8127 10.3118 10.8127Z"
          fill="white"
        />
        <path
          d="M14.7556 4.18452H13.0586V1.0978C13.0586 0.803266 12.8198 0.564453 12.5253 0.564453H3.47475C3.18022 0.564453 2.94141 0.803234 2.94141 1.0978V4.18452H1.24444C0.55825 4.18452 0 4.7428 0 5.42898V10.7947C0 11.4809 0.55825 12.0391 1.24444 12.0391H2.9415V14.902C2.9415 15.1966 3.18028 15.4354 3.47484 15.4354H12.5252C12.8197 15.4354 13.0585 15.1966 13.0585 14.902V12.0391H14.7556C15.4418 12.0391 16 11.4809 16 10.7947V5.42898C16 4.74283 15.4418 4.18452 14.7556 4.18452ZM4.00806 1.63114H11.9919V4.18452H4.00806V1.63114ZM11.9918 14.3687H4.00819C4.00819 14.2645 4.00819 10.1342 4.00819 10.0053H11.9918C11.9918 10.1375 11.9918 14.2685 11.9918 14.3687ZM12.5253 7.31623H11.1677C10.8731 7.31623 10.6343 7.07745 10.6343 6.78289C10.6343 6.48833 10.8731 6.24955 11.1677 6.24955H12.5253C12.8198 6.24955 13.0586 6.48833 13.0586 6.78289C13.0586 7.07745 12.8198 7.31623 12.5253 7.31623Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1858_8095">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BigPrintIcon;
