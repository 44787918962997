import tw, { styled } from 'twin.macro';

export const CheckboxWrapper = styled.label(({ checked, isResult }) => [
  tw`inline-flex items-center bg-white rounded-[1.313rem] gap-[0.313rem]
     border border-[#00000010] px-[1.5rem] py-[0.625rem] cursor-pointer `,
  checked && tw`bg-green border-green`,
  isResult && tw`grayscale`,
]);

export const CheckboxLabel = tw.span`
   font-normal text-base leading-[1.375rem] capitalize whitespace-nowrap cursor-pointer
`;

export const CheckboxInput = styled.input`
  -webkit-appearance: none;
  flex-shrink: 0;
  appearance: none;
  width: 1rem;
  height: 1rem;
  border: 2px solid #7a7b7c;
  border-radius: 5px;
  outline: none;
  cursor: pointer;

  :checked {
    background-color: #444d14;
  }
  :disabled {
    cursor: default;
  }
`;

export const CheckboxSpan = tw.span`
  
`;
