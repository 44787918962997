import tw, { styled } from 'twin.macro';
export const variants = {
  primary: tw`bg-orange text-white disabled:bg-[#C9C9C9] min-w-[7.2rem]`,
  secondary: tw`text-orange bg-white border-2 border-orange 
  disabled:text-[#C9C9C9] disabled:border-[#C9C9C9] min-w-[7.2rem]`,
  roundedOrange: tw`w-[3rem]  h-[3rem] rounded-full  bg-orange text-white`,
  roundedBlue: tw`w-[1.625rem] h-[1.625rem] lg:(w-[2.375rem]  h-[2.375rem]) rounded-full  bg-blue text-white disabled:bg-[#CCCCCC] duration-700`,
  roundedWhite: tw`w-[2.875rem]  h-[2.875rem] rounded-full p-0 bg-white [box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25)]`,
  square: tw`w-14 h-14 rounded-2xl bg-[#EBEBEB] shadow-sm shrink-0 border-[1px] border-[#CCCCCC]`,
};

export const sizes = {
  small: tw`py-3 px-8 font-medium`,
  large: tw`py-4 font-bold`,
};

export const StyledBtn = styled.button(({ width }) => [
  tw`flex justify-center items-center gap-2 rounded-[1.875rem] text-lg capitalize
     transition-all duration-300`,
  ({ variant }) => variants[variant],
  ({ size }) => size && sizes[size],
  width && `width: ${width};`,
]);
