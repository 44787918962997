import { useLocation } from 'react-router-dom';

import * as analytics from 'utils/google-analytics';
import { useEffect } from 'react';

export function useAnalytics() {
  const location = useLocation();

  useEffect(() => {
    analytics.initializeGoogleAnalytics();
  }, []);

  useEffect(() => {
    const path = location.pathname + location.search;
    analytics.trackPageView(path);
  }, [location]);
}

export default useAnalytics;
