import Filter from 'components/Filter';
import MapComponent from 'components/MapComponent';
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { getAllCities, getAllPlaces, getPrograms, getTags } from 'services';
import usePlaces from 'store/usePlaces';
import {
  BackDropBttom,
  BackDropTop,
  TopBars,
  TripCreatorBtn,
  Wrapper,
} from './Home.styles';
import PopularPlaces from 'components/PopularPlaces';
import useTags from 'store/useTags';
import Search from 'components/Search';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Trip from 'Icons/Trip';

import {
  COMMERCIAL_TAGS,
  MAIN_FILTER_TAGS,
  POPULAR_SLUG,
  PREFERENCES_SLUGS,
  SEARCH_SLUGS,
} from 'utils/constants';
import LangSwitcher from 'components/LangSwitcher';
import useSearchFilter from 'store/useSearchFilter';
import useDirections from 'store/useDirections';
import { getDistance } from 'geolib';
import { getDistanceWithUnit } from 'utils/helpers';
import useRecommended from 'store/useRecommended';
import UpdatePopup from 'components/UpdatesPopup';
import useServiceWorker from 'store/useServiceWorker';
import useAnalytics from 'hooks/useAnalytics';

export default function Home() {
  useAnalytics();

  const {
    setCities,
    setPlaces,

    cityIsSelected,
    allPlaces,
    popPlaces,
    setAllPlaces,
    setSeePlaces,
    setPopPlaces,
    setWithoutCommercialPlaces,
    setCitySelected,
  } = usePlaces();
  const { setTags, tags, setPreferencesTags, resetActiveTags } = useTags();
  const { setSearchFilters } = useSearchFilter();
  const { isDirections } = useDirections();
  const { setPrograms, getDays } = useRecommended();
  const { updated } = useServiceWorker();

  const { data } = useQuery('places', getAllPlaces);
  const { data: fetchedTags } = useQuery('tags', getTags);
  const { data: fetchedCities } = useQuery('cities', getAllCities, {
    refetchOnWindowFocus: false,
  });
  const { data: fetchedPrograms } = useQuery('programs', getPrograms);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const goToCreateTrip = () => {
    navigate('create-program/trip-planer/1');
  };

  //set the places
  useEffect(() => {
    if (data?.data) {
      const filteredPlaces = data.data.filter(
        (place) =>
          place.location?.coordinates?.length !== 0 &&
          !place.location?.coordinates?.includes(null) &&
          place.location?.coordinates[0] < 0 &&
          place.location?.coordinates[0] > -20 &&
          place.location?.coordinates[1] > 15 &&
          place.location?.coordinates[1] < 40,
      );

      const popPlaces = filteredPlaces?.filter((place) => {
        const popTagIndex = place.tags.findIndex(
          (tag) => tag.slug === POPULAR_SLUG,
        );
        return popTagIndex > -1;
      });

      const withoutCommercialPlaces = filteredPlaces?.filter(
        (place) =>
          !place.tags.some((tag) => COMMERCIAL_TAGS.includes(tag.slug)),
      );

      const seePlaces = filteredPlaces?.filter(
        (place) => !place.tags.some((tag) => SEARCH_SLUGS.includes(tag.slug)),
      );
      setAllPlaces(filteredPlaces);
      setPopPlaces(popPlaces);
      setWithoutCommercialPlaces(withoutCommercialPlaces);
      setSeePlaces(seePlaces);
    }
  }, [
    data?.data,
    setAllPlaces,
    setPopPlaces,
    setSeePlaces,
    setWithoutCommercialPlaces,
  ]);

  //set the filters
  useEffect(() => {
    const getCustomTags = (tags, customSlugs) =>
      tags.filter((tag) => customSlugs.includes(tag.slug));

    if (fetchedTags) {
      const filteredTags = fetchedTags.data
        .filter((tag) => MAIN_FILTER_TAGS.includes(tag.slug))
        .sort((a, b) => a.order - b.order);

      setTags(filteredTags);
      setPreferencesTags(getCustomTags(fetchedTags.data, PREFERENCES_SLUGS));
      setSearchFilters(getCustomTags(fetchedTags.data, SEARCH_SLUGS));
    }
  }, [fetchedTags, setTags, setPreferencesTags, setSearchFilters]);

  //set the cities
  useEffect(() => {
    if (fetchedCities) {
      setCities(
        fetchedCities.data.sort((a, b) => a.slug.length - b.slug.length),
      );
      setPlaces(fetchedCities.data);
    }
  }, [fetchedCities, setPlaces, setCities, setCitySelected]);

  useEffect(() => {
    resetActiveTags();
  }, [resetActiveTags]);

  //set the programs
  useEffect(() => {
    if (fetchedPrograms && fetchedPrograms.data?.length > 0) {
      const adaptedPrograms = fetchedPrograms.data.map((program) => {
        const image = program.places[0]?.media[0]?.url;
        const firstPlaceCoords = {
          latitude: program.places[0]?.location.coordinates[1],
          longitude: program.places[0]?.location.coordinates[0],
        };

        const lastPlaceCoords = {
          latitude: program.places.slice(-1)[0]?.location?.coordinates[1],
          longitude: program.places.slice(-1)[0]?.location?.coordinates[0],
        };

        const distance = getDistanceWithUnit(
          getDistance(firstPlaceCoords, lastPlaceCoords) * 1.4,
        );
        const regions = [];
        program.places.forEach((place) => {
          const regionSlug = place?.contact?.address?.city;
          if (regionSlug && !regions.includes(regionSlug)) {
            regions.push(regionSlug);
          }
        });

        const days = getDays(program.places);

        return {
          id: program._id,
          title: program.title,
          image,
          distance,
          regions: regions.length,
          days: days.length,
          program: days,
        };
      });
      setPrograms(adaptedPrograms);
    }
  }, [setPrograms, fetchedPrograms, getDays]);

  const withoutFilter = useMemo(() => {
    const pagesToRestrict = [
      'program-result',
      'program',
      'shared',
      'create-program',
      'poi',
      'suggestion-previw',
    ];
    const page = location.pathname.split('/')[1];
    if (pagesToRestrict.includes(page)) return true;
    return false;
  }, [location.pathname]);

  return (
    <Wrapper>
      <Outlet />
      <MapComponent />
      <BackDropTop />
      <BackDropBttom />
      <TopBars isDirections={isDirections}>
        <LangSwitcher />
        <Search places={allPlaces} />
        <PopularPlaces
          filterExist={!withoutFilter}
          isDirections={isDirections}
          selectedCity={cityIsSelected}
          places={popPlaces}
        />

        {tags && !withoutFilter && !isDirections && <Filter tags={tags} />}
      </TopBars>

      <TripCreatorBtn onClick={goToCreateTrip}>
        <span>
          <Trip />
        </span>
        {t('circuitCreator')}
      </TripCreatorBtn>
      {updated && <UpdatePopup />}
    </Wrapper>
  );
}
