const Send = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.946815 7.31501C0.424815 7.14101 0.419815 6.86001 0.956815 6.68101L20.0438 0.31901C20.5728 0.14301 20.8758 0.43901 20.7278 0.95701L15.2738 20.043C15.1238 20.572 14.8188 20.59 14.5948 20.088L11.0008 12L17.0008 4.00001L9.00081 10L0.946815 7.31501Z"
        fill="#B5CD3A"
      />
    </svg>
  );
};

export default Send;
