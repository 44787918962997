import tw, { styled } from 'twin.macro';

export const sizes = {
  lg: tw`text-2xl font-bold leading-7 tracking-[-0.063rem]`,
  xl: tw`text-lg lg:text-xl font-bold leading-[1.513rem]`,
  md: tw`text-lg font-bold leading-[1.375rem]`,
  sm: tw`text-base font-medium tracking-[-0.5px]
         md:font-bold `,
};
export const StyledTitle = styled.h1(() => [
  tw`capitalize `,
  ({ size }) => sizes[size],
]);
