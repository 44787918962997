import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { ProgramSaveWrapper, TagWrapper } from './ProgramSave.styles';
import { useTranslation } from 'react-i18next';
import BookMark from 'Icons/BookMark';

const ProgramSave = forwardRef(({ setSaveIsOpen, isAtTop }, ref) => {
  const { t } = useTranslation();

  return (
    <ProgramSaveWrapper
      isAtTop={isAtTop}
      ref={ref}
      onClick={() => setSaveIsOpen(true)}
    >
      <TagWrapper>
        <BookMark />
      </TagWrapper>
      {t('saveProgram')}
    </ProgramSaveWrapper>
  );
});

ProgramSave.propTypes = {
  label: PropTypes.string,
};

export default ProgramSave;
