import tw, { styled } from 'twin.macro';

export const Wrapper = tw.div`
   bg-white flex flex-col justify-center items-center gap-14
   w-full py-8 rounded-xl [box-shadow: 6px 8px 22px rgba(0, 0, 0, 0.08)]

`;

export const ShareOption = tw.div`
   w-full flex flex-col justify-center items-center gap-4 first:px-6
   md:px-6
`;

export const NavigationBtn = tw.button`
  hidden md:flex justify-center items-center w-10 h-10 rounded-full shrink-0
  bg-[#EAEAEA] [box-shadow: 1px 5px 4px -1px rgba(0, 0, 0, 0.12)] text-black mb-4
`;

export const SwiperWrapper = tw.div`
  w-full flex justify-center items-center gap-6
`;

export const CopyWrapper = tw.form`
  relative w-full max-w-[32rem] flex justify-center items-center gap-1.5 
`;

export const Link = tw.input`
   w-full overflow-hidden whitespace-nowrap text-ellipsis p-4 pr-10
   text-opacity-40 text-black text-base tracking-tight leading-[1.25rem]  
   bg-[#F7F7F7] border-2 border-[#B9B9B9] rounded-lg cursor-default
`;

export const CopyButton = styled.button`
  ${tw`absolute right-3.5 top-1/2 -translate-y-1/2 shrink-0 flex justify-center items-center rounded-lg cursor-copy`}
  &:hover:after {
    ${tw`[content: 'copy link'] absolute z-10 top-full left-1/2 -translate-x-1/2
    w-20 py-1.5 mt-2 bg-black text-white text-center text-sm rounded-lg`}
  }
`;

export const MediaWrapper = tw.div`
  flex flex-col justify-center items-center gap-2 text-opacity-50
  text-base text-black font-medium capitalize tracking-tight first:pl-4 md:first:pl-0
`;
export const Media = styled.a`
  ${tw`w-[4.25rem] h-[4.25rem] flex items-center justify-center text-white rounded-xl`};
  background: ${({ bgColor }) => bgColor};
`;
