const Close = () => {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.579 0.870637C10.9484 0.240102 9.92612 0.240102 9.29558 0.870637L6.31284 3.85338L2.9179 0.458444C2.3897 -0.0697553 1.53332 -0.0697563 1.00512 0.458443C0.47692 0.986642 0.476921 1.84302 1.00512 2.37122L4.40006 5.76616L1.41731 8.7489C0.786779 9.37944 0.786779 10.4017 1.41731 11.0323C2.04785 11.6628 3.07015 11.6628 3.70068 11.0323L6.68343 8.04953L10.0784 11.4445C10.6066 11.9727 11.4629 11.9727 11.9911 11.4445C12.5193 10.9163 12.5193 10.0599 11.9911 9.53169L8.59621 6.13675L11.579 3.15401C12.2095 2.52347 12.2095 1.50117 11.579 0.870637Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Close;
