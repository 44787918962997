const Plus = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.214844 6.43176C0.214844 7.13426 0.784337 7.70376 1.48684 7.70376L5.18285 7.70376L5.18285 11.3998C5.18285 12.036 5.69861 12.5518 6.33484 12.5518C6.97108 12.5518 7.48684 12.036 7.48684 11.3998L7.48684 7.70376L11.1828 7.70376C11.8854 7.70376 12.4548 7.13426 12.4548 6.43176C12.4548 5.72925 11.8854 5.15976 11.1828 5.15976L7.48684 5.15976L7.48684 1.46376C7.48684 0.827524 6.97108 0.311757 6.33484 0.311757C5.69861 0.311757 5.18285 0.827525 5.18285 1.46376L5.18285 5.15976L1.48684 5.15976C0.784338 5.15976 0.214844 5.72925 0.214844 6.43176Z"
        fill="white"
      />
    </svg>
  );
};

export default Plus;
