const Share = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0289 10.5096C11.8817 10.5081 12.6935 10.8507 13.2545 11.4491C13.8154 12.0475 14.0705 12.8429 13.9541 13.6299C13.8375 14.417 13.3607 15.1184 12.6468 15.5529C11.9329 15.9874 11.0518 16.1126 10.2314 15.896C9.4108 15.6794 8.73127 15.1423 8.36825 14.4234C8.00517 13.7045 7.99427 12.8744 8.33828 12.1474L4.77923 10.0078C4.19996 10.4406 3.4663 10.6533 2.72566 10.6028C1.98496 10.5525 1.29231 10.2428 0.786649 9.73611C0.281066 9.22951 0 8.56333 0 7.87154C0 7.17981 0.281066 6.51363 0.786649 6.00697C1.29231 5.5003 1.98496 5.19071 2.72566 5.14026C3.46628 5.08989 4.19994 5.30251 4.77923 5.73529L8.26236 3.64214V3.64221C7.95155 2.80169 8.09653 1.8731 8.6514 1.14991C9.20627 0.426722 10.1024 -0.00170708 11.0565 4.76837e-06C12.0108 0.00173187 12.9051 0.4335 13.4569 1.15868C14.0087 1.88393 14.1497 2.81301 13.8354 3.65245C13.5211 4.49174 12.7903 5.13749 11.874 5.38565C10.9577 5.63382 9.96916 5.45365 9.22089 4.90208L5.72115 7.00407C5.92544 7.566 5.92544 8.17498 5.72115 8.73691L9.42257 10.9472C9.90075 10.6585 10.4591 10.5064 11.0288 10.5096L11.0289 10.5096Z"
        fill="black"
      />
    </svg>
  );
};

export default Share;
