const Triangle = ({ color }) => {
  return (
    <svg
      width="16"
      height="8"
      viewBox="0 0 16 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 8L0 0H16L8 8Z" fill={color} />
    </svg>
  );
};

export default Triangle;
