const Calender = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.78306 6.96993H9.32303C9.91809 6.96993 10.4005 6.48867 10.4005 5.89247V1.57747C10.3993 0.982533 9.91676 0.5 9.32186 0.5H8.78306C8.18683 0.5 7.70312 0.982567 7.70312 1.57747V5.89113C7.70312 6.48867 8.18686 6.9699 8.78306 6.9699V6.96993Z"
        fill="currentColor"
      />
      <path
        d="M23.7116 6.96993H24.2515C24.8466 6.96993 25.329 6.48867 25.329 5.89247V1.57747C25.329 0.982533 24.8466 0.5 24.2515 0.5H23.7116C23.1153 0.5 22.6328 0.982567 22.6328 1.57747V5.89113C22.6328 6.48867 23.1154 6.9699 23.7116 6.9699V6.96993Z"
        fill="currentColor"
      />
      <path
        d="M13.4479 12.445C13.4479 12.1787 13.2292 11.96 12.9617 11.96H10.1854C9.91797 11.96 9.69922 12.1787 9.69922 12.445V14.5537C9.69922 14.8225 9.91667 15.0399 10.1854 15.0399H12.9617C13.2292 15.0399 13.4479 14.8225 13.4479 14.5537V12.445Z"
        fill="currentColor"
      />
      <path
        d="M18.3837 12.445C18.3837 12.1787 18.1663 11.96 17.8987 11.96H15.1712C14.905 11.96 14.6875 12.1787 14.6875 12.445V14.5537C14.6875 14.8225 14.9049 15.0399 15.1712 15.0399H17.8987C18.1661 15.0399 18.3837 14.8225 18.3837 14.5537V12.445Z"
        fill="currentColor"
      />
      <path
        d="M20.1139 15.039H22.8414C23.1089 15.039 23.3264 14.8215 23.3264 14.5528V12.444C23.3264 12.1777 23.1102 11.959 22.8414 11.959H20.1139C19.8477 11.959 19.6289 12.1777 19.6289 12.444V14.5528C19.6289 14.8214 19.8477 15.039 20.1139 15.039Z"
        fill="currentColor"
      />
      <path
        d="M25.0565 15.039H27.7815C28.049 15.039 28.2677 14.8215 28.2677 14.5528V12.444C28.2677 12.1777 28.0503 11.959 27.7815 11.959H25.0565C24.7891 11.959 24.5703 12.1777 24.5703 12.444V14.5528C24.5702 14.8214 24.7889 15.039 25.0565 15.039Z"
        fill="currentColor"
      />
      <path
        d="M8.45665 16.7604C8.45665 16.493 8.2392 16.2754 7.96915 16.2754H5.24531C4.97787 16.2754 4.75781 16.4928 4.75781 16.7604V18.868C4.75781 19.1354 4.97656 19.353 5.24531 19.353H7.96901C8.23776 19.353 8.45651 19.1356 8.45651 18.868L8.45665 16.7604Z"
        fill="currentColor"
      />
      <path
        d="M13.4479 16.7604C13.4479 16.493 13.2292 16.2754 12.9617 16.2754H10.1854C9.91797 16.2754 9.69922 16.4928 9.69922 16.7604V18.868C9.69922 19.1354 9.91667 19.353 10.1854 19.353H12.9617C13.2292 19.353 13.4479 19.1356 13.4479 18.868V16.7604Z"
        fill="currentColor"
      />
      <path
        d="M18.3837 16.7604C18.3837 16.493 18.1663 16.2754 17.8987 16.2754H15.1712C14.905 16.2754 14.6875 16.4928 14.6875 16.7604V18.868C14.6875 19.1354 14.9049 19.353 15.1712 19.353H17.8987C18.1661 19.353 18.3837 19.1356 18.3837 18.868V16.7604Z"
        fill="currentColor"
      />
      <path
        d="M19.6289 18.867C19.6289 19.1345 19.8464 19.352 20.1139 19.352H22.8414C23.1089 19.352 23.3264 19.1346 23.3264 18.867V16.7594C23.3264 16.492 23.1102 16.2744 22.8414 16.2744H20.1139C19.8477 16.2744 19.6289 16.4919 19.6289 16.7594V18.867Z"
        fill="currentColor"
      />
      <path
        d="M24.5703 18.867C24.5703 19.1345 24.7891 19.352 25.0565 19.352H27.7815C28.049 19.352 28.2677 19.1346 28.2677 18.867V16.7594C28.2677 16.492 28.0503 16.2744 27.7815 16.2744H25.0565C24.7891 16.2744 24.5703 16.4919 24.5703 16.7594V18.867Z"
        fill="currentColor"
      />
      <path
        d="M8.45665 21.0759C8.45665 20.8071 8.2392 20.5908 7.96915 20.5908H5.24531C4.97787 20.5908 4.75781 20.8058 4.75781 21.0759V23.1834C4.75781 23.4509 4.97656 23.6685 5.24531 23.6685H7.96901C8.23776 23.6685 8.45651 23.4522 8.45651 23.1834L8.45665 21.0759Z"
        fill="currentColor"
      />
      <path
        d="M13.4479 21.0759C13.4479 20.8071 13.2292 20.5908 12.9617 20.5908H10.1854C9.91797 20.5908 9.69922 20.8058 9.69922 21.0759V23.1834C9.69922 23.4509 9.91667 23.6685 10.1854 23.6685H12.9617C13.2292 23.6685 13.4479 23.4522 13.4479 23.1834V21.0759Z"
        fill="currentColor"
      />
      <path
        d="M18.3837 21.0759C18.3837 20.8071 18.1663 20.5908 17.8987 20.5908H15.1712C14.905 20.5908 14.6875 20.8058 14.6875 21.0759V23.1834C14.6875 23.4509 14.9049 23.6685 15.1712 23.6685H17.8987C18.1661 23.6685 18.3837 23.4522 18.3837 23.1834V21.0759Z"
        fill="currentColor"
      />
      <path
        d="M7.97031 24.9062H5.24531C4.97787 24.9062 4.75781 25.1225 4.75781 25.3913V27.4989C4.75781 27.7663 4.97656 27.9839 5.24531 27.9839H7.96901C8.23776 27.9839 8.45651 27.7664 8.45651 27.4989V25.3913C8.45664 25.1237 8.23907 24.9062 7.97031 24.9062Z"
        fill="currentColor"
      />
      <path
        d="M12.9617 24.9062H10.1854C9.91797 24.9062 9.69922 25.1225 9.69922 25.3913V27.4989C9.69922 27.7663 9.91667 27.9839 10.1854 27.9839H12.9617C13.2292 27.9839 13.4479 27.7664 13.4479 27.4989V25.3913C13.4479 25.1237 13.2292 24.9062 12.9617 24.9062Z"
        fill="currentColor"
      />
      <path
        d="M17.8987 24.9062H15.1712C14.905 24.9062 14.6875 25.1225 14.6875 25.3913V27.4989C14.6875 27.7663 14.9049 27.9839 15.1712 27.9839H17.8987C18.1661 27.9839 18.3837 27.7664 18.3837 27.4989V25.3913C18.3837 25.1237 18.1663 24.9062 17.8987 24.9062Z"
        fill="currentColor"
      />
      <path
        d="M2.22485 31.6636H19.7499C19.4561 31.0511 19.2262 30.4049 19.0811 29.7236L2.87245 29.7235V10.2185H30.1561V19.7422C30.8461 19.9647 31.4949 20.2759 32.0974 20.656V4.70229C32.0974 3.98733 31.5186 3.40723 30.8037 3.40723H26.0773V5.89096C26.0773 6.89853 25.2573 7.71843 24.2499 7.71843H23.7099C22.7012 7.71843 21.8811 6.89836 21.8811 5.89096V3.40853H11.1485V5.89226C11.1485 6.89983 10.3284 7.71973 9.32099 7.71973L8.78102 7.71986C7.77229 7.71986 6.95109 6.89979 6.95109 5.89239V3.40852H2.22475C1.50979 3.40852 0.929688 3.98846 0.929688 4.70359V30.3709C0.93099 31.0847 1.50975 31.6635 2.22475 31.6635L2.22485 31.6636Z"
        fill="currentColor"
      />
      <path
        d="M19.9648 27.9478C19.9648 32.1128 23.3522 35.5002 27.5172 35.5002C31.6822 35.5002 35.0695 32.1128 35.0695 27.9478C35.0695 23.7828 31.6822 20.3955 27.5172 20.3955C23.3522 20.3955 19.9648 23.7828 19.9648 27.9478ZM33.5198 27.9478C33.5198 31.2579 30.8274 33.9502 27.5175 33.9502C24.2075 33.9502 21.5152 31.2577 21.5152 27.9478C21.5152 24.6379 24.2076 21.9455 27.5175 21.9455C30.8274 21.9455 33.5198 24.6379 33.5198 27.9478Z"
        fill="currentColor"
      />
      <path
        d="M24.8973 27.6097C24.5585 27.2709 23.966 27.2684 23.6236 27.6072C23.2723 27.9572 23.2711 28.5284 23.6211 28.8809L25.5899 30.8572C25.7599 31.0273 25.9861 31.121 26.2274 31.1222C26.4673 31.1222 26.6936 31.0284 26.8636 30.8584L31.4122 26.3097C31.5823 26.1396 31.676 25.9135 31.676 25.6735C31.676 25.4335 31.5823 25.2072 31.4122 25.0373C31.0723 24.6985 30.4797 24.6985 30.1385 25.0373L26.2272 28.9486L24.8973 27.6097Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Calender;
